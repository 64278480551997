.smartfield {
  &__container {
    @include span(12 of 12 inside no-gutters);
    display: flex;
    flex-wrap: wrap;
  }

  &__item-wrapper {
    @include span(6 of 12 inside no-gutters);
    position: relative;
    padding: 12px 24px 12px 0;

    @include lg-up {
      @include span(4 of 12 inside no-gutters);
    }

    @include xl-up {
      @include span(3 of 12 inside no-gutters);
    }

    &--extended {
      width: 100%;
    }
  }

  &__labels {
    float: left;
    width: calc(100% - 100px);
    max-height: max-content;
    transition: linear 2s;

    &.collapsed {
      display: block;
      max-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__checkbox-input,
  &__radio-input {
    position: absolute;
    cursor: pointer;
  }

  &__checkbox-label,
  &__radio-label {
    @include type-body-primary;
    float: left;
    padding: 3px 0 0 32px;
    color: $color-regent-gray;
    text-align: left;
    cursor: pointer;

    &--active {
      color: $color-outer-space;

      .rebrand & {
        color: $color-black-pearl;
      }
    }

    &--large-gap {
      padding-left: 55px;
    }
  }

  &__checkbox-label {
    user-select: none;

    &--in-columns {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__placeholder {
    @include type-body-primary;
    float: left;
    padding: 12px 0;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }

    &--read-only {
      color: $color-regent-gray;
    }
  }
}
