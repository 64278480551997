.form__row {
  &__open-tab-anchor {
    margin-right: 18px;

    &--corrupted {
      opacity: 0.3;
    }
  }

  &__open-tab-icon {
    width: 24px;
    height: 100%;
    fill: $color-regent-gray;

    &:hover {
      fill: $color-cerulean;

      .rebrand & {
        fill: $color-dodger-blue;
      }
    }
  }
}

.form__field-label {
  &--corrupted {
    opacity: 0.3;
  }
}

.form__text-input-container {
  &--relationship {
    max-width: 100%;
  }

  &__select {
    display: flex;
    align-items: center;
  }
}

.form__text-input__label-container {
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 160px;
  &__label {
    color: $color-regent-gray;
    font-family: "FSAlbert", Arial, sans-serif;
    font-size: 15px;

    padding: 12px 12px 12px 0;
    overflow: hidden;
    overflow-wrap: break-word;
  }
}

.relationship-dropdown {
  &__container {
    position: relative;
    width: 288px;

    &:hover:not(.relationship-dropdown__container--corrupted) {
      .dropdown__arrow {
        background-color: $color-outer-space;

        .rebrand & {
          background-color: $color-black-pearl;
        }
      }
    }
  }

  &__clear {
    cursor: pointer;
    margin-left: 14px;

    span {
      color: $color-hit-gray;
      font-size: 14px;

      &:hover {
        color: $color-cerulean;
        text-decoration: underline;

        .rebrand & {
          color: $color-dodger-blue;
        }
      }
    }
  }

  &__link {
    margin-left: 14px;

    a {
      display: flex;
    }
  }

  &__link-icon {
    width: 24px;
    height: 100%;
    fill: $color-hit-gray;

    &:hover {
      fill: $color-cerulean;

      .rebrand & {
        fill: $color-dodger-blue;
      }
    }
  }

  &__show-more {
    cursor: pointer;

    color: $color-cerulean;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;

    .rebrand & {
      text-transform: none;
      color: $color-dodger-blue;
    }

    margin-bottom: 9px;
  }

  &__warning {
    display: flex;
    align-items: center;
    margin-left: 16px;

    p {
      color: $color-outer-space;
      margin-left: 16px;

      .rebrand & {
        color: $color-black-pearl;
      }
    }
  }

  &__warning-icon {
    width: 24px;
    height: 100%;
  }
}

.chosen-search-input {
  color: $color-cerulean;
  font-size: 14px !important;

  .rebrand & {
    color: $color-dodger-blue;
    font-size: 12px !important;
  }
}

.chosen-results {
  .active-result {
    font-size: 14px;
    color: $color-regent-gray !important;

    .rebrand & {
      font-size: 12px;
    }

    em {
      color: $color-cerulean;
      text-decoration: none !important;

      .rebrand & {
        color: $color-dodger-blue;
      }
    }
  }

  .no-results {
    background-color: transparent !important;
    font-style: italic;

    .rebrand & {
      font-size: 12px;
    }
  }
}

.chosen-search-input {
  &::placeholder {
    color: $color-regent-gray;
    font-style: italic;
  }
}
