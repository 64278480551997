$parent: block-focus;

.#{$parent} {
  &__selectable {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    color: transparent;
  }
}
