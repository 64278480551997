@import "styles/utilities/typography";
@import "styles/base/colors";
@import "styles/components/articles";
@import "styles/utilities/media-queries";

.text-editor {
  @include type-body-article;
  position: relative;
  width: 100%;

  &__container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 912px;
    padding: 0 32px 32px;
    overflow-x: hidden;

    &--open {
      @include xxxl-up {
        margin-right: auto;
      }

      position: relative;
      margin-right: $drawer-width;
      padding-left: 16px;
      padding-right: 0;
      width: calc(100% - #{$drawer-width});
      transition: width 0.2s ease-in-out;
    }
  }

  &__content {
    padding: 68px 0 0;
    position: relative;
    min-width: 300px;
    max-width: 848px;
    min-height: 52vh;
    background-color: $color-white;
    color: $color-regent-gray;
  }
}
