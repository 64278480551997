$parent: navigation;

.#{$parent} {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-index, navigation);
  width: 100%;
  background-color: $color-shark;

  .rebrand & {
    background-color: $color-east-bay;
  }

  @include lg-up {
    width: $navigation-width;
    height: 100%;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba($color-shark, 0.9);
    content: "";
    opacity: 0;
    transition: opacity 0.3s ease-in, z-index 0s;

    .rebrand & {
      background-color: rgba($color-east-bay, 0.9);
    }

    .fixed & {
      z-index: 2;
      opacity: 1;
      transition: opacity 0.1s ease-out, z-index 0s;
    }
  }

  &__section {
    margin-bottom: 22px;
    display: block;

    .rebrand & {
      margin-bottom: 16px;
    }

    &--signout {
      margin: 0;
      position: fixed;
      width: 260px;
      bottom: 0;
      background: $color-shark;
      z-index: 1;
      padding: 22px 12px 20px 12px;

      .rebrand & {
        background-color: $color-east-bay;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0px;
        right: -8px;
        left: -8px;
        background: rgba($color-cape-cod, 0.35);
        height: 1px;
      }
    }
  }

  &__section-list {
    margin-top: 37px;
    padding: 0 12px;

    &--no-settings {
      margin-top: 10px;
    }
  }

  &__icon {
    @include icon-size(20px);
    float: right;
    fill: $color-shuttle-grey;
  }

  .nav-open & {
    .#{$parent}__container {
      transform: translate(0, 0);
    }

    .#{$parent}__menu-icon {
      background: none;

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        transform-origin: 50% 50%;
      }

      // Rotate the lines to form the x shape
      &::before {
        transform: rotate3d(0, 0, 1, -45deg);
      }

      &::after {
        transform: rotate3d(0, 0, 1, 45deg);
      }
    }
  }

  &__hamburger {
    position: absolute;
    top: 50%;
    z-index: 10;
    display: inline-block;
    width: 48px;
    height: 48px;
    padding: 16px 24px;
    margin: -16px 0 0 16px;
    cursor: pointer;
    transform: translate(0, -50%);

    @include lg-up {
      display: none;
    }
  }

  &__menu-icon {
    position: relative;
    top: 50%;
    left: 50%;
    display: block;
    width: 20px;
    height: 2px;
    background-color: $color-hit-gray;
    transform: translate(-50%, -50%);
    transition: all 0.1s ease-in-out;

    &::before {
      position: relative;
      top: -6px;
      display: block;
      width: 20px;
      height: 2px;
      background-color: $color-hit-gray;
      content: "";
      transition: all 0.2s ease-in-out;
    }

    &::after {
      position: relative;
      top: 4px;
      display: block;
      width: 20px;
      height: 2px;
      background-color: $color-hit-gray;
      content: "";
      transition: all 0.2s ease-in-out;
    }
  }

  &__container {
    @include scroll-y();
    height: calc(100% - 202px);
    padding: 4px 20px 10px 20px;

    .rebrand & {
      height: calc(100% - 232px);
    }

    @include lg-down {
      position: fixed;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
      padding-top: 0;
      padding-bottom: 50px;
      background: $color-outer-space;
      transform: translate(-100%, 0);
      transition: transform 0.3s ease-in-out;

      .rebrand & {
        background: $color-black-pearl;
      }
    }
  }

  &__header-container {
    color: $color-regent-gray;

    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s ease-in-out;

    .navigation--nav-scrolling & {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
    }

    @include lg-up {
      padding-top: 9px;
    }
  }

  &__skylark-logo {
    @include icon-size(48px);
    margin: 24px 0 19px 23px;
    fill: $color-white;
  }

  &__logo-text {
    @include type-brand;
  }

  &__logo-subtitle {
    @include type-brand;
    @include type-body-secondary;
    color: $color-regent-gray;
  }

  &__version {
    @include type-navigation-primary;
    padding-top: 15px;
    cursor: inherit;

    .rebrand & {
      padding-top: 10px;
    }

    p {
      font-size: 14px;
      color: rgb(78, 79, 80);

      .rebrand & {
        color: #7a848f;
        font-size: 12px;
      }
    }
  }
}

.fade-before {
  &::before {
    left: 0;
    display: block;
  }
}

.fade-after {
  &::after {
    right: 0;
    display: block;
  }
}
