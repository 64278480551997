.module-header {
  display: flex;
  align-items: center;
  padding: 24px;
  overflow: hidden;
  flex-wrap: wrap;

  &--overflow {
    overflow: unset;
  }

  &__add {
    @include span(6 of 12 inside no-gutters);
  }

  &__manage {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 100%;
  }

  &__pagination {
    margin-left: auto;
  }

  &__manage + &__pagination {
    margin-top: 24px;

    .pagination {
      margin-right: 0;
    }
  }
}
