@import "styles/components/articles";
@import "styles/utilities/typography";
@import "styles/utilities/mixins";

$parent: video-block;

.#{$parent} {
  @include article-media-block;
  @include unselectable;

  &__container {
    @include article-media-block-container;
    @include type-note-primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $color-shuttle-grey;
    max-width: 488px;
    height: 274px;
    color: rgba($color-white, 0.5);
    font-style: italic;
    text-transform: uppercase;
  }

  &__icon {
    height: 36px;
    width: 48px;
    fill: $color-white;
    opacity: 0.5;
    margin-bottom: 14px;

    &--overlay {
      position: absolute;
    }
  }

  &__image {
    @include article-image;
    overflow: hidden;
    max-height: 274px;
    opacity: 0.5;
  }
}
