.bookmark {
  min-width: 114px;
  position: relative;
  flex: 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  cursor: pointer;

  .rebrand & {
    min-width: 118px;
  }

  &:hover {
    .bookmark__text {
      color: $color-regent-gray;
      transition: color 0.1s ease-in;
      background-color: $color-white;

      &--hidden {
        visibility: hidden;
      }
    }
  }

  &__icon {
    position: relative;
    top: -3px;
    width: 24px;
    height: 24px;
    stroke: $color-hit-gray;
    fill: $color-white;
    transition: stroke 0.1s ease-in;

    &:hover {
      stroke: $color-sunshade;

      .rebrand & {
        stroke: $color-blistering-mars;
      }
    }
  }

  &__text {
    @include type-body-primary;
    position: relative;
    top: -9px;
    color: transparent;
  }

  &--is-active {
    .bookmark__icon {
      animation: pop 0.2s ease-in;
      stroke: $color-sunshade;
      fill: $color-sunshade;

      .rebrand & {
        stroke: $color-blistering-mars;
        fill: $color-blistering-mars;
      }
    }
  }
}
