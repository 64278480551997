//.loader {
//
//  &__container {
//    display: flex;
//    justify-content: center;
//  }
//
//  &__pulse {
//    border-radius: 50%;
//    width: var(--loader-size);
//    height: var(--loader-size);
//
//    &--left {
//      animation: pulse .7s ease-in-out infinite alternate;
//      margin-left: var(--loader-margin);
//    }
//
//    &--right {
//      animation: pulse .7s ease-in-out infinite alternate-reverse;
//    }
//  }
//
//}

@import "styles/utilities/typography";
@import "styles/base/colors";
@import "styles/base/variables";
@import "styles/base/animations";
@import "styles/base/z-index";

$parent: loading;
$pulse-size: 28px;

.#{$parent} {
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: $color-white;
  text-align: center;
  z-index: map-get($z-index, loading);

  position: absolute;
  left: 0;
  top: 34px;

  &--drawer {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__container {
    position: absolute;
    display: inline-block;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    .#{$parent}--page & {
      width: calc(100% - #{$navigation-width});
    }

    .#{$parent}--modal & {
      top: 150px;
    }

    .#{$parent}--drawer & {
      top: 50%;
    }
  }

  &__pulse {
    display: inline-block;
    border-radius: 100%;
    width: $pulse-size;
    height: $pulse-size;

    &--left {
      @include animation-pulse;

      .#{$parent}--modal & {
        @include animation-pulse($inline: true);
      }

      .#{$parent}--drawer & {
        @include animation-pulse($inline: true);
      }
    }

    &--right {
      @include animation-pulse($reverse: true);
      margin-left: 24px;

      .#{$parent}--modal & {
        @include animation-pulse($reverse: true, $inline: true);
        margin-left: 18px;
      }

      .#{$parent}--drawer & {
        @include animation-pulse($reverse: true, $inline: true);
        margin-left: 18px;
      }
    }
  }

  &__text {
    @include type-body-primary;
    bottom: 48px;
    color: $color-regent-gray;
    text-align: center;

    position: absolute;
    width: 100%;
    left: 5px;
  }

  &__enter {
    opacity: 0.01;

    &--active {
      opacity: 1;
      transition: opacity 0.1s ease-in;
    }
  }

  &__exit {
    opacity: 1;

    &--active {
      opacity: 0.01;
      transition: opacity 0.1s ease-in;
    }
  }
}
