.performance-detail-row {
  background-color: $color-athens-gray;

  margin: 2px;
  padding: 16px 0;

  &__metric {
    font-size: 20px;
  }

  &:hover {
    background-color: $color-gallery;
  }
}

.performance-details {
  &__icon {
    &__container {
      display: flex;
      justify-content: center;
    }

    path {
      fill: $color-neon-blue;
    }

    border: 2px solid $color-neon-blue;
    border-radius: 2px;

    cursor: pointer;

    margin-left: 5px;
    width: 20px;
    height: 20px;
  }
}
