* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body,
body.ng-scope {
  @include type-body-primary;
  min-height: 100%;
  // Accommodate mobile navigation
  padding-top: 116px;
  background: $color-porcelain;

  @include lg-up {
    padding-top: 0;
  }

  &.fixed,
  &.overlay,
  &.locked,
  &.nav-open {
    overflow: hidden;
  }
}

body.ng-scope.rebrand {
  @include font-secondary-rebrand;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

.main-wrap {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
}

.main-sidebar {
  position: absolute;
}
