.dropdown {
  @include type-body-primary;
  position: relative;
  display: block;
  max-height: 48px;
  min-width: 170px;
  padding: 13px 16px 11px;
  color: $color-regent-gray;
  cursor: pointer;
  background: $color-porcelain;
  border: 2px solid $color-porcelain;
  border-radius: 2px;

  &:hover {
    color: $color-outer-space;
    background: $color-white;
    border-color: $color-iron;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .rebrand & {
      color: $color-black-pearl;
      border-color: $color-solitude;
    }

    .dropdown__arrow {
      background-color: $color-outer-space;

      .rebrand & {
        background-color: $color-black-pearl;
      }
    }

    .dropdown__text {
      color: $color-outer-space;

      .rebrand & {
        color: $color-black-pearl;
      }
    }
  }

  &--dark {
    background-color: $color-iron;
    border-color: $color-iron;

    .rebrand & {
      background-color: $color-alabaster;
      border-color: $color-solitude;
    }

    &:hover {
      background-color: $color-white;
      border-color: $color-solitude;

      .rebrand & {
        color: $color-black-pearl;
        border-color: $color-solitude;
      }
    }
  }

  &--light {
    @include type-body-primary;
    height: 38px;
    width: 100%;
    background-color: $color-athens-gray;
    color: $color-regent-gray;
    text-align: left;
    border-radius: 2px;
    padding: 8px 12px;
    border-width: 1px;
    border-color: transparent;

    .dropdown {
      &__arrow {
        width: 28px;
        height: 28px;
      }

      &__arrow-icon {
        @include icon-size(20px);
      }
    }

    &:hover {
      background-color: $color-athens-gray;
      border: 1px solid $color-porcelain;

      .dropdown {
        &__arrow {
          background-color: $color-hit-gray;
        }

        &__text {
          color: $color-regent-gray;
        }
      }
    }
  }

  &--transparent {
    color: $color-hit-gray;
    background-color: transparent;
    border-color: transparent;

    &:hover {
      background-color: $color-white;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 6px;
    width: 34px;
    height: 34px;
    pointer-events: none;
    background-color: $color-hit-gray;
    border-radius: 2px;
    transform: translateY(-50%);

    &:hover {
      background-color: $color-outer-space;

      .rebrand & {
        background-color: $color-black-pearl;
      }
    }
  }

  &__arrow-icon {
    @include icon-size(24px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: $color-white;

    &--language {
      right: 22px;
      left: auto;
      display: none;
      transform: translate(0, -50%);
      fill: $color-hit-gray;

      @include ml-up {
        display: block;
      }
    }
  }

  &__placeholder-icon {
    @include icon-size(24px);
    position: relative;
    top: 4px;
    fill: $color-hit-gray;
  }

  &__text {
    display: inline-block;
    width: 100%;
    padding-right: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__trigger-wrapper {
    @include span(12 of 12 inside no-gutters);
    position: relative;
    margin-bottom: 16px;

    @include lg-up {
      width: auto;
      margin-right: 20px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.options__translation-indicator {
  float: right;
  font-size: 24px;
}
