.search-field {
  position: relative;
  width: 100%;
  margin-bottom: 16px;

  @include lg-up {
    float: left;
    width: auto;
    margin-right: 20px;
  }

  &:last-child {
    @include lg-up {
      margin-right: 0;
    }
  }

  &__label {
    position: relative;
    display: block;
    min-width: 200px;
  }

  &__input {
    width: 100%;
  }

  &--light {
    width: 100%;
    margin: 0;
  }

  &__icon {
    @include icon-size(24px);
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);
    fill: $color-hit-gray;

    &--light {
      fill: $color-regent-gray;
    }
  }
}
