.pagination {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;

  &__count {
    @include type-body-primary;
    position: relative;
    margin-right: 16px;
    line-height: $margin-small;
    color: $color-regent-gray;

    &--strong {
      font-weight: 600;
    }
  }
}
