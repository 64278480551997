.multiple-checkbox {
  &__search {
    width: 100%;
  }

  &__search-field {
    width: 100%;
    margin-bottom: 20px;
  }

  &__count {
    @include type-body-primary;
    padding: 11px 0;
  }
}
