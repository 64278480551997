@import "styles/base/colors";
@import "styles/base/z-index";
@import "styles/utilities/typography";

$parent: toast;

@mixin displayToast {
  margin-top: 10px;
  padding: 16px 20px 13px;
  max-height: 60px;
}

.#{$parent} {
  position: relative;
  margin: 0;
  padding: 0 20px;
  border-radius: 2px;
  max-width: 100%;
  max-height: 0;
  background: $color-outer-space;
  background-repeat: no-repeat;
  background-position: 15px center;
  opacity: 0;
  transition: all 0.2s ease;
  color: $color-white;

  .rebrand & {
    background: $color-black-pearl;
  }

  &--error {
    background: $color-crimson;
  }

  &__entered {
    @include displayToast;
    opacity: 0.8;
  }

  &__first {
    @include displayToast;
  }

  &__emphasised-message {
    color: $color-cerulean;
    text-decoration: underline;

    .rebrand & {
      color: $color-dodger-blue;
    }

    &:hover {
      color: $color-jelly-bean;

      .rebrand & {
        background-color: $color-denim;
      }
    }
  }

  &__body-message {
    margin-right: 20px;
  }

  &__button {
    position: absolute;
    z-index: 100;
    top: 0;
  }

  &__close {
    @include type-title;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 10px;
    border: 0;
    background: transparent;
    text-shadow: none;
    opacity: 1;
    cursor: pointer;
    color: $color-white;
    line-height: 1.92;
    -webkit-appearance: none;
  }

  &__enter {
    opacity: 0.01;
  }

  &__enter-active {
    @include displayToast;
    opacity: 0.8;
    transition-delay: 0.2s;
  }

  &__exit {
    opacity: 0.01;
  }

  &__exit-active {
    opacity: 0.01;
    transition-delay: 0.1s;
  }
}
