.error-404 {
  height: 100vh;
  background-image: url("../../../assets/images/mountains.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .local-nav__isactive & {
    height: calc(100vh - 48px);
  }

  &__popover {
    position: absolute;
  }
}
