@mixin font-primary($fontFamily: "FSAlbert") {
  @include font-smoothing;
  font-family: $fontFamily, Arial, sans-serif;
}

@mixin font-secondary($fontFamily: "Ciutadella") {
  @include font-smoothing;
  font-family: $fontFamily, Arial, sans-serif;
}

@mixin font-primary-rebrand($fontFamily: "Work Sans") {
  @include font-smoothing;
  font-family: $fontFamily, Arial, sans-serif;
}

@mixin font-secondary-rebrand($fontFamily: "Inter") {
  @include font-smoothing;
  font-family: $fontFamily, Arial, sans-serif;
}

@mixin font-smoothing {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@mixin type-note-primary {
  @include font-primary;
  font-size: 13px;
  line-height: 16px;

  .rebrand & {
    @include font-secondary-rebrand;
    font-size: 11px;
  }
}

@mixin type-note-secondary {
  @include font-secondary;
  font-size: 13px;
  line-height: 16px;

  .rebrand & {
    @include font-secondary-rebrand;
    font-size: 12px;
  }
}

@mixin type-body-primary {
  @include font-primary;
  font-size: 15px;
  line-height: 20px;

  .rebrand & {
    @include font-secondary-rebrand;
    font-size: 13px;
  }
}

@mixin type-body-secondary {
  @include font-secondary;
  font-size: 15px;
  line-height: 19px;

  .rebrand & {
    @include font-secondary-rebrand;
    font-size: 13px;
  }
}

@mixin type-body-article {
  @include font-primary;
  font-size: 18px;
  line-height: 26px;

  .rebrand & {
    @include font-secondary-rebrand;
    font-size: 16px;
  }
}

@mixin type-title-article {
  @include font-primary;
  font-size: 40px;
  line-height: 52px;

  .rebrand & {
    @include font-primary-rebrand;
    font-size: 38px;
  }
}

@mixin type-article-quote {
  @include font-primary;
  font-size: 28px;
  line-height: 36px;

  .rebrand & {
    @include font-secondary-rebrand;
    font-size: 26px;
  }
}

@mixin type-body-link {
  @include font-primary;
  font-size: 18px;
  line-height: 24px;

  .rebrand & {
    @include font-secondary-rebrand;
    font-size: 16px;
  }
}

@mixin type-subheading {
  @include font-secondary;
  font-size: 18px;
  line-height: 22px;

  .rebrand & {
    @include font-secondary-rebrand;
    font-size: 15px;
  }
}

@mixin type-title {
  @include font-secondary;
  font-size: 28px;
  line-height: 34px;

  .rebrand & {
    @include font-primary-rebrand;
    font-size: 26px;
  }
}

@mixin type-display {
  @include font-secondary;
  font-size: 48px;
  line-height: 58px;

  .rebrand & {
    @include font-primary-rebrand;
    font-size: 42px;
    line-height: 44px;
  }
}

@mixin type-italic {
  @include font-secondary("Ciutadella Regular Italic");
  font-style: italic;

  .rebrand & {
    @include font-secondary-rebrand;
  }
}

@mixin type-label {
  @include font-secondary;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1;

  .rebrand & {
    @include font-secondary-rebrand;
    text-transform: none;
    font-size: 11px;
  }
}

@mixin type-brand {
  @include font-secondary;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 54px;
  color: $color-white;

  .rebrand & {
    @include font-primary-rebrand;
    text-transform: none;
    letter-spacing: 0px;
    font-size: 20px;
  }
}

@mixin type-navigation-primary {
  @include font-secondary;
  font-size: 16px;
  font-weight: 400;
  color: rgba($color-white, 0.65);
  cursor: pointer;

  .rebrand & {
    @include font-secondary-rebrand;
    font-size: 13px;
    font-weight: 500;
  }
}

@mixin type-button {
  @include type-subheading;
  text-transform: uppercase;

  .rebrand & {
    @include font-primary-rebrand;
    font-size: 15px;
    text-transform: capitalize;
  }
}

@mixin type-button-small {
  @include type-note-secondary;
  text-transform: uppercase;

  .rebrand & {
    @include font-primary-rebrand;
    font-size: 12px;
    text-transform: capitalize;
  }
}

@mixin type-panel-title {
  @include type-subheading;
  text-transform: uppercase;

  .rebrand & {
    @include font-primary-rebrand;
    font-size: 16px;
    text-transform: none;
  }
}
