@mixin button-defaults {
  position: relative;
  display: inline-block;
  appearance: none;
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.1s ease-in;
}

@mixin button-icon-defaults {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

// Extend button states by defining
// reusable modifier. It will then
// be available to use with either
// button-standard or button-small.
@mixin button-states {
  &--primary {
    color: $color-white;
    background-color: $color-cerulean;
    fill: $color-white;

    .rebrand & {
      background-color: $color-dodger-blue;
    }

    &:hover {
      background-color: $color-jelly-bean;

      .rebrand & {
        background-color: $color-denim;
      }
    }
  }

  &--secondary-on-dark {
    color: $color-white;
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $color-white;
    fill: $color-white;

    &:hover {
      background-color: rgba($color-white, 0.2);
    }
  }

  &--secondary-on-light {
    color: $color-regent-gray;
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $color-regent-gray;
    fill: $color-regent-gray;

    &:hover {
      background-color: rgba($color-regent-gray, 0.2);
    }
  }

  &--tertiary {
    color: $color-white;
    background-color: $color-crimson;
    fill: $color-white;

    &:hover {
      background-color: $color-shiraz;
    }
  }

  &--disabled {
    color: $color-outer-space;
    cursor: default;
    background-color: $color-hit-gray;
    opacity: 0.3;
    // Overwrite --secondary box-shadow
    box-shadow: none;
    fill: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
      fill: $color-black-pearl;
    }
  }

  &--locked {
    color: $color-white;
    cursor: not-allowed;
    background-color: $color-hit-gray;
    // Overwrite --secondary box-shadow
    box-shadow: none;
    fill: $color-white;

    &:hover {
      background-color: $color-regent-gray;
    }
  }

  &--outline {
    border: 1px solid $color-black-pearl;
    border-radius: 3px;
    color: $color-black-pearl;

    &:hover {
      border-color: $color-dodger-blue;
      color: $color-dodger-blue;
    }
  }

  &--is-saving {
    @include spinner-pulse($size: 12px);
    color: $color-outer-space;
    cursor: default;
    background-color: $color-hit-gray;
    opacity: 0.3;
    // Overwrite --secondary box-shadow
    box-shadow: none;
    fill: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
      fill: $color-black-pearl;
    }

    &::before,
    &::after {
      position: absolute;
      top: 0;
      right: 12px;
      bottom: 0;
      margin: auto;
    }

    &::after {
      margin-right: 16px;
    }
  }
}
