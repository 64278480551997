.text-input {
  @include input-defaults;
  border: 2px solid $color-porcelain;
  border-radius: 2px;
  appearance: none;

  &::placeholder {
    color: $color-hit-gray;
  }

  &:focus,
  &:hover {
    color: $color-outer-space;
    background-color: $color-white;
    border: 2px solid $color-iron;

    .rebrand & {
      color: $color-black-pearl;
      border: 2px solid $color-solitude-dark;
      background-color: $color-white;
    }
  }

  &:focus + label,
  &:hover + label {
    color: $color-black;
  }

  &:focus + svg,
  &:hover + svg {
    fill: $color-outer-space;

    .rebrand & {
      fill: $color-black-pearl;
    }
  }

  &[disabled] {
    &:hover {
      background-color: $color-porcelain;
    }
  }

  &--dark {
    background-color: $color-iron;
    border-color: $color-iron;

    .rebrand & {
      background-color: $color-alabaster;
      border-color: $color-alabaster;
    }
  }

  &--light {
    @include input-drawer;
  }

  &--position {
    max-width: 30px;
    padding: 3px 0;
    text-align: center;
  }

  &--has-icon {
    padding-right: 42px;
  }

  &--auto-save {
    display: inline-block;
    width: 68%;
  }
}
