.version {
  &__upload {
    position: relative;
  }

  &__upload:not(:first-child) {
    margin-top: 28px;
  }

  &__upload__title {
    @include type-brand;
    color: $color-outer-space;
    letter-spacing: 0;
    padding-left: 20px;

    .rebrand & {
      color: $color-black-pearl;
    }
  }
}
