.modal-block {
  margin: auto;

  &__content {
    &--settings {
      border-radius: 2px;
      background-color: $color-white;
      border: 1px solid $color-white;
    }
  }

  &__head-section {
    background-color: $color-shuttle-grey;

    &--local-nav {
      background-color: $color-cerulean;

      .rebrand & {
        background-color: $color-dodger-blue;
      }

      &:hover {
        color: $color-white;
      }
      &.active {
        color: $color-white;
        cursor: default;
        box-shadow: inset 0 -4px 0 0 rgba($color-white, 0.6);
      }
    }

    &.active {
      color: $color-white;
    }
  }

  &__body-section {
    @include scroll-y;
    max-height: calc(100vh - 228px);
    background-color: $color-white;

    &--overflow-visible {
      overflow: visible;
    }

    &--translations,
    &--dimensions {
      background-color: transparent;
    }

    .placeholder-image {
      height: auto;
      max-width: 148px;

      @include lg-down {
        max-width: 100%;
      }

      @include ml-down {
        width: span(12 of 12 inside no-gutters);
      }
    }

    &--local-nav,
    &--dimensions {
      height: 475px;
    }

    &--translations {
      height: 310px;
    }
  }

  &__container {
    padding: 20px;
    transition: all 0.2s ease;

    @include lg-up {
      padding: 24px;
    }

    &--edit {
      @include lg-up {
        padding: 24px;
      }
    }

    &--dimensions,
    &--translations {
      @include lg-up {
        padding: 35px 30px 30px;
      }
    }

    &--translations {
      @include clearfix;

      @include lg-up {
        padding: 0 35px 35px;
      }
    }

    &.hiding {
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  }

  &__controls {
    width: 100%;
    border-bottom: 1px solid $color-iron;
    padding: 20px 20px 12px;

    .rebrand & {
      border-bottom-color: $color-solitude;
    }

    @include lg-up {
      padding: $margin-small 44px 12px;
      &,
      + .modal-block__container {
        padding: $margin-small 44px 12px;
      }
    }

    &.hiding {
      opacity: 0;
    }
  }

  &__footer {
    @include clearfix;
    margin: 16px 0;
  }

  &__footer-button-wrap {
    float: left;
    margin: 0 12px 0 0;

    &--right {
      float: right;
      margin: 0 0 0 12px;
    }
  }

  &__footer-button {
    display: inline-block;
  }
}
