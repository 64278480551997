.top-navigation-search {
  position: relative;
  z-index: map-get($z-index, top-navigation-search);

  .search-bar-container {
    box-shadow: 0 0 2px 0 rgba(black, 0.1), 0 2px 3px 0 rgba(black, 0.1);
  }

  .search-container {
    position: relative;
  }

  .search-results {
    position: absolute;
    width: 100%;
  }

  .select-filter__input {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
