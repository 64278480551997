@import "styles/utilities/typography";
@import "styles/utilities/mixins";

.block-indicator {
  @include unselectable;
  @include type-note-secondary;
  position: absolute;
  top: 0;
  padding: 8px 7px;

  &:before {
    @include unselectable;
    content: attr(data-indicator);
    position: absolute;
  }
}
