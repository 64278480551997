.repeatable-multisearch {
  @include span(12 of 12 inside no-gutters);

  &__operator {
    @include span(12 of 12 inside no-gutters);
    @include type-body-primary;
    margin: 4px 0 14px;
    color: $color-regent-gray;
    text-transform: uppercase;
  }

  &__add {
    @include span(12 of 12 inside no-gutters);
  }
}
