// Build your button using these:
// button-small button-small--primary (blue)
// button-small button-small--secondary-on-dark (modals mainly)
// button-small button-small--secondary-on-light (secondary action: CANCEL, etc.)
// button-small button-small--tertiary (red)
//
// Change button state by replacing modifiers with these:
// button-small--disabled
// button-small--locked
//
// Spacing between buttons: 8px

.button-small {
  @include button-defaults;
  @include button-states;
  min-height: $margin-small;
  padding: 7px 8px 6px;
  line-height: 16px;

  &--outline {
    padding: 6px 7px 5px;
  }

  &__text {
    @include type-button-small;
    padding: 0 2px;

    &--has-icon {
      padding-right: 24px;
    }
  }

  &__icon {
    @include button-icon-defaults;
    @include icon-size(16px);
    right: 8px;
  }

  &--is-saving {
    @include spinner-pulse($size: 8px);

    &:before {
      right: 8px;
    }

    &:after {
      right: 4px;
    }
  }
}
