.video-upload-file {
  padding-top: 100px;
  padding-bottom: 128px;
  text-align: center;
  color: $color-regent-gray;
  position: relative;

  &__icon {
    height: 40px;
    margin-bottom: 18px;
    fill: $color-regent-gray;
  }

  &__text {
    @include type-note-primary;
    text-transform: uppercase;
    margin-bottom: 24px;

    .rebrand & {
      text-transform: none;
      font-size: 14px;
    }
  }

  &__file-types {
    @include type-note-secondary;
    font-style: italic;
  }

  &__file-types-warning {
    @include type-note-secondary;
    font-style: italic;
    margin-top: 4px;
  }

  &__button {
    margin-bottom: 15px;
  }

  &__close-button {
    position: absolute;
    top: 25px;
    right: 25px;

    &__icon {
      fill: $color-regent-gray;
    }
  }
}
