.search-input {
  @include type-body-primary;

  border: 0;
  border-radius: 0 2px 2px 0;

  padding-left: 20px;

  &:focus {
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &:disabled {
    display: none;
    width: 0;
    height: 0;
  }
}
