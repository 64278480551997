.permissions-manager {
  margin-top: 8px;
  text-align: right;

  @include lg-up {
    width: auto;
  }

  &--modal {
    @include lg-up {
      position: static;
      width: 100%;
    }
  }

  &__trigger {
    @include type-body-primary;
    color: $color-cerulean;
    text-decoration: underline;
    cursor: pointer;

    .rebrand & {
      color: $color-dodger-blue;
    }

    &--deactivated {
      cursor: auto;
    }
  }
}
