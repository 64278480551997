.navigation-filter-button {
  position: relative;
  display: flex;
  padding: 8px 10px;
  margin-top: 6px;
  border: 2px solid rgba($color-white, 0.1);
  border-radius: 2px;
  cursor: pointer;
  transition: border 0.1s ease-in-out;

  &--active {
    z-index: 3;
    position: relative;
    border-color: rgba($color-white, 0.25);
    cursor: auto;
  }

  &--disabled {
    cursor: not-allowed;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color-shark, 0.8);

      .rebrand & {
        background-color: rgba($color-east-bay, 0.8);
      }
    }
  }

  &:hover {
    &:not(.navigation-filter-button--disabled) {
      border-color: rgba($color-white, 0.2);
    }
  }

  &__icon {
    @include icon-size(22px);
    fill: rgba($color-white, 0.8);
  }

  &__text {
    padding: 3px 0 0 13px;
    overflow: hidden;
  }

  &__title {
    @include type-navigation-primary;
    color: $color-white;
    transition: color 0.2s ease-in-out;
    user-select: none;
    cursor: inherit;

    .navigation-filter-button--active & {
      color: $color-summer-sky;

      .rebrand & {
        color: $color-dodger-blue-light;
      }
    }
  }

  &__copy {
    font-size: 13px;
    color: rgba($color-white, 0.45);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
    cursor: inherit;
    transition: color 0.2s ease-in-out;

    .navigation-filter-button--active & {
      color: $color-white;
    }
  }
}
