@import "styles/base/colors";
@import "styles/utilities/typography";
@import "styles/utilities/mixins";
@import "styles/components/modal";

$parent: modal-notification;

.#{$parent} {
  @include modal;
  width: 802px;

  &__body {
    @include modal-body;
  }

  &__content {
    margin: 32px;
  }

  &__body-message {
    @include type-body-primary;
    padding: 20px 32px 20px 20px;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &__footer {
    @include modal-footer;
  }

  &__footer-button-wrap {
    @include modal-footer-button-wrap;
  }
}
