@import "styles/components/articles";

@mixin textPlaceholder {
  position: relative;
  display: block;
  opacity: 0.4;
  cursor: text;
  color: $color-regent-gray;
  pointer-events: none;
}

.text-placeholder-block {
  &__title {
    @include article-title;
    @include textPlaceholder;
    padding: 20px 0;
  }

  &__subtitle {
    @include article-subtitle;
    @include textPlaceholder;
    padding: 15px 0;
  }

  &__default {
    @include article-generic;
    @include textPlaceholder;
  }

  &:before {
    position: absolute;
    content: attr(data-text-placeholder);
  }
}
