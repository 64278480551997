.playback-canvas,
.playback-overlay svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playback-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  svg {
    width: 30px;
  }

  &:hover {
    svg {
      display: none;
    }
  }

  &:hover + .playback-image + .playback-canvas {
    display: none;
  }
}
