@import "styles/base/colors";
@import "styles/utilities/typography";
@import "styles/components/dropdown";
@import "styles/base/z-index";

$parent: dropdown;

.#{$parent} {
  @include type-body-primary;
  @include dropdown-wrapper;
  background-color: $color-athens-gray;
  border-radius: 2px;
  float: left;
  padding: 8px 12px;
  border-width: 1px;
  border-color: transparent;
  width: 100%;
  transition: background-color 0.1s ease-in, opacity 0.1s ease-in-out;
  color: $color-regent-gray;

  &:hover {
    color: $color-outer-space;
    border: 1px solid $color-porcelain;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &__icon-wrapper {
    @include dropdown-icon($color-white);
    top: calc(50%);
    right: 5px;
    background-color: $color-regent-gray;
    padding: 4px;
    border-radius: 3px;
  }

  &__icon {
    right: 18px;
    width: 20px;
    height: 20px;
  }

  &__text {
    @include dropdown-text;
    @include type-body-primary;
    color: $color-regent-gray;
    text-transform: initial;
  }

  &__options {
    margin: 1px -1px 0 -1px;
    border-width: 1px;
    border-top: none;
    z-index: map-get($z-index, dropdown);
    border: 1px solid $color-porcelain;
    border-top: none;

    li > div {
      @include type-body-primary;
      padding: 10px 12px;
      height: 38px;
      text-transform: initial;
    }
  }
}
