@import "styles/utilities/typography";
@import "styles/base/colors";

.link-preview {
  &__container {
    display: flex;
    align-items: center;
    background-color: $color-white;
    width: 440px;
    border: 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  }

  &__text {
    @include type-body-primary;
    position: relative;
    width: 324px;
    color: $color-cerulean;
    text-decoration: underline;
    max-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 20px;
    margin-right: 12px;

    .rebrand & {
      color: $color-dodger-blue;
    }

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      width: 30px;
      height: 48px;
      background: linear-gradient(to right, transparent, $color-white);
    }
  }

  &__controls {
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 104px;
    padding: 0 20px;
  }

  &__button {
    margin-right: 8px;
    padding: 2px 4px;
    background-color: transparent;
    line-height: 1;
    &--edit {
      &:hover svg {
        fill: $color-cerulean;
        transition: fill 0.1s ease-in-out;

        .rebrand & {
          fill: $color-dodger-blue;
        }
      }
    }

    &--remove {
      &:hover svg {
        fill: $color-crimson;
        transition: fill 0.1s ease-in-out;
      }
    }
  }

  &__icon {
    fill: $color-hit-gray;
    width: 24px;
    height: 24px;
  }
}
