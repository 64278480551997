// Styles for tooltip ui directive

// Hide the element for hover
tooltip {
  display: none;
  position: relative;
}

.tooltip-container {
  position: relative;
  text-align: center;
  margin: 0 auto;

  .tooltip {
    left: auto;
    right: -235px;
    top: -27px;
  }
}

.tooltip {
  @include clearfix;
  @include type-body-secondary;
  display: block;
  position: absolute;
  top: -15px;
  padding: 8px 0 0;
  width: 215px;
  border-radius: 2px;
  z-index: map-get($z-index, tooltip);
  background: $color-outer-space;
  color: $color-white;
  text-transform: none;
  text-align: left;
  left: -20px;

  .rebrand & {
    background: $color-black-pearl;
  }

  &--aligned-top {
    top: auto;
    bottom: 18px;
    left: -88px;
  }

  &--aligned-right {
    top: -9px;
    left: $margin-small;
  }

  // Triangle
  &--aligned-left:before {
    content: "";
    position: absolute;
    top: 8px;
    left: -16px;
    border: 8px solid $color-outer-space;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    z-index: 1;
    opacity: 1;

    .rebrand & {
      border-right-color: $color-black-pearl;
    }
  }

  &--aligned-right:before {
    content: "";
    position: absolute;
    top: 8px;
    left: -16px;
    border: 8px solid $color-outer-space;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    z-index: 1;
    opacity: 1;

    .rebrand & {
      border-right-color: $color-black-pearl;
    }
  }

  &--aligned-top:before {
    content: "";
    position: absolute;
    bottom: -16px;
    left: 88px;
    border: 8px solid $color-outer-space;
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    z-index: 1;
    opacity: 1;

    .rebrand & {
      border-bottom-color: $color-black-pearl;
    }
  }

  p {
    line-height: 1.25;
  }

  &__message {
    padding: 0 12px 8px;
  }

  &__items {
    @include type-body-primary;
    background-color: $color-shuttle-grey;
    padding: 8px 12px;
  }

  &__icon {
    @include icon-size(20px);
    margin-top: -2px;
    border-radius: 50%;
    fill: $color-hit-gray;

    &--error {
      fill: $color-crimson;
    }

    &--align-top {
      margin-left: 9px;
    }
  }
}

.tooltip-item {
  &__text {
    @include type-note-secondary;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    &--right {
      padding-left: 4px;
      max-width: 50px;
      text-align: right;
      text-transform: none;
      font-weight: normal;
    }
  }
}
