.modal-elements-container {
  position: fixed;
  right: 56px;
  bottom: 24px;

  z-index: map-get($z-index, modal-elements-container);

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  #toast-container {
    margin-bottom: 12px;
  }
}
