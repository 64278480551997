.modal-dimensions-settings {
  max-width: 1220px;

  &__content {
    border-radius: 2px;
  }

  &__header {
    border-bottom: 1px solid $color-porcelain;
  }

  &__title {
    @include font-modal-primary-heading;
    @include modal-primary-title;
    background: $color-white;
  }

  &__tabs {
    display: flex;
    padding: 0 30px;
  }
}
