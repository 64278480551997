.select-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: span 4;

  background-color: white;

  padding-left: 22px;

  &--focus {
    grid-column: span 4;

    + .search-input {
      width: 0;
      height: 0;
    }
  }

  &--selected {
    grid-column: span 1;
  }

  .icon-search,
  .icon-down {
    min-width: 20px;
    width: 20px;
    height: 20px;
    fill: $color-regent-gray;
  }

  .select-filter {
    @include type-body-primary;
    width: 100%;
    margin-left: 10px;

    &__value-container {
      padding: 0 !important;
    }

    &__control {
      border-width: 0 !important;
      border-radius: 2px 0 0 2px !important;
      outline: 0 !important;
      box-shadow: none !important;

      &:hover {
        box-shadow: none;
      }

      &:focus {
        box-shadow: none;
      }

      &--is-focused {
        .select-filter__placeholder,
        input {
          color: $color-cerulean !important;

          .rebrand & {
            color: $color-dodger-blue !important;
          }
        }

        .icon-down {
          fill: $color-cerulean;

          .rebrand & {
            fill: $color-dodger-blue;
          }
        }
      }
    }

    &__placeholder {
      color: $color-regent-gray !important;

      &:active {
        color: $color-cerulean !important;

        .rebrand & {
          color: $color-dodger-blue !important;
        }
      }
    }

    &__input {
      outline: 0;
      padding: 12px 0;
    }

    &__menu {
      border: 0;
      box-shadow: 0 2px 6px 0 rgba(black, 0.1), 0 6px 16px -6px rgba(black, 0.3) !important;
      border-radius: 2px;
    }

    &__option {
      border: 0;
      color: $color-regent-gray !important;
      cursor: pointer;

      &:hover,
      &--is-focused,
      &--is-selected {
        background-color: rgba($color-anakiwa, 0.1) !important;
        color: $color-outer-space !important;

        .rebrand & {
          background-color: rgba($color-alice-blue, 0.1) !important;
          color: $color-black-pearl !important;
        }
      }
    }

    &__single-value {
      @include type-body-primary;
      color: $color-regent-gray !important;
    }

    &__indicators {
      display: grid !important;
      grid-template-columns: 1fr 1px;
      grid-template-areas: "indicator separator";
    }

    &__indicator {
      grid-area: indicator;
    }

    &__indicator-separator {
      background-color: rgba($color-hit-gray, 0.25) !important;
      margin: 0 !important;
      grid-area: separator;
    }
  }
}
