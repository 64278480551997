@import "styles/utilities/mixins";
@import "styles/base/colors";
@import "styles/utilities/typography";

@mixin text-defaults {
  padding: 0 2px;
  text-transform: uppercase;
}

@mixin button-states($backgroundColor, $color, $cursor) {
  &:hover {
    background-color: $backgroundColor;
    color: $color;
    cursor: $cursor;
  }

  &:focus {
    outline: none;
  }
}

@mixin button-box-shadow($color) {
  @if ($color) {
    box-shadow: inset 0 0 0 1px $color;
  }
}

// Button Types
$buttons: (
  standard: (
    padding: 14px 12px 11px,
    min-height: 48px,
    line-height: 22px,
    icon-padding-right: 36px,
    icon-right: 0,
    icon-width: 24px,
    icon-height: 24px,
  ),
  small: (
    padding: 7px 8px,
    min-height: 32px,
    line-height: 16px,
    icon-padding-right: 24px,
    icon-right: 8px,
    icon-width: 16px,
    icon-height: 16px,
  ),
  medium: (
    padding: 8px 12px 7px,
    min-height: 36px,
    line-height: 22px,
    icon-padding-right: 26px,
    icon-right: 0,
    icon-width: 17px,
    icon-height: 17px,
  ),
);

@each $button-type, $map in $buttons {
  .button-#{$button-type} {
    position: relative;
    padding: map-get($map, padding);
    border: 0;
    border-radius: 2px;
    min-height: map-get($map, min-height);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    line-height: map-get($map, line-height);

    &__text {
      @include text-defaults;
      transition: color 0.1s ease-in-out;
      white-space: nowrap;

      &--has-icon {
        padding-right: map-get($map, icon-padding-right);
      }
    }

    &__icon {
      @include vertical-center;
      transition: fill 0.1s ease-in-out;
      right: map-get($map, icon-right);
      width: map-get($map, icon-width);
      height: map-get($map, icon-height);
    }
  }
}

.button-standard {
  &__text {
    @include type-button;
  }

  &__icon-container {
    position: relative;
  }
}

.button-small {
  &__text {
    @include type-button-small;
  }
}

.button-medium {
  &__text {
    @include type-navigation-primary;
    line-height: 22px;
  }

  &__icon-container {
    position: relative;
  }
}

// Button Colors
$button-colors: (
  primary: (
    backgroundColor: $color-cerulean,
    backgroundColorHover: $color-jelly-bean,
    color: $color-white,
    colorHover: $color-white,
    fill: $color-white,
    cursor: pointer,
    opacity: 1,
  ),
  secondary-on-dark: (
    backgroundColor: transparent,
    backgroundColorHover: rgba($color-white, 0.2),
    boxShadow: $color-white,
    color: $color-white,
    colorHover: $color-white,
    fill: $color-white,
    cursor: pointer,
    opacity: 1,
  ),
  secondary-on-light: (
    backgroundColor: transparent,
    backgroundColorHover: rgba($color-regent-gray, 0.2),
    boxShadow: $color-regent-gray,
    color: $color-regent-gray,
    colorHover: $color-white,
    fill: $color-regent-gray,
    cursor: pointer,
    opacity: 1,
  ),
  tertiary: (
    backgroundColor: $color-crimson,
    backgroundColorHover: $color-shiraz,
    color: $color-white,
    colorHover: $color-white,
    fill: $color-white,
    cursor: pointer,
    opacity: 1,
  ),
  disabled: (
    backgroundColor: lighten($color-outer-space, 69%),
    backgroundColorHover: lighten($color-outer-space, 69%),
    color: lighten($color-outer-space, 55%),
    colorHover: lighten($color-outer-space, 55%),
    fill: lighten($color-outer-space, 55%),
    cursor: not-allowed,
    opacity: 1,
  ),
  disabled--dark-background: (
    backgroundColor: $color-hit-gray,
    backgroundColorHover: $color-hit-gray,
    color: $color-outer-space,
    colorHover: $color-outer-space,
    fill: $color-outer-space,
    cursor: not-allowed,
    opacity: 0.3,
  ),
  disabled--small-light: (
    backgroundColor: rgba($color-white, 0.7),
    backgroundColorHover: rgba($color-white, 0.7),
    color: $color-outer-space,
    colorHover: $color-outer-space,
    fill: $color-outer-space,
    cursor: not-allowed,
    opacity: 0.3,
  ),
  locked: (
    backgroundColor: $color-hit-gray,
    backgroundColorHover: $color-regent-gray,
    color: $color-white,
    colorHover: $color-white,
    fill: $color-white,
    cursor: pointer,
    opacity: 1,
  ),
);

@each $button-color-type, $map in $button-colors {
  .button-#{$button-color-type} {
    @include button-states(
      map-get($map, backgroundColorHover),
      map-get($map, colorHover),
      map-get($map, cursor)
    );
    @include button-box-shadow(map-get($map, boxShadow));
    background-color: map-get($map, backgroundColor);
    color: map-get($map, color);
    opacity: map-get($map, opacity);

    &__icon {
      fill: map-get($map, fill);
    }
  }
}
