@mixin animation-pulse($reverse: false, $inline: false) {
  animation-name: if($inline, pulse-inline, pulse);
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-direction: if($reverse, alternate-reverse, alternate);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    background-color: $color-shuttle-grey;
    transform: scale(0.642);
  }

  100% {
    background-color: $color-regent-gray;
    transform: scale(1);
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulse-inline {
  0% {
    background-color: $color-hit-gray;
    transform: scale(0.714);
  }

  100% {
    background-color: $color-iron;
    transform: scale(0.4642);
  }
}

@keyframes pulse-inline-rebrand {
  0% {
    background-color: $color-hit-gray;
    transform: scale(0.714);
  }

  100% {
    background-color: $color-solitude;
    transform: scale(0.4642);
  }
}

@keyframes pulse-inline-dark {
  0% {
    background-color: $color-outer-space;
    transform: scale(0.714);
  }

  100% {
    background-color: $color-shuttle-grey;
    transform: scale(0.4642);
  }
}

@keyframes pulse-inline-dark-rebrand {
  0% {
    background-color: $color-black-pearl;
    transform: scale(0.714);
  }

  100% {
    background-color: $color-shuttle-grey;
    transform: scale(0.4642);
  }
}

@keyframes pulse-saving {
  0% {
    background-color: $color-shuttle-grey;
    transform: scale(0.714);
  }

  100% {
    background-color: $color-regent-gray;
    transform: scale(0.4642);
  }
}

.fadein.ng-enter {
  display: block;
  opacity: 0;
  transition: opacity ease-in-out 0.3s;

  &.ng-enter-active {
    opacity: 1;
  }
}

.fadein.ng-enter-stagger {
  transition-delay: 0.2s;
  transition-duration: 0s;
}

.modal-container.ng-enter {
  opacity: 0;
  transition: opacity ease-in-out 1s;

  &.ng-enter-active {
    opacity: 1;
  }
}
