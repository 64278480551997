.radial-progress {
  position: relative;
  float: inherit;

  &__circular-svg,
  &__svg {
    float: inherit;
    transform: rotate(-90deg);

    @include ml-down {
      width: 50px;
      height: 50px;
    }

    @include ml-up {
      width: 60px;
      height: 60px;
    }
  }

  &__guide {
    stroke: $color-iron;
    stroke-width: 8;
    fill: none;

    .rebrand & {
      stroke: $color-solitude;
    }

    &--required {
      stroke: $color-crimson;
    }

    &--darker {
      stroke: $color-hit-gray;
    }
  }

  &__bar {
    transition: stroke-dashoffset 0.3s linear;
    stroke: $color-shuttle-grey;
    stroke-width: 8;
    fill: none;
  }

  &__complete {
    &--required {
      stroke: $color-cerulean;

      .rebrand & {
        stroke: $color-dodger-blue;
      }
    }
  }

  &__percent,
  &__text {
    @include type-body-secondary;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
  }
}
