@import "styles/base/colors";
@import "styles/utilities/typography";
@import "styles/utilities/mixins";
@import "styles/components/articles";

$listPadding: 5px 44px 5px 44px;

.editor-block {
  @include article-block-border;
  position: relative;
  padding: 20px 0;
  width: 100%;

  &:last-of-type {
    background: none;
  }

  &__template {
    background-color: $color-athens-gray;
  }

  &__ol {
    @include article-ul;
    padding: $listPadding;
  }

  &__ul {
    @include article-ol;
    padding: $listPadding;
  }
}
