.changelog {
  &__icon-container {
    cursor: pointer;
    position: relative;
  }

  &__icon {
    @include icon-size(16px);
    position: absolute;
    top: 45%;
    right: 5px;
    transform: translate(0, -50%);
    fill: $color-regent-gray;
  }
}
