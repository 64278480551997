.modal-translation-settings {
  max-width: calc(506px + #{$navigation-width} + (2 * #{$margin-small}));

  &__header {
    padding-bottom: 20px;
    background: $color-white;
  }

  &__title {
    @include font-modal-primary-heading;
    @include modal-primary-title;
    background: $color-white;
  }

  &__search-wrapper {
    background: white;
    padding: 0 30px;
  }

  &__search {
    width: 100%;
    margin: 0;
    float: none;
    margin-top: 20px;
  }

  &__radio-input {
    display: none;

    &:checked + .modal-translation-settings__radio-label {
      .modal-translation-settings__radio-label-copy {
        background: $color-cerulean;
        color: $color-white;

        .rebrand & {
          background: $color-dodger-blue;
        }
      }
    }
  }

  &__radio-label {
    display: block;
    padding: 10px 0;
    margin: 3px 0;
    cursor: pointer;
  }

  &__radio-label-copy {
    display: inline-block;
    padding: 0 4px;
  }
}
