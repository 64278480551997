.search-container {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "searchBar searchBar"
    ". searchResults";
}

.search-bar-container {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-area: searchBar;
  box-shadow: 0 2px 6px 0 rgba(black, 0.1), 0 6px 16px -6px rgba(black, 0.3);
}
