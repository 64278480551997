@import "styles/utilities/mixins";
@import "styles/base/colors";
@import "styles/utilities/typography";
@import "styles/base/z-index";
@import "styles/base/variables";

.edit-banner {
  @include type-subheading;
  position: fixed;
  z-index: map-get($z-index, edit-banner);
  top: 0;
  left: $navigation-width;
  display: flex;
  padding: 10px 40px 10px 30px;
  width: calc(100% - #{$navigation-width});
  background-color: $color-cerulean;
  color: $color-white;
  justify-content: space-between;

  .rebrand & {
    background-color: $color-dodger-blue;
  }

  &__icon {
    position: relative;
    top: 2px;
    float: left;
    width: 24px;
    height: 24px;
    fill: $color-white;
  }

  &__text {
    @include vertical-center;
    margin: 2px 0 0 36px;
    vertical-align: bottom;
    text-transform: uppercase;
  }

  &__button-container {
    button {
      margin-left: 8px;
    }
  }
}
