.uploader {
  &__image-preview-container {
    float: left;
    position: relative;
    width: 100%;
    max-width: 300px;
  }

  &__image-preview {
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    overflow: hidden;
    background-color: $color-outer-space;

    .rebrand & {
      background-color: $color-black-pearl;
    }

    &--placeholder {
      @include image-placeholder($color-porcelain);

      .rebrand & {
        @include image-placeholder-rebrand($color-solitude);
      }
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 300px;
    max-height: 100%;
    transform: translateY(-50%) translateX(-50%);
  }

  &__image-buttons {
    position: absolute;
    bottom: 0;
    left: 100%;
    width: 240px;
    margin-left: 12px;
  }

  &__icon {
    @include icon-size(16px);
  }
}
