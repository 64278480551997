@import "styles/base/colors";
@import "styles/utilities/typography";
@import "styles/utilities/mixins";

$parent: media-placeholder;

.#{$parent} {
  &__header {
    position: relative;
    max-width: 541px;
    background-color: $color-white;
    border: 1px solid transparent;
    border-radius: 2px;
    margin: 0 auto;
    padding: 15px 24px;
    box-shadow: 0 0 2px 2px $color-porcelain;
    z-index: 1;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &__block {
    position: relative;
    margin: 0 auto;
    padding: 60px 0;
    border: 4px solid transparent;
    max-width: 541px;
    width: 488px;
    height: 274px;
    background: $color-porcelain;
    transition: border 0.2s ease-in-out, background 0.2s ease-in-out;
    text-align: center;

    &--drag-active {
      border-color: $color-cerulean;

      .rebrand & {
        border-color: $color-dodger-blue;
      }
    }

    &--has-focus {
      transition: opacity 0.1s ease-in;

      &:after {
        content: "";
        background-color: $color-cerulean;
        border: 4px solid $color-cerulean;
        top: -4px;
        left: -4px;
        position: absolute;
        width: 100%;
        height: calc(100%);
        z-index: 1;
        opacity: 0.5;

        .rebrand & {
          border-color: $color-dodger-blue;
          background-color: $color-dodger-blue;
        }
      }
    }
  }

  &__drop {
    position: absolute;
    z-index: 2;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    background: $color-porcelain;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &--enter {
      opacity: 0.01;
    }

    &--enter-active {
      opacity: 1;
    }

    &--exit {
      opacity: 0.01;
    }

    &--exit-active {
      opacity: 0;
    }

    &--visible {
      opacity: 1;
    }
  }

  &__dropdown {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
  }

  &__drop-text {
    @include type-title;
    @include unselectable;
    position: absolute;
    z-index: 3;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    color: $color-hit-gray;
  }

  &__icon {
    position: relative;
    z-index: 0;
    fill: rgba($color-regent-gray, 0.5);
    width: 48px;
    height: 48px;
  }

  &__caption {
    @include type-note-primary;
    margin-top: 7px;
    text-shadow: 0 0 0 $color-regent-gray;
    color: transparent;
    font-style: italic;
    text-transform: uppercase;
  }

  &__button-wrapper {
    position: relative;
    display: inline-block;
    overflow: hidden;
  }

  &__button {
    @include type-body-primary;
    position: relative;
    z-index: 1;
    display: block;
    margin-top: 11px;
    text-shadow: 0 0 0 $color-cerulean;
    color: $color-cerulean;
    text-decoration: underline;

    .rebrand & {
      color: $color-dodger-blue;
      text-shadow: 0 0 0 $color-dodger-blue;
    }
  }

  &__selectable {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    color: transparent;
  }
}
