.image-playback {
  &-container {
    position: relative;

    img {
      position: relative;
      z-index: 1;
    }

    canvas {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  &-container,
  &-overlay {
    display: flex;
    justify-content: center;
  }

  &-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    min-width: 50px;
    min-height: 50px;
    z-index: 3;

    svg {
      width: 30px;
    }

    &:hover {
      svg {
        display: none;
      }

      + img + canvas {
        z-index: -1;
      }
    }
  }
}
