.time-slot {
  position: absolute;
  width: 100%;
  padding: 5px 10px 5px 24px;
  overflow: hidden;
  background: $color-white;
  opacity: 1;
  transition: border ease-in 0.1s, opacity ease-in 0.2s;
  cursor: pointer;

  // ng-animations on appending
  &.ng-enter {
    opacity: 0;
    transition: opacity ease-in 0.2s;
  }

  &.ng-enter-active {
    opacity: 1;
  }

  &.ng-leave {
    opacity: 1;
  }

  &.ng-leave-active {
    opacity: 0;
    transition: opacity ease-out 2s;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 100%;
    background-color: $color-regent-gray;
    content: "";
    transition: border ease-in 0.1s;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    background-color: transparent;
    content: "";
    transition: background-color ease-in 0.2s;
  }

  &:hover {
    z-index: 10;
    transition: background-color ease-in 0.2s;

    &::before {
      background-color: $color-cerulean;

      .rebrand & {
        background-color: $color-dodger-blue;
      }
    }

    &::after {
      background-color: rgba($color-cerulean, 0.1);

      .rebrand & {
        background-color: rgba($color-dodger-blue, 0.1);
      }
    }

    .time-slot__additional-content {
      opacity: 1;
      transition: all 0.1s ease-in 0.2s;
    }
  }

  &--has-adjacent-top {
    border-top: 1px solid $color-iron;

    .rebrand & {
      border-top-color: $color-solitude;
    }
  }

  &--has-adjacent-bottom {
    border-bottom: 1px solid $color-iron;

    .rebrand & {
      border-bottom-color: $color-solitude;
    }
  }

  &--live {
    &::after {
      background-color: rgba($color-cerulean, 0.05);

      .rebrand & {
        background-color: rgba($color-dodger-blue, 0.05);
      }
    }
  }

  &--invalid {
    background-color: $color-white;
    border-top: 1px solid $color-iron;
    border-bottom: 1px solid $color-iron;

    .rebrand & {
      border-top-color: $color-solitude;
      border-bottom-color: $color-solitude;
    }

    &::before,
    &:hover::before {
      background-color: $color-crimson;
    }

    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($color-tickle-pink, 0.1);
      content: "";
    }

    &:hover::after {
      background-color: rgba($color-tickle-pink, 0.2);
    }
  }

  &--expandable {
    z-index: 0;
    transition: z-index 0.1s step-end, max-height ease-in-out 0.1s,
      height ease-in-out 0.1s;

    // !important needs to override properties being written to DOM in a style declaration.
    &:hover {
      z-index: 20;
      height: 110px !important;
      max-height: 110px !important;
      background: $color-white;
      border-bottom: 1px solid $color-iron;
      transition: z-index 0.2s step-start, max-height ease-out 0.2s,
        height ease-out 0.2s;

      .rebrand & {
        border-bottom-color: $color-solitude;
      }
    }
  }

  &--expandable-and-invalid {
    &:hover {
      height: 200px !important;
      max-height: 200px !important;
    }
  }

  &__content {
    @include clearfix();
    width: 100%;
    opacity: 1;
    transition-delay: 0.2s;

    &.ng-hide {
      opacity: 0;
    }

    &.ng-hide-animate {
      opacity: 1;
      transition: opacity 0.2s ease-in 0.2s;
    }

    &.ng-hide.ng-hide-remove,
    &.ng-hide-add {
      opacity: 0;
      transition: opacity 0.2s ease-in 0.2s;
    }
  }

  &__title {
    @include type-body-primary;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }

    &--medium-duration {
      float: left;
      margin-top: -3px;
    }
  }

  &__duration {
    @include type-note-primary;
    color: $color-regent-gray;

    &--medium-duration {
      float: left;
      margin-right: 12px;
    }
  }

  &__additional-content {
    @include type-body-primary;
    padding-top: 4px;
    color: $color-regent-gray;
    opacity: 0;
    transition-delay: 0s;
    transition-duration: 0s;
  }

  &__running-time {
    padding-top: 15px;

    &--invalid {
      color: $color-crimson;
    }
  }

  &__icon-expand {
    @include icon-size(24px);
    max-height: 8px;
    fill: $color-regent-gray;
  }

  &__icon-invalid {
    @include icon-size(24px);
    fill: $color-crimson;
  }
}
