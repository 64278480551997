.upload-progress-modal {
  $gradient-length: 120px;
  width: 360px;

  &__item {
    $itemBlock: &;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 15px 20px;
    background-color: $color-white;
    align-items: center;

    &--error {
      background-color: $color-lavender-blush;

      #{$itemBlock}__title {
        color: $color-regent-gray;
      }

      #{$itemBlock}__status {
        color: $color-crimson;
      }

      .text-scroller:after {
        background: linear-gradient(
          to right,
          transparent,
          $color-lavender-blush
        );
      }
    }

    &--complete {
      background-color: $color-narvik;

      .text-scroller:after {
        background: linear-gradient(to right, transparent, $color-narvik);
      }
    }

    &--canceled {
      background-color: $color-athens-gray;

      #{$itemBlock}__status {
        color: $color-oslo-gray;
      }

      #{$itemBlock}__actions {
        background: linear-gradient(
          90deg,
          transparent,
          $color-athens-gray $gradient-length
        );
      }

      .text-scroller:after {
        background: linear-gradient(to right, transparent, $color-athens-gray);
      }
    }

    &__icon {
      width: 18px;
      height: 18px;
      margin-right: 15px;
    }

    &__title {
      flex: 1;
      font-size: 14px;
      color: inherit;
      margin-bottom: 8px;
    }

    &__progress {
      margin-left: 33px;
      width: 100%;
    }

    &__progress-spinner {
      @include spinner-pulse($size: 10px, $keyframe: pulse-inline-dark);
      display: flex;

      .rebrand & {
        @include spinner-pulse(
          $size: 10px,
          $keyframe: pulse-inline-dark-rebrand
        );
      }
    }

    &__status {
      @include type-note-primary;
      width: 20%;
      text-align: right;
      margin-bottom: 7px;
      color: $color-regent-gray;
    }

    &__actions {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        transparent,
        $color-white $gradient-length
      );
      padding-left: $gradient-length;
      opacity: 1;
      transition: opacity 0.2s linear;

      &__link {
        padding: 8px;
        margin-right: 17px;

        &__icon {
          height: 24px;
          width: 24px;
          margin-top: 4px;
          fill: $color-regent-gray;
        }
      }

      &__button {
        @include type-body-primary;
        margin-right: 10px;
        margin-left: 10px;
        color: $color-cerulean;
        font-weight: bold;
        box-shadow: none;

        .rebrand & {
          color: $color-dodger-blue;
        }
      }
    }

    &:not(:hover) &__actions {
      opacity: 0;
      transition-duration: 0s;
    }
  }

  &__content {
    max-height: 250px;
    overflow-y: auto;
  }

  .collapsible-panel {
    box-shadow: 3px 0px 7px 0px rgba($color-black, 0.15);

    &__header {
      margin-top: 0;
      background-color: $color-shark;
      padding: 11px 20px;

      .rebrand & {
        background-color: $color-east-bay;
      }
    }

    &__header-title {
      color: $color-white;
      font-size: 16px;
    }

    &__svg,
    &__header:hover,
    &__header:hover &__svg {
      fill: $color-white;
    }
  }

  .text-scroller {
    height: 20px;
  }
}
