@import "styles/base/colors";
@import "styles/utilities/typography";
@import "styles/components/dropdown";

$parent: dropdown-editor-controls;

.#{$parent} {
  @include type-body-primary;
  @include dropdown-wrapper;
  display: inline-block;
  float: left;
  padding: 14px 4px 10px 21px;
  border-width: 1px;
  border-color: transparent;
  border-right-color: rgba($color-hit-gray, 0.3);
  border-bottom-color: transparent;
  width: 186px;
  transition: background-color 0.1s ease-in, opacity 0.1s ease-in-out;
  color: $color-regent-gray;
  line-height: 0;

  &:hover {
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &--disabled {
    background-color: $color-shuttle-grey;
    opacity: 0.3;

    &:hover {
      background-color: $color-shuttle-grey;
    }
  }

  &__icon {
    @include dropdown-icon($color-white);
    top: calc(50% - 2px);
    right: 18px;
    width: 20px;
    height: 20px;
  }

  &__text {
    @include dropdown-text;
    color: $color-white;
  }

  &__options {
    margin: 1px -1px 0 -1px;
    border-width: 1px;
    border-top: none;
  }
}
