@import "styles/base/colors";
@import "styles/components/modal";
@import "styles/modules/modal-list";
@import "styles/utilities/mixins";
@import "styles/components/lists";
@import "styles/base/grid";

$parent: modal-list;

.#{$parent} {
  @include modal;

  &__body {
    @include modal-body;
  }

  &__body-header {
    display: flex;
    position: relative;
    padding: 24px 24px 0;
  }

  &__data {
    min-height: 372px;
    position: relative;
    margin-top: 15px;
  }

  &__footer {
    @include modal-footer;
  }

  &__footer-button-wrap {
    @include modal-footer-button-wrap;
  }

  &__message-reset-button {
    @include reset-button;
    color: $color-cerulean;
    text-decoration: underline;
    cursor: pointer;

    .rebrand & {
      color: $color-dodger-blue;
    }
  }
}
