@import "styles/utilities/typography";
@import "styles/base/colors";

$drawer-width: 388px;
$default-bottom-margin: 28px;
$child-block: ".public-DraftStyleDefault-block";

@mixin article-container {
  margin: 0 auto 28px;
  width: 100%;
  max-width: 912px;
}

@mixin article-title {
  @include type-title-article;
  color: $color-outer-space;
  font-size: 44px;
  line-height: 56px;

  .rebrand & {
    color: $color-black-pearl;
  }
}

@mixin article-subtitle {
  @include type-body-article;
  color: $color-outer-space;
  font-size: 28px;
  line-height: 36px;

  .rebrand & {
    color: $color-black-pearl;
  }
}

@mixin article-generic {
  @include type-body-article;
  color: $color-regent-gray;
}

@mixin article-header-generic($font-size: 28px, $line-height: 36px) {
  color: $color-outer-space;
  font-size: $font-size;
  line-height: $line-height;

  .rebrand & {
    color: $color-black-pearl;
  }
}

@mixin article-list {
  margin-left: 18px;

  li {
    margin-bottom: 0;
    padding-left: 7px;
  }
}

@mixin article-ul {
  @include article-list;
  list-style-type: disc;
}

@mixin article-ol {
  @include article-list;
  list-style-type: decimal;
}

@mixin article-direction-rtl {
  text-align: right;
  direction: rtl;
}

@mixin article-direction-ltr {
  text-align: left;
}

@mixin article-block-border {
  background-image: linear-gradient(
    to right,
    $color-hit-gray 33%,
    rgba($color-hit-gray, 0) 0%
  );
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 5px 1px;
}

@mixin article-image {
  margin: 0 auto;
  max-width: 430px;
  text-align: center;
  pointer-events: none;

  img {
    display: inline-block;
  }
}

@mixin article-media-block {
  position: relative;
  width: 541px;
  margin: 0 auto;

  &--has-focus {
    transition: opacity 0.1s ease-in;

    &:after {
      content: "";
      background-color: $color-cerulean;
      top: 48px;
      left: 0;
      position: absolute;
      width: 100%;
      height: calc(100% - 48px);
      z-index: 1;
      opacity: 0.5;

      .rebrand & {
        background-color: $color-dodger-blue;
      }
    }
  }
}

@mixin article-media-block-container {
  @include type-subheading;
  color: transparent;
  margin: 0 auto;
  max-width: 541px;
  text-align: center;
  text-shadow: 0 0 0 $color-regent-gray;
  border: 4px solid transparent;
  transition: border 0.2s ease-in-out;
}
