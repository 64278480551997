.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__filter-container {
    width: span(12 of 12 inside no-gutters);

    @include lg-up {
      width: span(6 of 12 inside no-gutters);
    }

    @include xl-up {
      width: span(8 of 12 inside no-gutters);
    }
  }

  &__filter {
    width: span(3 of 12 inside no-gutters);

    &:nth-child(3) {
      margin-right: 0;
    }
  }

  &__select-all {
    width: span(12 of 12 inside no-gutters);
    margin-bottom: 16px;

    @include lg-up {
      width: span(2 of 12 inside no-gutters);
      margin: 0 16px 16px 0;
    }
  }

  &__placeholder {
    @include type-body-primary;
    padding: 13px 16px 11px;
    color: $color-regent-gray;
    border: 2px solid $color-porcelain;
  }

  &__placeholder-icon {
    @include icon-size(24px);
    position: relative;
    top: -3px;
    float: right;
    fill: $color-hit-gray;
  }

  &__placeholder-state {
    margin-right: 20px;
  }

  &__toggle-view {
    width: span(12 of 12 inside no-gutters);
    min-width: 170px;
    margin-bottom: 16px;
    background-color: $color-porcelain;

    @include lg-up {
      width: span(2 of 12 inside no-gutters);
      margin: 0 16px 16px 0;
    }
  }

  &__pagination {
    align-self: start;
    flex-shrink: 0;
    margin-top: 10px;
    margin-bottom: 16px;
  }

  .search-and-filter__container > * {
    margin-bottom: 16px;
    margin-right: 20px;
  }

  &__list {
    background-color: $color-white;
  }

  &__arrow {
    position: relative;
    float: left;
    padding: 16px;
    margin-left: 4px;
    cursor: pointer;
    background-color: $color-outer-space;
    border: 0;
    border-radius: 2px;
    fill: $color-white;

    .rebrand & {
      background-color: $color-black-pearl;
    }

    &--disabled {
      cursor: default;
      background-color: $color-hit-gray;
      opacity: 0.4;
    }
  }

  &__arrow-icon {
    @include icon-size(24px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    .controls__arrow--disabled & {
      fill: $color-outer-space;

      .rebrand & {
        fill: $color-white;
      }
    }
  }
}
