@import "styles/base/colors";
@import "styles/utilities/mixins";
@import "styles/base/z-index";
@import "styles/base/variables";
@import "styles/components/articles";
@import "styles/utilities/media-queries";

$parent: editor-controls;

.#{$parent} {
  @include clearfix;
  position: absolute;
  z-index: map-get($z-index, editor-controls);
  display: inline-flex;
  padding-top: 20px;
  width: 100%;
  max-width: 848px;
  background-color: $color-porcelain;

  @include xxl-down {
    width: calc(100% - 64px);
  }

  &--open {
    @include xxxl-up {
      width: 100%;
    }
    position: absolute;
    width: calc(100% - 16px);
  }

  &--fixed {
    @include xxxl-up {
      width: calc(100% - #{$navigation-width});
    }

    @include xxl-down {
      width: calc(100% - #{$navigation-width + 128px});
    }

    position: fixed;
    top: 48px;
    width: calc(100% - #{$navigation-width + 64px});
    transform: translateZ(0);
  }

  &--open.#{$parent}--fixed {
    width: calc(100% - #{$navigation-width + 64px} - #{$drawer-width + 16px});
  }

  &__container {
    display: inline-block;
    border-radius: 2px 2px 0 0;
    width: 100%;
    background-color: $color-shuttle-grey;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      right: 3px;
      bottom: 0;
      left: 1px;
      height: 10px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 4px 12px 0 rgba(0, 0, 0, 0.2);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      .#{$parent}--fixed & {
        opacity: 1;
      }
    }
  }

  &__enter {
    opacity: 0.01;

    &--active {
      opacity: 1;
      transition: opacity 2s ease-in-out 1s;
    }
  }

  &__exit {
    opacity: 1;

    &--active {
      opacity: 0.01;
      transition: opacity 2s ease-in-out;
    }
  }

  &__section {
    @include clearfix;
    float: left;
    padding: 8px 4px 8px 20px;
    border-right: 1px solid rgba($color-hit-gray, 0.3);
  }

  &__dropdown {
    float: right;
  }

  &__button {
    float: left;
    margin-right: 16px;
    padding: 2px 4px;
    border-radius: 2px;
    background-color: transparent;
    transition: background-color 0.1s ease-in, opacity 0.1s ease-in-out;
    line-height: 1;

    &:hover {
      background-color: rgba($color-hit-gray, 0.3);
    }

    &--active {
      background-color: $color-cerulean;

      .rebrand & {
        background-color: $color-dodger-blue;
      }

      &:hover {
        background-color: $color-cerulean;

        .rebrand & {
          background-color: $color-dodger-blue;
        }
      }
    }

    &--disabled {
      background-color: $color-shuttle-grey;
      opacity: 0.3;

      &:hover {
        background-color: $color-shuttle-grey;
      }
    }
  }

  &__icon {
    position: relative;
    top: 2px;
    width: 24px;
    height: 24px;
    color: $color-white;
    fill: $color-white;
  }
}
