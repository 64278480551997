// Classes for modal in list
.modal-list {
  &__header {
    display: flex;
    background-color: $color-shuttle-grey;
  }

  &__title {
    @include type-title;
    padding: 24px;
    color: $color-white;

    &--local-nav {
      padding-bottom: 0;
    }
  }

  &__content {
    background-color: $color-white;
  }

  &__content-header {
    display: flex;
    position: relative;
    padding: 24px 24px 0;
  }

  &__filters {
    flex-grow: 1;
  }

  // Create button
  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > * {
      margin-bottom: 16px;
    }
  }

  &__data {
    position: relative;
    background-color: $color-white;
    min-height: 235px;

    .loading--inline {
      margin: 0;
    }
  }

  &__loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;

    // Overwrite loading state positioning
    .loading__text {
      position: relative;
      width: 100%;
      margin-top: 20px;
    }

    .loading--inline {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  // Listing overwrite
  .table {
    min-height: 105px;

    .table__head {
      border-bottom: 1px solid rgba($color-hit-gray, 0.35);
    }

    &__row {
      padding: 0;
    }

    &__row-inner,
    &__head-inner {
      padding: 0 24px;

      // Overwrite checkbox table cell padding
      .table__column:first-of-type .table__cell {
        padding: 0 12px;
      }
    }

    &__body {
      max-height: calc(100vh - 360px);
      overflow-y: auto;
    }

    &__body > div:first-of-type .table__row-inner {
      box-shadow: none;
    }
  }

  &__footer {
    @include clearfix;
    margin-top: 16px;
  }

  &__footer-button-wrap {
    display: inline-block;

    &--right {
      float: right;
      margin-left: 10px;
    }
  }

  // Vertically align checkboxes
  // in modal list rows
  .table__row .checkbox-input__label-icon {
    margin-top: 4px;
  }

  .search-and-filter {
    &__container {
      display: flex;
      flex: 1;

      .dropdown-widget__container {
        flex: 1;

        .dropdown-widget__control {
          background: $color-porcelain;
          border: 2px solid $color-porcelain;

          &--menu-is-open {
            border: 2px solid $color-iron;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            color: $color-outer-space;
            background: $color-white;

            .rebrand & {
              color: $color-black-pearl;
              border-color: $color-solitude;
            }

            .dropdown-widget__indicators {
              background-color: $color-outer-space;

              .rebrand & {
                background-color: $color-black-pearl;
              }
            }
          }
        }
      }

      .search-field__label {
        flex: 1;

        .text-input {
          @extend .text-input;
        }
      }
    }
  }
}
