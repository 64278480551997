@import "styles/base/colors";
@import "styles/utilities/typography";
@import "styles/components/dropdown";

$parent: dropdown-localisation-navigation;

.#{$parent} {
  @include type-body-primary;
  @include dropdown-wrapper;
  padding: 13px 16px 11px;
  border-color: transparent;
  border-radius: 2px;
  min-width: 170px;
  height: 100%;
  background-color: transparent;
  color: $color-hit-gray;

  &:hover {
    border-color: $color-iron;
    border-radius: inherit 0;
    background-color: rgba($color-hit-gray, 0.2);
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
      border-color: $color-solitude;
    }
  }

  &__icon {
    position: absolute;
    top: calc(50% - 2px);
    right: 22px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    fill: $color-hit-gray;

    .#{$parent}:hover & {
      fill: $color-cerulean;

      .rebrand & {
        fill: $color-dodger-blue;
      }
    }
  }

  &__text {
    @include type-subheading;
    @include dropdown-text;
    color: $color-regent-gray;
  }
}
