.filter-nav {
  @include clearfix;
  padding-top: 18px;
  overflow-y: hidden;
  white-space: nowrap;
  position: relative;

  &--light {
    background-color: $color-white;
  }

  &--dark {
    background-color: $color-shuttle-grey;
  }

  &--colour {
    background-color: $color-cerulean;

    .rebrand & {
      background-color: $color-dodger-blue;
    }
  }

  &--transparent {
    background: transparent;
  }

  &__border {
    position: absolute;
    height: 4px;
    width: 10px;
    background: $color-cerulean;
    bottom: 0;
    transition: width 0.16s ease-in-out, left 0.16s ease-in-out;

    .rebrand & {
      background: $color-dodger-blue;
    }
  }

  &__wrapper {
    height: 57px;
    margin-bottom: 15px;
    overflow: hidden;
    border-bottom: 1px solid $color-iron;

    .rebrand & {
      border-bottom-color: $color-solitude;
    }

    &--has-subtext {
      height: auto;
      min-height: 76px;
    }
  }

  &--inline-content-filter {
    padding-top: 0;
    margin-top: 20px;
  }

  &__filter-text-wrapper {
    position: relative;
  }

  &__filter-options {
    @include clearfix;
  }

  &__filter-item {
    position: relative;
    display: block;
    float: left;
  }

  &__count {
    color: $color-regent-gray;
    transition: 0.2s ease-in-out;

    .filter-nav__filter-title:hover & {
      color: $color-cerulean;

      .rebrand & {
        color: $color-dodger-blue;
      }
    }

    .filter-nav__filter-title.active & {
      color: $color-regent-gray;
    }
  }

  &__filter-title {
    @include type-subheading;
    user-select: none;
    position: relative;
    float: left;
    padding: 17px 24px 13px;
    margin-right: 8px;
    color: $color-regent-gray;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    .rebrand & {
      text-transform: none;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      cursor: default;
    }

    &--on-light {
      &:hover {
        color: $color-cerulean;

        .rebrand & {
          color: $color-dodger-blue;
        }
      }

      &.active {
        color: $color-outer-space;
        box-shadow: inset 0 -4px 0 0 $color-cerulean;

        .rebrand & {
          color: $color-black-pearl;
          box-shadow: inset 0 -4px 0 0 $color-dodger-blue;
        }
      }
    }

    &--on-dark {
      &:hover {
        color: $color-cerulean;

        .rebrand & {
          color: $color-dodger-blue;
        }
      }

      &.active {
        color: $color-white;
        box-shadow: inset 0 -4px 0 0 $color-cerulean;

        .rebrand & {
          box-shadow: inset 0 -4px 0 0 $color-dodger-blue;
        }
      }
    }

    &--on-colour {
      color: rgba($color-white, 0.6);

      &:hover {
        color: $color-white;
      }

      &.active {
        color: $color-white;
        box-shadow: inset 0 -4px 0 0 rgba($color-white, 0.5);
      }
    }

    &--small {
      font-size: 15px;
      padding: 3px 7px 13px;
      margin-right: 27px;

      &.active {
        box-shadow: none !important;
      }
    }
  }

  &__filter-range-title {
    display: inline-block;
  }

  &__filter-range {
    &:hover {
      cursor: pointer;
    }
  }

  &__filter-subtext {
    @include type-note-primary;
    display: block;
    padding-top: 5px;
    color: $color-regent-gray;
    text-transform: capitalize;
  }

  &__icon {
    @include icon-size(24px);
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    fill: $color-regent-gray;
  }

  &__filter-content {
    position: relative;
  }

  &__range-filter {
    float: right;
    margin: 13px 0 0 15px;
  }

  &__range-filter-toggle {
    @include icon-size(24px);
    position: absolute;
    right: -30px;
    padding: 0;
    cursor: pointer;
    background: none;
    border: 0;
    outline: none;
    transform: translateY(50%);
    appearance: none;
  }

  // TODO : BEMify
  .datepicker-start,
  .datepicker-end,
  .local-nav__search-field {
    color: $color-outer-space;
    background-color: $color-white;
    border: 2px solid $color-iron;

    .rebrand & {
      color: $color-black-pearl;
      border-color: $color-solitude;
    }
  }
}
