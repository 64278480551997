@import "styles/base/z-index";
@import "styles/base/colors";
@import "styles/utilities/mixins";
@import "styles/utilities/typography";
@import "styles/utilities/media-queries";
@import "styles/base/variables";

@mixin modal {
  position: fixed;
  z-index: map-get($z-index, modal-content);
  top: 50%;
  left: 50%;
  width: 100%;
  min-width: 320px;
  max-width: 1472px;
  padding-left: 30px;
  transform: translate(calc(-50% - 16px), -50%);
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @include xxl-up {
    padding-left: $navigation-width;
  }
}

@mixin modal-header {
  @include type-subheading;
  // TODO create new typography
  font-size: 22px;
  background: $color-white;
  color: $color-black;
  padding: 28px 0 34px 28px;
  text-transform: capitalize;
}

@mixin modal-header-classic {
  position: relative;
  padding: 24px;
  background: $color-shuttle-grey;
  color: $color-white;
  @include type-title;
  text-transform: capitalize;
}

@mixin modal-body {
  overflow-y: auto;
  max-height: calc(100vh - 196px);
  background-color: $color-white;
}

@mixin modal-footer {
  @include clearfix;
  margin: 16px 0 0;
}

@mixin modal-footer-button-wrap {
  float: right;
  margin: 0 0 0 12px;
}

@mixin modal-footer-button-left {
  float: left;
  margin: 0 12px 0 0;
}

@mixin modal-footer-button {
  display: inline-block;
}

@mixin list-padding-modal {
  padding: 0 24px;
}
