.footer {
  @include clearfix;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;

  &__logo {
    @include icon-size(50px);
    fill: rgba($color-hit-gray, 0.5);

    .rebrand & {
      opacity: 0.1;
      font-size: 40px;
    }
  }
}
