.checkbox-input {
  display: none;
  cursor: pointer;

  + .checkbox-input__label {
    .checkbox-input__label-icon {
      position: absolute;
      top: -4px;
      left: 1px;
      border: 2px solid $color-iron;
      border-radius: 2px;
      fill: none;

      .rebrand & {
        border-color: $color-solitude;
      }
    }

    // Checkbox container
    &::before {
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: middle;
      content: "";
    }

    &--white .checkbox-input__label-icon {
      background-color: $color-white;
    }

    &:focus .checkbox-input__label-icon {
      background-color: $color-white;
      border: 2px solid $color-iron;

      .rebrand & {
        border-color: $color-solitude;
      }
    }

    &.lighter-color {
      .checkbox-input__label-icon {
        background-color: $color-porcelain;
      }

      &:focus .checkbox-input__label-icon {
        border-color: $color-porcelain;
      }
    }
  }

  &:checked + .checkbox-input__label {
    .checkbox-input__label-icon {
      display: inline-block;
      background-color: $color-cerulean;
      border: 0;
      fill: $color-white;

      .rebrand & {
        background-color: $color-dodger-blue;
      }
    }

    &:hover .checkbox-input__label-icon {
      color: $color-hit-gray;
    }
  }

  &__label {
    position: relative;
    cursor: pointer;
  }

  &__label-icon {
    @include icon-size(24px);
    background-color: $color-white;

    &--lowered {
      margin-top: 12px;
    }
  }
}
