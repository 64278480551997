.search-and-filter {
  display: flex;

  &__container {
    display: flex;
    flex-wrap: wrap;

    > * {
      margin-bottom: 16px;
      margin-right: 20px;
    }
  }
}
