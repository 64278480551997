@import "styles/utilities/typography";
@import "styles/base/colors";

.article-status {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;

  &__current {
    @include type-body-secondary;
    color: $color-hit-gray;
    padding: 15px;
  }
}
