.time-indicator {
  position: absolute;
  left: -19%;
  z-index: 100;
  width: calc(100% + 90px);
  height: 20px;
  transition: linear 0.2s;

  @include xl-up {
    left: -12%;
  }

  @include xxl-up {
    left: -7%;
  }

  &__time-container {
    @include type-note-primary;
    position: absolute;
    left: 19%;
    float: left;
    width: 50px;
    height: 20px;
    padding: 2px 0;
    color: $color-white;
    text-align: center;
    background-color: $color-cerulean;
    border-radius: 1px;

    .rebrand & {
      background-color: $color-dodger-blue;
    }

    &::after {
      position: absolute;
      top: calc(50% - 1px);
      left: 50px;
      width: 50px;
      height: 2px;
      background-color: $color-cerulean;
      content: "";

      .rebrand & {
        background-color: $color-dodger-blue;
      }
    }
  }

  &__line-container {
    position: absolute;
    float: left;
    width: 100%;
    height: 20px;
    padding: 9px 0;
  }

  &__line {
    width: 100%;
    height: 2px;
    background-color: $color-cerulean;

    .rebrand & {
      background-color: $color-dodger-blue;
    }
  }
}
