.collapsible-panel {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  &__count {
    @include type-subheading;
    float: left;
    width: 48px;
    height: 48px;
    line-height: 50px;
    color: $color-white;
    text-align: center;
    vertical-align: middle;
    background-color: $color-hit-gray;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 24px;
    margin-top: 20px;
    cursor: pointer;
    background: $color-white;

    &__content {
      flex: 1;
    }

    &--group {
      padding: 0 24px 0 0;
      margin-top: 0;

      .collapsible-panel__header-title {
        @include type-note-secondary;
        padding: 12px;
      }

      .collapsible-panel__header-meta {
        padding-top: 14px;
      }
    }

    &:hover {
      background-color: $color-anakiwa-alpha;

      .rebrand & {
        background-color: $color-alice-blue-alpha;
      }

      .collapsible-panel__svg {
        fill: $color-cerulean;

        .rebrand & {
          fill: $color-dodger-blue;
        }
      }
    }

    &--open {
      background-color: $color-iron;

      .rebrand & {
        background-color: $color-solitude;
      }

      &:hover {
        background-color: darken($color-iron, 2%);

        .rebrand & {
          background-color: darken($color-solitude, 2%);
        }
      }
    }

    &--locked {
      cursor: default;
      background-color: $color-iron;

      .rebrand & {
        background-color: $color-solitude;
      }

      &:hover {
        background-color: $color-iron;

        .rebrand & {
          background-color: $color-solitude;
        }

        .collapsible-panel__svg {
          fill: $color-hit-gray;
        }
      }

      .collapsible-panel__header-title {
        color: $color-hit-gray;
      }
    }

    &--error {
      background-color: $color-tickle-pink;
    }

    &-title {
      @include type-panel-title;
      position: relative;
      width: auto;
      overflow: hidden;
      color: $color-outer-space;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;

      .rebrand & {
        color: $color-black-pearl;
      }
    }

    &-meta {
      margin-left: auto;
      &-count {
        @include type-subheading;
        position: relative;
        top: -2px;
        padding: 24px;
        text-align: center;
        text-transform: uppercase;
      }
      &-count--highlight {
        display: inline-block;
        width: 30px;
        height: 30px;
        padding: 0;
        padding-left: 1px;
        margin-right: 10px;
        line-height: 33px;
        color: $color-white;
        text-align: center;
        vertical-align: bottom;
        background-color: $color-crimson;
        border-radius: 50%;
      }
    }

    &-indicator {
      .collapsible-panel__svg {
        width: 20px;
        height: 20px;
        transition: 0.2s transform ease;
        fill: $color-hit-gray;
      }

      &--open .collapsible-panel__svg {
        transform: rotate(-180deg);
      }
    }
  }

  &__content {
    overflow: hidden;
    background: $color-white;
    transition: 0.25s max-height linear;

    &--finished-opening {
      @include clearfix();
      overflow: initial;
    }
  }

  &__requirement-indicator {
    @include icon-size(20px);
    border-radius: 50%;
    fill: $color-crimson;
    right: -22px;
    top: 0;
  }

  &__requirement-indicator-container {
    top: -12px;
    position: relative;
  }
}
