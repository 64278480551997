.form {
  @include clearfix;

  &__content {
    float: left;
    width: calc(100% - 96px);
    padding-right: 44px;

    &__full-width {
      width: 100%;
    }
  }

  &__actions {
    float: left;
    width: 96px;

    &--image-create,
    &--document-create {
      position: absolute;
      top: 214px;
      right: 32px;
    }
  }

  &__group {
    padding: 22px 0;
  }

  &__row {
    @include clearfix;
    position: relative;
    margin-bottom: 20px;

    &--flex {
      display: flex;
    }
  }

  &__field-label {
    @include span(2 of 12 inside no-gutters);
    @include type-body-primary;
    display: block;
    max-width: 160px;
    padding: 12px 12px 12px 0;
    color: $color-regent-gray;
    overflow: hidden;
    overflow-wrap: break-word;

    &--has-subtitle {
      padding: 6px 12px 6px 0;
    }
  }

  &__field-label-required {
    color: $color-crimson;
  }

  &__field-label-description {
    @include type-note-primary;
    color: $color-hit-gray;
  }

  &__field-tooltip {
    display: inline-block;
  }

  &__text-input-container {
    @include span(12 of 12 inside no-gutters);
    position: relative;
    max-width: 288px;
    word-break: break-all;

    &--dynamic-objects {
      width: calc(27% - 20px);
    }

    @include lg-up {
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &--large {
      width: 100%;
      float: left;
      max-width: none;
      margin-right: 0;
    }

    &:hover {
      .dropdown__arrow {
        background-color: $color-outer-space;

        .rebrand & {
          background-color: $color-black-pearl;
        }
      }
    }
  }

  &__textarea-input-container {
    @include span(12 of 12 inside no-gutters);
    max-width: 488px;
    color: $color-regent-gray;

    @include ml-up {
      min-height: 180px;
    }

    &--inactive {
      @include ml-up {
        min-height: auto;
      }
    }
  }

  &__field-container {
    @include span(10 of 12 inside no-gutters);
    position: relative;

    &--narrow {
      @include span(8 of 12 inside no-gutters);
    }
  }

  &__field {
    width: 100%;

    .form & {
      display: none;
    }

    &.active {
      display: block;
      animation: fade-in 0.3s;
    }

    &__outer-container {
      display: flex;
      align-items: center;
    }

    &__data-source--flex {
      display: flex;
    }
  }

  .dropdown {
    max-width: 288px;
  }
}
