$parent: navigation-section;

@mixin nested-list-item {
  @include type-navigation-primary;

  &:hover {
    color: $color-white;
  }

  &.active {
    color: $color-summer-sky;

    .rebrand & {
      color: $color-white;
    }
  }
}

@mixin item-line {
  content: "";
  position: absolute;
  left: -28px;
  top: 8px;
  height: 1px;
  width: 11px;
  background: $color-cape-cod;

  .rebrand & {
    background: $color-chateau-gray;
  }
}

@mixin nested-list-closed {
  margin: 0;
  opacity: 0;
  pointer-events: none;
}

@mixin list-transition {
  transition: margin 0.07s ease-in-out, opacity 0.15s ease-in-out 0.07s;
}

.navigation-section {
  margin-top: 15px;

  &__sections {
    transition: all 0.15s ease-in-out;

    &--closed {
      max-height: 0 !important;
      opacity: 0;
      margin: 0;
      overflow: hidden;
    }
  }

  &__list {
    @include list-transition;
    opacity: 1;

    .navigation-section__nested-list--closed > li > & {
      @include nested-list-closed;
    }
  }

  &__title {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: -1px;
    z-index: 1;

    &:before {
      @include item-line;
      top: 8px;
    }

    &:hover {
      .navigation-section__title-text {
        color: $color-white;

        &--signout {
          color: rgba($color-white, 0.65);
          cursor: auto;
        }
      }

      .navigation-section__title-arrow {
        fill: rgba($color-white, 0.8);
      }
    }

    .navigation-section__nested-list & {
      @include nested-list-item;
      margin-bottom: -2px;
    }

    &--first-level {
      color: $color-white;

      &:before {
        display: none;
      }
    }

    &--disabled {
      opacity: 0.3;
      cursor: default !important;
    }
  }

  &__title-text {
    @include type-navigation-primary;
    cursor: inherit;
    user-select: none;
    transition: color 0.15s ease-in-out;

    &--active {
      color: $color-white;
    }

    .navigation-section__title--disabled:hover & {
      color: rgba($color-white, 0.65);
    }
  }

  &__title-arrow {
    @include icon-size(17px);
    position: relative;
    margin-left: 1px;
    top: 3px;
    fill: rgba($color-white, 0.2);
    transition: fill 0.15s ease-in-out;

    &--active {
      svg {
        fill: $color-white;
      }
    }

    .navigation-section__title--disabled:hover & {
      fill: rgba($color-white, 0.2);
    }
  }

  &__title-icon {
    position: relative;
    width: 16px;
    height: 16px;
    top: 1px;
    margin-right: 8px;
    fill: none;
    stroke: rgba($color-white, 0.55);
  }

  &__list-item {
    display: block;
    opacity: 0;
    transition: margin 0.15s ease-in-out, opacity 0.3s ease-in-out 0.3s;

    &--empty {
      margin-top: 12px;

      .rebrand & {
        margin-bottom: 6px;
      }
    }

    &--has-items {
      margin-bottom: 14px;

      .rebrand & {
        margin-bottom: 7px;
      }
    }

    &--ready {
      opacity: 1;
    }

    .navigation-section__list-item--closed & {
      margin: 0;
    }
  }

  &__nested-list {
    @include list-transition;
    padding-left: 36px;
    margin-top: 10px;
    display: block;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      left: 8px;
      bottom: -21px;
      width: 1px;
      background: $color-cape-cod;
      top: -4px;

      .rebrand & {
        background: $color-chateau-gray;
      }
    }

    &:last-of-type {
      &:before {
        opacity: 0;
      }
    }

    &:only-of-type {
      &:before {
        opacity: 1;
        bottom: 10px;
      }
    }

    &--signout {
      padding: 0;
      margin-top: 5px;

      &:before {
        display: none;
      }
    }

    .navigation-section__list-item--closed & {
      margin: 0;
    }
  }

  &__link-container {
    text-decoration: none;
    position: relative;

    &:hover {
      .navigation-section__icon-close {
        visibility: visible;
      }
    }
  }

  &__link {
    @include nested-list-item;
    @include list-transition;
    color: rgba($color-white, 0.65);
    opacity: 1;
    display: block;
    width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:before {
      @include item-line;
      top: 9px;

      .user-profile-nav & {
        display: none;
      }
    }

    &--signout {
      &:before {
        display: none;
      }
    }

    .navigation-section__nested-list--closed
      > .navigation-section__list-item
      > & {
      @include nested-list-closed;
    }

    .navigation-section__title--disabled & {
      cursor: default;
    }
  }

  &__icon-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    fill: rgba($color-white, 0.65);
    cursor: pointer;
    visibility: hidden;

    &:hover {
      fill: $color-white;
    }
  }
}
