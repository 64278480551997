.collapsible-preview {
  position: relative;
  bottom: -5px;

  &__container {
    position: relative;
    top: -3px;
    float: left;
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.1s ease-in;

    &:hover {
      background-color: rgba($color-cerulean, 0.2);
      transition: 0.2s ease-in;

      .rebrand & {
        background-color: rgba($color-dodger-blue, 0.2);
      }

      .collapsible-preview__icon {
        transition: 0.2s linear;
        fill: $color-cerulean;

        .rebrand & {
          fill: $color-dodger-blue;
        }
      }
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    fill: $color-regent-gray;

    &--open {
      transform: rotate(180deg);
    }
  }
}
