@import "styles/utilities/typography";
@import "styles/base/colors";

.default-column {
  @include type-body-primary;
  color: $color-outer-space;
  display: -webkit-box;
  max-height: 38px;
  overflow: hidden;
  box-orient: vertical;
  line-clamp: 2;

  .rebrand & {
    color: $color-black-pearl;
  }

  &--is-faded {
    opacity: 0.3;
  }

  &--drawer {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
}
