.multisearch {
  @include span(12 of 12 inside no-gutters);
  display: flex;
  flex-wrap: wrap;
  min-width: 200px;
  margin-right: 20px;

  @include lg-up {
    width: 75%;
  }

  &__container {
    position: relative;
    height: 48px;
    padding-right: $margin-small;
    background: $color-porcelain;
    border: 2px solid transparent;

    &--is-focussed,
    &:focus,
    &:hover {
      background-color: $color-white;
      border: 2px solid $color-iron;

      .rebrand & {
        border-color: $color-solitude;
      }

      &::after {
        display: none;
      }
    }
  }

  &__suggestion-container {
    position: absolute;
    z-index: 10;
    float: left;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 13px;
    background-color: $color-white;
    border: 2px solid $color-porcelain;
    border-top: 0;
  }

  &__suggestion {
    @include type-body-primary;
    padding: 9px 18px;
    color: $color-black;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__inner-container {
    @include span(4 of 10 inside no-gutters);
    position: relative;
    flex-grow: 1;
    margin-bottom: 16px;

    @include lg-up {
      float: left;
    }

    &:last-child {
      @include lg-up {
        margin-right: 0;
      }
    }
  }

  &__dropdown-container {
    @include span(2 of 12 inside no-gutters);

    // Extend 'Filter' button width
    .button-standard {
      width: 100%;
      text-align: center;
    }
  }

  &__tag-outer-container {
    @include span(10 of 12 inside no-gutters);
    position: relative;
  }

  &__tag-container {
    @include span(6 of 10 inside no-gutters);
    @include scroll-x;

    display: inline-block;
    height: 100%;
    overflow-y: hidden;
    text-align: left;
    white-space: nowrap;
    cursor: text;
    border-right: 2px solid $color-iron;

    .rebrand & {
      border-right-color: $color-solitude;
    }
  }

  &__input {
    width: calc(100% - 24px - 8px);
    background-color: transparent !important;
    border: 0 !important;

    &:focus,
    &:hover {
      .multisearch__input {
        background-color: $color-white;
      }
    }

    &.is-focussed {
      &::after {
        display: none;
      }
    }

    .multisearch__input {
      &:focus,
      &:hover {
        background-color: $color-white;
      }
    }
  }

  &__label {
    position: absolute;
    left: -9999px;
  }

  &__icon-container {
    position: absolute;
    top: 10px;
    right: 8px;
    width: 24px;
  }

  &__icon {
    @include icon-size(24px);
  }
}
