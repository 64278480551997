@import "styles/base/colors";
@import "styles/utilities/typography";
@import "styles/components/dropdown";

$parent: dropdown-article-objects;

.#{$parent} {
  @include type-body-primary;
  @include dropdown-wrapper;
  padding: 14px 4px 10px 24px;
  border: 1px solid transparent;
  border-top-right-radius: 2px;
  width: 191px;
  background-color: $color-cerulean;
  transition: background-color 0.1s ease-in, opacity 0.1s ease-in-out;
  color: $color-regent-gray;
  line-height: 0;

  .rebrand & {
    background-color: $color-dodger-blue;
  }

  &:hover {
    border-color: transparent;
    border-right-color: rgba($color-hit-gray, 0.3);
    border-bottom-color: transparent;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &--disabled {
    background-color: rgba($color-cerulean, 0.7);

    .rebrand & {
      background-color: rgba($color-dodger-blue, 0.7);
    }
  }

  &--light {
    background-color: $color-white;
    color: $color-cerulean;
    padding-left: 26px;
    width: 153px;

    .rebrand & {
      color: $color-dodger-blue;
    }

    &:hover {
      background-color: rgba($color-anakiwa, 0.2);
      border-color: transparent;

      .rebrand & {
        background-color: rgba($color-alice-blue, 0.2);
      }
    }
  }

  &__icon {
    @include dropdown-icon($color-white);
    top: 50%;
    right: 18px;
    width: 24px;
    height: 24px;
    transition: opacity 0.1s ease-in-out;

    .#{$parent}--disabled & {
      opacity: 0.3;
    }

    .#{$parent}--light & {
      fill: $color-cerulean;
      width: 20px;
      right: 24px;

      .rebrand & {
        fill: $color-dodger-blue;
      }
    }
  }

  &__text {
    @include dropdown-text;
    transition: opacity 0.1s ease-in-out;
    color: $color-white;

    .#{$parent}--disabled & {
      opacity: 0.3;
    }

    .#{$parent}--light & {
      color: $color-cerulean;

      .rebrand & {
        color: $color-dodger-blue;
      }
    }
  }

  &__options {
    left: -1px;
    width: calc(100% + 1px);
    margin: 1px 0 0;
    border: 1px solid rgba($color-hit-gray, 0.1);
    border-top: 1px solid rgba($color-hit-gray, 0.35);
    border-radius: 0 0 3px 3px;
    box-shadow: 0 7px 14px rgba($color-black, 0.15);
    z-index: 5;
  }
}
