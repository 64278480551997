@import "styles/components/articles";

$parent: editor-preview;

.#{$parent} {
  @include article-container;
  padding: 128px 40px 40px;
  min-height: 44vh;
  background-color: $color-white;
  margin: 0 auto;

  &__block {
    + .#{$parent}__block {
      margin-top: 25px;
    }
  }

  h4 {
    @include article-subtitle;
  }

  h5 {
    @include article-header-generic(24px, 32px);
  }

  h6 {
    @include article-header-generic(20px, 28px);
  }

  ol {
    @include article-ol;
  }

  ul {
    @include article-ul;
  }

  &--rtl {
    @include article-direction-rtl;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  strike {
    text-decoration: line-through;
  }

  a {
    color: $color-cerulean;
    text-decoration: underline;

    .rebrand & {
      color: $color-dodger-blue;
    }
  }
}
