@import "styles/utilities/typography";
@import "styles/base/colors";

$parent: "schedules";

.#{$parent} {
  @include type-label;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  width: 125px;
  height: 100%;
  text-align: center;
  padding-top: 2px;
  color: $color-white;

  &--standard {
    background-color: $color-porcelain;
    color: $color-regent-gray;
  }

  &--warning {
    background-color: $color-crimson;
  }

  &--upcoming {
    background-color: $color-sunshade;

    .rebrand & {
      background-color: $color-blistering-mars;
    }
  }

  &--warning-upcoming {
    background: linear-gradient(
      -60deg,
      $color-sunshade 50%,
      $color-crimson 50%
    );

    .rebrand & {
      background: linear-gradient(
        -60deg,
        $color-blistering-mars 50%,
        $color-crimson 50%
      );
    }
  }
}
