@import "../upload-progress-modal/upload-progress-modal";

.ovp-asset__container {
  display: flex;
  flex-direction: column;
  background-color: $color-zircon;
}

.ovp-asset__status {
  display: flex;
  align-items: center;
  height: 44px;
  padding-left: 16px;
  background-color: white;
  border-top: 1px solid $color-hit-gray-alpha;

  &__icon {
    display: flex;
    width: 18px;
    height: 18px;
  }

  &__title {
    font-size: 14px;
    color: $color-hit-gray;

    margin-left: 15px;
  }
}

.ovp-asset__frame {
  margin: 40px auto 44px;
  width: 50%;

  &__title {
    @include type-note-primary;
    display: flex;
    padding: 14px 11px;
    font-size: 14px;
    background-color: $color-white;

    &__icon {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }
  }

  &__container {
    position: relative;
  }

  &__placeholder {
    padding-bottom: 56.25%; //16 to 9 resolution
    background-color: $color-shuttle-grey;
  }

  &__progress {
    display: flex;
    align-self: center;
    text-align: center;
    flex-direction: column;
    width: 80%;
  }

  &__progress-text {
    text-transform: uppercase;

    .rebrand & {
      text-transform: none;
    }
  }

  &__progress-spinner {
    @include spinner-pulse($size: 10px, $keyframe: pulse-inline);
    position: relative;
    align-self: center;
    width: 20px;
    height: 10px;
    margin-bottom: 15px;

    .rebrand & {
      @include spinner-pulse($size: 10px, $keyframe: pulse-inline-rebrand);
    }

    &:before,
    &:after {
      position: absolute;
    }

    &:before {
      right: 0;
    }

    &:after {
      left: 0;
    }
  }

  &__error {
    align-self: center;
    text-align: center;
  }

  &__error-icon {
    fill: $color-white;
    margin-bottom: 15px;
    width: 20px;
    height: 20px;
  }

  &__error-text {
    text-transform: uppercase;
    margin-bottom: 25px;

    .rebrand & {
      text-transform: none;
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    color: $color-white;
  }

  &__player {
    width: 100%;
  }

  &__play,
  &__pause {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    transform: translate(-50%, -50%);
    background-color: $color-summer-sky;
    cursor: pointer;

    .rebrand & {
      background-color: $color-dodger-blue-light;
    }

    &-icon {
      display: flex;
    }
  }

  &__play {
    transition: all 0.1s ease-out;

    &--video {
      border-radius: 2px;

      .ovp-asset__frame__play-icon {
        width: 18px;
      }
    }

    &--audio {
      border-radius: 50%;

      .ovp-asset__frame__play-icon {
        width: 15px;
        margin-left: 2px;
      }

      &:hover {
        width: 58px;
        height: 58px;
      }
    }
  }

  &__pause {
    &-icon {
      font-family: VideoJS;
      font-weight: normal;
      font-style: normal;
      font-size: 25px;
      display: none;

      &:before {
        content: "\f103";
      }
    }
  }

  &__no-preview {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: $color-shuttle-grey;
    z-index: 2;

    &-icon {
      display: flex;
      width: 48px;
      height: 48px;
      opacity: 0.5;
    }

    &-title {
      @include type-note-primary;
      color: rgba($color-white, 0.5);
      font-style: italic;
      margin-top: 16px;
      margin-bottom: 7px;
      text-transform: uppercase;

      .rebrand & {
        text-transform: none;
      }
    }

    &-message {
      @include type-note-primary;
      margin-bottom: 24px;
      text-align: center;
      padding: 0 30px;
    }
  }

  &--playing {
    &:before,
    &:after {
      border-radius: 50%;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
    }

    &:before {
      animation: pulse-two 2.2s infinite ease-in-out;

      .rebrand & {
        animation: pulse-two-rebrand 2.2s infinite ease-in-out;
      }
    }

    &:after {
      animation: pulse-one 1.5s infinite ease-in-out;

      .rebrand & {
        animation: pulse-one-rebrand 1.5s infinite ease-in-out;
      }
    }

    .ovp-asset__frame__play-icon {
      display: none;
    }

    .ovp-asset__frame__pause-icon {
      display: flex;
    }
  }

  .linear-progress-indicator {
    margin-bottom: 20px;
  }
}

.video-js {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto !important;
  background-color: transparent !important;

  video {
    outline: none;
  }

  .vjs-big-play-button {
    display: none !important;
  }

  .vjs-progress-holder {
    height: 0.5em !important;
  }

  .vjs-play-progress,
  .vjs-load-progress,
  .vjs-load-progress div {
    border-radius: 25px;
  }

  .vjs-slider,
  .vjs-load-progress div {
    background-color: transparent !important;
  }

  .vjs-load-progress {
    background-color: $color-white-alpha !important;
  }

  .vjs-play-progress {
    background-color: $color-summer-sky !important;

    .rebrand & {
      background-color: $color-dodger-blue-light !important;
    }

    &::before {
      top: -0.21111em !important;
    }
  }
}

.vjs-fullscreen {
  &.vjs-ended {
    .vjs-control-bar {
      opacity: 1 !important;
    }
  }
}

.vjs-control-bar {
  bottom: 0px !important;
  background: linear-gradient(
    -180deg,
    transparent,
    $color-outer-space
  ) !important;
  height: 4em !important;
  padding-bottom: 10px;
  transition: opacity 0.3s ease-in-out !important;
  opacity: 0 !important;

  .rebrand & {
    background: linear-gradient(
      -180deg,
      transparent,
      $color-black-pearl
    ) !important;
  }

  button,
  div {
    outline: none;
  }
}

.vjs-playing {
  + .ovp-asset__frame__play--video {
    display: none;
  }
}

.vjs-has-started:not(.vjs-ended) {
  &:hover {
    .vjs-control-bar {
      opacity: 1 !important;
    }
  }

  + .ovp-asset__frame__play--video {
    display: none;
  }
}

.ovp-asset__upload-divider {
  @include type-note-primary;
  display: flex;
  flex: 0 1 auto;
  text-transform: uppercase;
  font-style: italic;
  color: $color-regent-gray;
  opacity: 0.25;

  .rebrand & {
    text-transform: none;
  }

  &:before,
  &:after {
    content: "";
    display: flex;
    flex: 1 0 auto;
    align-self: center;
    height: 1px;
    background-color: $color-hit-gray;
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
}

.ovp-asset__button-block {
  border-top: 1px solid $color-iron;
  display: flex;
  align-items: center;
  margin: 0 20px;

  .rebrand & {
    border-top-color: $color-solitude;
  }

  .button-small {
    margin: 15px 8px 18px 0;
  }

  .button-small:not(:last-of-type) {
    margin-left: 0;
  }

  &__paragraph {
    @include type-body-primary;
    margin-left: 23px;
    vertical-align: middle;
  }

  button {
    display: flex;
    align-items: center;
  }

  &__upload-video {
    display: flex;
    justify-content: flex-end;
  }
}

.ovp-asset__error-block {
  background-color: rgba($color-crimson, 0.4);
  padding: 16px 20px;
  font-size: 12px;
}

@keyframes pulse-one {
  0%,
  100% {
    box-shadow: 0 0 0 1em rgba($color-summer-sky, 0.4);
  }
  25% {
    box-shadow: 0 0 0 0.15em rgba($color-summer-sky, 0.15);
  }
  50% {
    box-shadow: 0 0 0 0.55em rgba($color-summer-sky, 0.55);
  }
  75% {
    box-shadow: 0 0 0 0.25em rgba($color-summer-sky, 0.25);
  }
}

@keyframes pulse-two {
  0%,
  100% {
    box-shadow: 0 0 0 0.6em rgba($color-summer-sky, 0.15);
  }
  25% {
    box-shadow: 0 0 0 1.2em rgba($color-summer-sky, 0.3);
  }
  50% {
    box-shadow: 0 0 0 0.4em rgba($color-summer-sky, 0.05);
  }
  75% {
    box-shadow: 0 0 0 0.55em rgba($color-summer-sky, 0.45);
  }
}

@keyframes pulse-one-rebrand {
  0%,
  100% {
    box-shadow: 0 0 0 1em rgba($color-dodger-blue-light, 0.4);
  }
  25% {
    box-shadow: 0 0 0 0.15em rgba($color-dodger-blue-light, 0.15);
  }
  50% {
    box-shadow: 0 0 0 0.55em rgba($color-dodger-blue-light, 0.55);
  }
  75% {
    box-shadow: 0 0 0 0.25em rgba($color-dodger-blue-light, 0.25);
  }
}

@keyframes pulse-two-rebrand {
  0%,
  100% {
    box-shadow: 0 0 0 0.6em rgba($color-dodger-blue-light, 0.15);
  }
  25% {
    box-shadow: 0 0 0 1.2em rgba($color-dodger-blue-light, 0.3);
  }
  50% {
    box-shadow: 0 0 0 0.4em rgba($color-dodger-blue-light, 0.05);
  }
  75% {
    box-shadow: 0 0 0 0.55em rgba($color-dodger-blue-light, 0.45);
  }
}
