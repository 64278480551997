@mixin font-modal-primary-heading {
  @include font-secondary;
  font-size: 22px;
  line-height: 22px;

  .rebrand & {
    @include font-primary-rebrand;
    font-size: 22px;
    line-height: 22px;
  }
}

@mixin modal-primary-title {
  padding: 30px;
}

.modal {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  width: 100%;
  min-width: 320px;
  max-width: 1440px;
  transform: translate(-50%, -50%);
  -webkit-overflow-scrolling: touch;

  @include xxl-up {
    padding-left: $navigation-width;
  }

  // angularJS $animate classes
  &--slide-up.ng-enter {
    opacity: 0;
    transform: translate(-50%, 100%);
    transition: all ease-in 0.5s;

    &.ng-enter-active {
      opacity: 1;
      transform: translate(-50%, -50%);
      transition: all ease-in 0.5s;
    }
  }

  &--slide-up.ng-enter-prepare {
    opacity: 0;
  }

  &.show {
    visibility: visible;
  }

  &__outer-container {
    opacity: 1;
    transition: opacity linear 0.3s;

    &.ng-hide {
      opacity: 0;
    }
  }

  &__content {
    margin: $margin-small;
  }

  &__content-inner {
    background: $color-white;
    border-radius: 2px;
  }

  &__backdrop {
    display: none;

    &.active {
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 100;
      top: 0;
      left: 0;
    }

    @include lg-down {
      padding: 45px 8px 0;
    }

    @include lg-up {
      padding: 45px $margin-small 0;
    }
  }

  &__layer {
    @extend .modal__backdrop;
    background: transparentize($color-outer-space, 0.1);
    opacity: 0;
    transition: opacity 0.5s ease;

    .rebrand & {
      background: transparentize($color-black-pearl, 0.1);
    }

    &.fade {
      opacity: 1;
    }
  }

  &__disabled-tab {
    padding: 50px;
  }

  &__disabled-text {
    text-align: center;
    font-style: italic;
    color: $color-regent-gray;
  }
}
