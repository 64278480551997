@import "styles/base/layout-config";
// 321px
@mixin sm-up {
  @media (min-width: $sm-min) {
    @content;
  }
}

// 601px
@mixin ml-up {
  @media (min-width: $ml-min) {
    @content;
  }
}

// 771px
@mixin lg-up {
  @media (min-width: $lg-min) {
    @content;
  }
}

// 981px
@mixin xl-up {
  @media (min-width: $xl-min) {
    @content;
  }
}

// 1201px
@mixin xxl-up {
  @media (min-width: $xxl-min) {
    @content;
  }
}

// 1801px
@mixin xxxl-up {
  @media (min-width: $xxxl-min) {
    @content;
  }
}

// 320px
@mixin sm-down {
  @media (max-width: $xs-max) {
    @content;
  }
}

// 600px
@mixin ml-down {
  @media (max-width: $sm-max) {
    @content;
  }
}

// 770px
@mixin lg-down {
  @media (max-width: $ml-max) {
    @content;
  }
}

// 980px
@mixin xl-down {
  @media (max-width: $lg-max) {
    @content;
  }
}

// 1200px
@mixin xxl-down {
  @media (max-width: $xl-max) {
    @content;
  }
}

// height based
@mixin ml-h-up {
  @media (max-height: $ml-max) {
    @content;
  }
}

// Config layout classes
@for $i from 1 through 12 {
  .columns-#{$i} {
    width: span($i of 12 inside no-gutters);
  }
}
