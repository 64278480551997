@import "styles/base/colors";
@import "styles/utilities/typography";
@import "styles/components/dropdown";

$parent: dropdown-list;

.#{$parent} {
  @include type-body-primary;
  @include dropdown-wrapper;
  padding: 13px 16px 11px;
  border-color: $color-porcelain;
  min-width: 170px;

  &:hover {
    border-color: $color-iron;
    border-radius: inherit 0;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
      border-color: $color-solitude;
    }
  }

  &__text {
    @include dropdown-text;
  }

  &__options {
    @include dropdown-options;
    margin: 0 0 0 -2px;
    padding: 14px 0;
    z-index: 100;

    &--visible {
      border-top: 1px solid $color-iron;

      .rebrand & {
        border-top-color: $color-solitude;
      }
    }
  }

  &__options-text {
    color: $color-hit-gray;
  }

  &__options-item {
    @include type-subheading;
    position: relative;
    display: block;
    padding: 15px 20px;
    outline: 0;
    height: 48px;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: rgba($color-anakiwa, 0.2);
      text-decoration: none;

      .rebrand & {
        background-color: rgba($color-alice-blue, 0.2);
      }

      .#{$parent}__options-text {
        color: $color-cerulean;

        .rebrand & {
          color: $color-dodger-blue;
        }
      }
    }

    &--inactive {
      cursor: default;
      user-select: none;

      &,
      &:hover,
      &:focus {
        background-color: transparent;
      }

      > .#{$parent}__options-text,
      &:hover > .#{$parent}__options-text,
      &:focus > .#{$parent}__options-text {
        color: $color-outer-space;

        .rebrand & {
          color: $color-black-pearl;
        }
      }
    }
  }
}
