// Consistent displaying of scheduled item data
// in modals. So, images, schedules things like that.
.collection-list {
  float: left;
  width: calc(100% + 48px);
  box-shadow: inset 0 1px 0 0 rgba($color-porcelain, 1);
  margin-left: -24px;

  @include ml-down {
    width: 100%;
  }

  &__inner {
    padding: 0 24px;
    float: left;
    width: 100%;
    max-height: 56px;
  }

  &__head {
    float: left;
    width: 100%;

    &--title {
      @include span(4 of 12 inside no-gutters);
      @include type-body-primary;
      display: block;
      padding: 12px 0 8px;
      color: $color-regent-gray;
    }
  }

  &__field {
    @include type-body-primary;
    line-height: 19px;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }

    &--article {
      @include span(4 of 12 inside no-gutters);
      min-width: 124px;
      padding: 15px 0;
    }

    &--image {
      @include span(4 of 12 inside no-gutters);
      min-width: 124px;
    }

    &--title {
      @include span(4 of 12 inside no-gutters);
      min-width: 124px;
      padding: 18px 0;
    }

    &--schedule {
      @include span(6 of 12 inside no-gutters);
      margin-top: 20px;

      &-wrapper {
        margin-top: 29px;
      }
    }

    &--actions {
      float: right;
      margin: 5px auto 0;
      text-align: right;

      > div {
        cursor: pointer;
        float: left;
        display: block;
        padding: 12px 0 12px 24px;
      }
    }

    &--icon {
      width: 24px;
      height: 24px;
      fill: $color-hit-gray;

      &-pencil {
        &:hover {
          fill: $color-cerulean;

          .rebrand & {
            fill: $color-dodger-blue;
          }
        }
      }

      &-close {
        &:hover {
          fill: $color-crimson;
        }
      }
    }
  }

  &__title {
    @include type-body-primary;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }
}
