@mixin input-defaults {
  @include type-body-primary;
  padding: 13px 16px 11px;
  color: $color-regent-gray;
  background-color: $color-porcelain;

  .rebrand & {
    color: $color-black-pearl;
    background-color: $color-alabaster;
  }
}

@mixin input-drawer {
  @include type-body-primary;
  padding: 8px 12px;
  color: $color-outer-space;
  background-color: $color-athens-gray;
  border: 2px solid $color-athens-gray;
  border-radius: 4px;
  box-shadow: none;

  .rebrand & {
    color: $color-black-pearl;
  }

  &:focus {
    background-color: $color-white;
  }
}

input {
  &::-webkit-input-placeholder {
    color: $color-regent-gray;
    text-transform: capitalize;
  }

  &:focus::-webkit-input-placeholder,
  &:hover::-webkit-input-placeholder {
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &::placeholder {
    color: $color-regent-gray;
    text-transform: capitalize;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &:-ms-input-placeholder {
    color: $color-regent-gray;
    text-transform: capitalize;
  }

  &:hover:-ms-input-placeholder,
  &:focus:-ms-input-placeholder {
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &:focus {
    background-color: $color-white;
    outline: none;
  }
}
