.loading {
  &--overlay {
    position: fixed;
    bottom: 0;
    z-index: map-get($z-index, loading-overlay);
    width: 100%;
    height: 100%;
    text-align: center;

    .loading__container {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @include lg-up {
        width: calc(100% - #{$navigation-width});
      }
    }

    .loading__pulse {
      &--left {
        animation: pulse 0.7s ease-in-out infinite alternate;
      }

      &--right {
        margin-left: 24px;
        animation: pulse 0.7s ease-in-out infinite alternate-reverse;
      }
    }
  }

  &--no-overlay {
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: $color-porcelain;
    opacity: 1;

    .loading__container {
      position: fixed;
      top: calc(50% + (184px / 2));
      z-index: 10;
      text-align: center;

      @include lg-up {
        width: calc(100% - #{$navigation-width});
      }
    }

    .loading__pulse {
      &--left {
        animation: pulse 0.7s ease-in-out infinite alternate;
      }

      &--right {
        margin-left: 24px;
        animation: pulse 0.7s ease-in-out infinite alternate-reverse;
      }
    }
  }

  &--inline {
    width: 100%;
    padding: 28px 0;
    text-align: center;

    .loading__container {
      padding: 38px 0;
      margin-left: auto;
    }

    .loading__pulse {
      &--left {
        animation: pulse-inline 0.7s ease-in-out infinite alternate;

        .rebrand & {
          animation: pulse-inline-rebrand 0.7s ease-in-out infinite alternate;
        }
      }

      &--right {
        margin-left: 18px;
        animation: pulse-inline 0.7s ease-in-out infinite alternate-reverse;

        .rebrand & {
          animation: pulse-inline-rebrand 0.7s ease-in-out infinite
            alternate-reverse;
        }
      }
    }
  }

  &--inline-small {
    width: 16px;
    text-align: center;

    .loading__pulse {
      width: 6px;
      height: 6px;
    }
  }

  &--inline-dark {
    .loading__pulse {
      &--left {
        animation: pulse-inline-dark 0.7s ease-in-out infinite alternate;

        .rebrand & {
          animation: pulse-inline-dark-rebrand 0.7s ease-in-out infinite
            alternate;
        }
      }

      &--right {
        margin-left: 0;
        animation: pulse-inline-dark 0.7s ease-in-out infinite alternate-reverse;

        .rebrand & {
          animation: pulse-inline-dark-rebrand 0.7s ease-in-out infinite
            alternate-reverse;
        }
      }
    }
  }

  &__container {
    display: inline-block;
    width: 100%;
  }

  &__pulse {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 100%;
  }

  &__text {
    @include type-body-primary;
    position: fixed;
    bottom: 48px;
    width: 100%;
    color: $color-regent-gray;
    text-align: center;

    @include lg-up {
      width: calc(100% - #{$navigation-width});
    }
  }
}
