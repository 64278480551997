.date-range {
  @include clearfix;
  padding-right: 23px;
  margin-bottom: 12px;
  border-bottom: 1px solid $color-hit-gray;

  &__today {
    @include type-note-secondary;
    float: left;
    margin-left: 5px;
    text-transform: uppercase;

    &--active {
      color: $color-white;
      background-color: $color-outer-space;

      .rebrand & {
        background-color: $color-black-pearl;
      }
    }
  }

  &__current-date-container {
    @include clearfix;
    float: left;
    padding: 0 23px 12px;
    border-bottom: 3px solid $color-cerulean;

    .rebrand & {
      border-color: $color-dodger-blue;
    }

    &:hover {
      cursor: pointer;

      .date-range__current-date-text {
        color: $color-cerulean;

        .rebrand & {
          color: $color-dodger-blue;
        }
      }

      .date-range__icon {
        fill: $color-cerulean;

        .rebrand & {
          color: $color-dodger-blue;
        }
      }
    }
  }

  &__current-date-text {
    @include type-title;
    float: left;
    margin-right: 12px;

    &--active {
      color: $color-cerulean;

      .rebrand & {
        color: $color-dodger-blue;
      }
    }
  }

  &__icon {
    @include icon-size(28px);
    float: left;
    fill: $color-regent-gray;

    &--active {
      fill: $color-cerulean;

      .rebrand & {
        fill: $color-dodger-blue;
      }
    }
  }

  &__enable-range {
    @include type-body-primary;
    position: absolute;
    top: 50%;
    right: 0;
    float: right;
    width: 50%;
    color: $color-cerulean;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    transform: translate(0, -50%);

    .rebrand & {
      color: $color-dodger-blue;
    }
  }

  &__controls {
    float: right;
  }

  &__filter-container {
    @include clearfix;
    position: absolute;
    z-index: 50;
    width: 232px;
    margin-top: 66px;
    cursor: auto;
    background-color: $color-white;
    box-shadow: 0 2px 6px 0 rgba($color-outer-space, 0.2);

    .rebrand & {
      box-shadow: 0 2px 6px 0 rgba($color-black-pearl, 0.2);
    }

    &.date-range--range-filtering-available {
      width: 464px;
    }
  }
}
