.linear-progress-indicator {
  $pattern-size: 50px;

  @mixin gradient-striped($color1, $color2, $angle: 45deg) {
    background-image: linear-gradient(
      $angle,
      $color1 50%,
      // pattern start is same color as end
      $color2 73%,
      // pattern, currently just one colour, but anything could go between 50% and 96%
      $color1 96%,
      // pattern stop stop, ready to repeat
      $color1
    );
  }

  @keyframes shiftingProgress {
    from {
      background-position: $pattern-size 0;
    }
    to {
      background-position: 0 0;
    }
  }

  position: relative;
  width: 100%;
  height: 4px;
  background-color: rgba($color-outer-space, 0.1);
  border-radius: 4px;
  overflow: hidden;

  .rebrand & {
    background-color: rgba($color-black-pearl, 0.1);
  }

  &__progress-bar {
    @include gradient-striped($color-summer-sky, $color-cerulean, -45deg);
    width: 100%;
    height: 100%;
    background-size: $pattern-size $pattern-size;
    animation: shiftingProgress 1s infinite linear;

    .rebrand & {
      @include gradient-striped(
        $color-dodger-blue-light,
        $color-dodger-blue,
        -45deg
      );
    }

    &--error {
      background-color: $color-crimson;
      background-image: none;
      animation: none;
    }

    &--stop {
      animation: none;
    }
  }
}
