@import "styles/base/colors";

$parent: modal-wrapper;

.#{$parent} {
  transition: opacity 0.2s ease-in-out, z-index 0s;
  opacity: 0;

  &__entered {
    opacity: 1;
  }

  &__enter {
    opacity: 0.1;
    transition-delay: 0.2s;
  }

  &__enter-active {
    opacity: 1;
  }

  &__exit {
    opacity: 0.01;
  }

  &__exit-active {
    opacity: 0.01;
    transition-delay: 0.1s;
  }
}
