.search-marker {
  &__container {
    display: inline-block;
    max-width: $navigation-width;
    padding: 6px 0 6px 12px;
    margin: 5px 8px;
    line-height: 19px;
    color: $color-white;
    background-color: $color-outer-space;
    border-radius: 2px;

    .rebrand & {
      background-color: $color-black-pearl;
    }

    &:hover {
      .search-marker__close {
        color: $color-white;
      }
    }
  }

  &__title {
    @include type-note-primary;
    position: relative;
    top: 5px;
    display: inline-block;
    max-width: 115px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__type {
    @include type-note-primary;
    color: $color-hit-gray;
  }

  &__operator {
    @include type-body-primary;
    position: absolute;
    top: 50%;
    margin-right: 6px;
    color: $color-hit-gray;
    transform: translate(0, -50%);

    &--split {
      position: relative;
      top: auto;
    }
  }

  &__close {
    @include icon-size(24px);
    padding: 4px;
    margin: 0 4px;
    vertical-align: middle;
    cursor: pointer;
    fill: $color-regent-gray;

    &:hover {
      fill: $color-white;
    }
  }
}
