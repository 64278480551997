.content-breadcrumb {
  float: left;
  padding: 0;
  margin: 0 0 20px;
  border: 0;

  @include lg-up {
    padding: 0 16px 0 0;
    margin: 0;
  }

  &--no-border {
    border-right: 0;
  }

  &__item {
    position: relative;
    display: inline-block;
    padding: 4px 11px 2px;
    margin-right: 8px;
    margin-bottom: 8px;
    line-height: 16px;
    background-color: $color-regent-gray;
    border-radius: 20px;

    .rebrand & {
      padding: 4px 11px;
    }

    @include lg-up {
      margin-bottom: 4px;
    }

    &:hover {
      cursor: pointer;
      background-color: $color-cerulean;

      .rebrand & {
        background-color: $color-dodger-blue;
      }
    }

    &--active {
      background-color: $color-outer-space;

      .rebrand & {
        background-color: $color-black-pearl;
      }
    }
  }

  &__link {
    @include type-body-secondary;
    position: relative;
    top: 2px;
    display: inline-block;
    max-width: 150px;
    overflow: hidden;
    color: $color-white;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
