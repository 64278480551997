@import "styles/utilities/typography";
@import "styles/base/colors";
@import "styles/utilities/mixins";

.image-column {
  position: relative;
  width: 100%;
  max-width: 100px;
  margin: 12px 0;

  &::before {
    background-color: $color-outer-space;
    content: "";
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    .rebrand & {
      background-color: $color-black-pearl;
    }
  }

  &--no-image {
    &::before {
      @include image-placeholder($color-iron);

      .rebrand & {
        @include image-placeholder-rebrand($color-solitude);
      }
    }
  }

  &--is-faded {
    opacity: 0.3;
  }

  img {
    @include unselectable;
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 100%;
    transform: translate(-50%, -50%);
  }
}
