.file-picker {
  @include type-body-primary;
  position: relative;
  display: block;
  max-height: 48px;
  min-width: 170px;
  padding: 13px 16px 11px;
  color: $color-regent-gray;
  cursor: pointer;
  background: $color-porcelain;
  border: 2px solid $color-porcelain;
  border-radius: 2px;

  @include lg-up {
    max-width: 288px;
  }

  &:hover {
    color: $color-outer-space;
    background: $color-white;
    border-color: $color-iron;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .rebrand & {
      color: $color-black-pearl;
      border-color: $color-solitude;
    }

    .file-picker__icon-container {
      background-color: $color-outer-space;

      .rebrand & {
        background-color: $color-black-pearl;
      }
    }

    .file-picker__text {
      color: $color-outer-space;

      .rebrand & {
        color: $color-black-pearl;
      }
    }
  }

  &__icon-container {
    position: absolute;
    top: 50%;
    right: 6px;
    width: 34px;
    height: 34px;
    pointer-events: none;
    background-color: $color-hit-gray;
    border-radius: 2px;
    transform: translateY(-50%);

    &:hover {
      background-color: $color-outer-space;

      .rebrand & {
        background-color: $color-black-pearl;
      }
    }
  }

  &__icon {
    @include icon-size(24px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: $color-white;

    &--language {
      right: 22px;
      left: auto;
      display: none;
      transform: translate(0, -50%);
      fill: $color-hit-gray;

      @include ml-up {
        display: block;
      }
    }
  }

  &__text {
    display: inline-block;
    width: 100%;
    padding-right: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__link {
    @include type-body-primary;
    display: inline-block;
    margin-top: 10px;
    color: $color-cerulean;
    text-decoration: underline;

    .rebrand & {
      color: $color-dodger-blue;
    }
  }
}
