.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-index, modal);
  width: 100%;
  height: 100%;

  @include xxl-up {
    left: $navigation-width;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba($color-outer-space, 0.9);
    opacity: 0;

    .rebrand & {
      color: rgba($color-black-pearl, 0.9);
    }

    @include lg-up {
      left: $navigation-width;
    }

    .fixed & {
      z-index: 90;
      opacity: 1;
      transition: opacity 0.3s ease-out, z-index 0s;
    }
  }

  // angularJS $animate classes
  &.ng-enter {
    opacity: 0;
    transition: all ease-in 0.3s;

    &.ng-enter-active {
      opacity: 1;
      transition: all ease-in 0.3s;
    }
  }

  &.ng-enter-prepare {
    opacity: 0;
  }

  &.ng-leave {
    opacity: 1;
    transition: all ease-out 0.1s;

    &.ng-leave-active {
      opacity: 0;
      transition: all ease-out 0.1s;
    }
  }
}
