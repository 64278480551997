.video-upload-url {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 65%;
  margin: 40px auto;

  .form {
    &__text-input-container {
      flex: 1 0 0;
      max-width: 100%;
      margin-right: 8px;
    }

    // first time of a new input style,
    // this is not made as a component as there is only one use case for it
    &__field {
      max-width: 100%;
      height: 32px;
      padding: 8px 6px 8px 12px;
      background: white;
      border: 1px solid white;

      &:focus {
        border-color: $color-cerulean;
        box-shadow: 0px 3px 8px -2px $color-black-alpha;

        .rebrand & {
          border-color: $color-dodger-blue;
        }
      }
    }
  }
}
