@import "styles/base/colors";
@import "styles/base/z-index";
@import "styles/base/variables";

.modal-overlay {
  content: "";
  position: fixed;
  z-index: map-get($z-index, modal-overlay);
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-outer-space, 0.9);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .rebrand & {
    background-color: rgba($color-black-pearl, 0.9);
  }

  &__enter {
    opacity: 0.01;
  }

  &__enter-active {
    opacity: 1;
  }

  &__entered {
    opacity: 1;
  }

  &__exit {
    opacity: 1;
  }

  &__exit-active {
    opacity: 0.01;
  }
}
