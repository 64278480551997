.search-results {
  --loader-size: 8px;
  --loader-margin: 2px;

  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 rgba(black, 0.1), 0 6px 16px -6px rgba(black, 0.3);

  grid-area: searchResults;

  max-height: 276px;
  margin-top: 8px;

  overflow-x: hidden;
  overflow-y: auto;

  padding: 20px 0 15px;

  &__count,
  &__message {
    margin-left: 20px;
    margin-bottom: 14px;

    p {
      color: $color-hit-gray;
      font-size: 13px;
    }
  }

  &__result {
    &:hover {
      background-color: rgba($color-anakiwa, 0.1);

      .rebrand & {
        background-color: rgba($color-alice-blue, 0.1);
      }
    }

    a {
      color: $color-outer-space;
      display: block;

      padding: 10px 20px;

      .rebrand & {
        color: $color-black-pearl;
      }

      &:visited {
        color: $color-outer-space;

        .rebrand & {
          color: $color-black-pearl;
        }
      }
    }
  }
}
