@import "styles/base/colors";
@import "styles/base/variables";
@import "styles/base/z-index";

$parent: editor-container;

.#{$parent} {
  position: relative;
  padding: 0 32px 32px;
  width: 100%;
  min-height: 63vh;

  &--preview {
    margin: 0 auto;
    max-width: 847px;
  }

  &__header {
    position: absolute;
    padding: 20px 0;
    width: calc(100% - 64px);
    max-width: 848px;
    background: $color-porcelain;
    text-align: right;

    &--fixed {
      position: fixed;
      top: 0;
      width: calc(100% - #{$navigation-width + 64px});
      transform: translateZ(0);
    }
  }
}
