// .main-content delineates the top-level section
// with left-padding to accomodate a fixed width menu
// to the left and fluid content within
.main-content {
  @include clearfix;
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;

  @include lg-up {
    min-height: 100vh;
    left: $navigation-width;
    width: calc(100% - #{$navigation-width});
  }

  &::after {
    position: fixed;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0;
  }

  .nav-open &,
  .local-nav-open & {
    &::after {
      position: fixed;
      top: 0;
      z-index: 95;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: rgba($color-outer-space, 0.9);
      content: "";
      opacity: 1;

      .rebrand & {
        background: rgba($color-black-pearl, 0.9);
      }
    }
  }

  .nav-open & {
    &::after {
      @include lg-up {
        display: none;
      }
    }
  }

  .local-nav-open & {
    &::after {
      z-index: 11;
    }
  }

  .overlay & {
    &::after {
      position: fixed;
      top: 0;
      z-index: 30;
      width: 100%;
      height: 100%;
      background-color: $color-white;
      content: "";
      opacity: 1;
    }
  }
}

.loadstate {
  &::after {
    opacity: 1;
  }
}

.loadstate-remove {
  opacity: 0;
}

.loadstate-remove-active,
.tab-container.ng-hide {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

.tab-container.ng-hide-remove {
  opacity: 0;
  transition: all 0.2s ease-out;
}

.tab-container.ng-hide-remove-active {
  opacity: 1;
}

.container {
  @include lg-down {
    padding: 20px;
  }

  @include lg-up {
    padding: $margin-small;
  }

  &--module {
    padding: 24px;
  }

  &--extra-padding {
    padding: 62px;
  }

  &--wider-padding {
    padding: $margin-small 48px 40px;
  }

  &--module-padding {
    padding: 24px 24px 44px;
  }

  &--flat-bottom {
    padding-bottom: 0;
  }

  &--flat-top {
    padding-top: 0;
  }

  &--padded-top {
    padding-top: 40px;
  }
}

.page {
  &__section {
    @include clearfix;
    padding: 0 24px;
    margin-bottom: 40px;

    &--image-layout,
    &--document-layout {
      margin-top: 24px;
      margin-bottom: 48px;
    }

    &--faded {
      transition: opacity ease-in 0.2s;
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &__section--preview-mode {
    margin-bottom: 0;
  }

  &__section-header {
    margin-bottom: 24px;
    overflow: hidden;
  }

  &__section-header-meta {
    float: right;

    &--with-controls {
      bottom: 5px;
      padding-right: 58px;
    }
  }

  &__section-heading {
    @include type-title;
    @include ellipsis;
    position: relative;
    z-index: 5;
    float: left;
    color: $color-outer-space;
    text-transform: capitalize;

    .rebrand & {
      color: $color-outer-space;
    }

    &--with-controls {
      padding-top: 15px;
    }
  }

  &__divide-after {
    &::after {
      display: block;
      height: 1px;
      margin: 40px 0 30px;
      background-color: $color-iron;
      content: "";

      .rebrand & {
        background-color: $color-solitude;
      }
    }
  }

  &__divider {
    margin-bottom: 16px;
    border-bottom: 2px dashed $color-iron;

    .rebrand & {
      border-bottom-color: $color-solitude;
    }
  }

  &__divider-icon {
    @include icon-size(24px);
    position: relative;
    top: 6px;
    margin-right: 5px;
    fill: $color-regent-gray;
  }

  &__divider-block {
    position: relative;
    top: 10px;
    display: table;
    margin: 0 auto;
    color: $color-regent-gray;
    background: $color-porcelain;
  }

  &__notifier-block {
    display: block;
    text-align: center;
  }

  &__notifier-messaging {
    @include type-body-secondary;
    @include type-italic;
    display: inline-block;
    margin-right: 5px;
    color: $color-regent-gray;
    text-transform: uppercase;
  }
}

.set-content {
  &__staged {
    min-width: 725px;
    margin-top: 68px;
  }

  &__unstaged {
    min-width: 725px;
    margin-bottom: 68px;
  }

  &__staged--preview-mode {
    margin-top: 0;
  }

  &__unstaged--preview-mode {
    margin-bottom: 0;
  }

  &__divider {
    position: relative;
    bottom: 35px;
  }
}
