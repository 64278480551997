.dropdown-widget__container {
  border-radius: 2px;

  .dropdown-widget {
    &__control {
      @extend .dropdown;
      border-radius: 0;
      box-shadow: none;

      &--menu-is-open {
        border: 2px solid $color-iron;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        color: $color-outer-space;
        background: $color-white;

        .rebrand & {
          // Use !important here to ensure that these styles are active when the menu is open
          color: $color-black-pearl !important;
          background-color: $color-white !important;
          border-color: $color-solitude-dark !important;
          border-bottom: none !important;
        }

        .dropdown-widget__indicators {
          background-color: $color-outer-space;

          .rebrand & {
            background-color: $color-black-pearl;
          }
        }
      }
    }

    &__value-container {
      padding: 0;
    }

    &__single-value {
      position: relative;
      color: inherit;
      transform: none;
      margin: 0;
    }

    &__indicators {
      @extend .dropdown__arrow;
    }

    &__indicator {
      width: inherit;
      height: inherit;
    }

    &__menu {
      box-sizing: content-box;

      margin-top: 0;

      text-decoration: none;
      text-transform: uppercase;

      background: $color-white;

      border: 2px solid $color-iron;
      border-top: 0;
      border-radius: 0;

      box-shadow: none;
      width: calc(100% - 4px);

      .rebrand & {
        border-color: $color-solitude-dark;
        border-top: none;
      }
    }

    &__menu-list {
      max-height: 217px;
    }

    &__option {
      @extend .options__link;
      background: transparent;
      outline: 0;

      text-transform: none;

      &:hover,
      &--is-focused {
        text-decoration: underline;
      }

      &:active {
        background: transparent;
      }
    }

    &--dark {
      .dropdown-widget__control {
        background-color: $color-iron;
        border-color: $color-iron;

        .rebrand & {
          background-color: $color-alabaster;
          border-color: $color-solitude;
        }

        &:hover {
          background-color: $color-white;
          border-color: $color-white;

          .rebrand & {
            background-color: $color-white;
            border-color: $color-solitude;
          }
        }
      }
    }
  }
}
