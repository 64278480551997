$minute-height: 5px;

.calendar-grid {
  @include clearfix;
  width: 100%;

  &__header {
    display: flex;
    flex-flow: row;
    width: 100%;
  }

  &__header-items {
    @include type-note-primary;
    flex-grow: 1;
    min-width: 25%; // min width of each day is a quarter of the grid
    padding: 11px 24px;
    color: $color-regent-gray;

    &--is-current {
      padding: 3px;
      color: $color-white;
      background-color: $color-cerulean;
      border: 1px solid $color-cerulean;
      border-radius: 3px;

      .rebrand & {
        background-color: $color-dodger-blue;
        border: 1px solid $color-dodger-blue;
      }
    }
  }

  &__side-header,
  &__body {
    float: left;
  }

  &__side-header {
    position: absolute;
    z-index: 5;
    // width of the side-header relative to the container width
    width: 15%;
    background-color: transparent;

    @include xl-up {
      // width of the side-header relative to the container width
      width: 10%;
    }

    @include xxl-up {
      // width of the side-header relative to the container width
      width: 8%;
    }

    &--has-dates {
      margin-top: 38px;
    }
  }

  &__side-header-hours {
    @include clearfix;
    position: relative;
    height: $minute-height * 60;
  }

  &__side-header-hours-text {
    @include type-note-primary;
    position: absolute;
    // width of padding to accomodate larger resolutions and align hour markers
    padding-left: 25%;
    color: $color-regent-gray;
    transform: translate(0, -50%);

    @include xxl-down {
      padding-left: 0%;
    }

    &::after {
      position: absolute;
      top: 50%;
      // ensure hour markers go under the calendar
      left: 130%;
      width: 150%;
      height: 1px;
      background-color: $color-hit-gray;
      content: "";
    }
  }

  &__side-header-minutes {
    float: right;
  }

  &__side-header-minutes-item {
    width: 40px;

    &:first-of-type {
      border-top: 1px solid $color-hit-gray;
    }
  }

  &__body {
    position: relative;
    z-index: 10;
    width: 100%;
    margin-left: 8%;
    overflow-x: scroll;
    overflow-y: hidden;

    @include xl-up {
      // remainder to position next to side-header
      width: 90%;
      padding-left: 0%;
    }

    @include xxl-up {
      // remainder to position next to side-header
      width: 92%;
    }
  }

  &__contents {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__contents-column {
    position: relative;
    flex-grow: 1;
    // min width of each day is a quarter of the grid,
    // to allow for scrolling when displaying ranges larger than 4 days
    min-width: 25%;
    background-color: $color-iron;

    .rebrand & {
      background-color: $color-solitude;
    }
  }

  &__contents-column-hours {
    height: $minute-height * 60;
    border-collapse: collapse;
    border-top: 1px solid $color-hit-gray;
  }
}
