@import "styles/base/grid";
@import "styles/components/lists";

$parent: list-item;

.#{$parent} {
  @include list-item;

  &:focus {
    outline: none;
  }

  &--drawer {
    height: auto;
    min-height: 44px;
  }

  &--is-biggest {
    height: 88px;
  }

  &--is-selected {
    @include list-item-selected;
  }

  &--is-faded {
    @include list-item-faded;
  }

  &__columns {
    @include list-box-shadow;
    @include list-item-columns;

    &--modal {
      padding: 0 24px;
    }

    &--drawer {
      padding: 0 24px;
    }
  }

  &__column {
    @include list-column;
  }

  &__column-inner {
    @include list-cell;
  }
}
