.textarea-input {
  @include type-body-primary;
  height: 150px;
  max-width: 270px;
  padding: 13px 16px 11px;
  color: $color-regent-gray;
  background: $color-porcelain;
  border: 2px solid $color-porcelain;
  border-radius: 0;
  resize: vertical;
  appearance: none;

  @include ml-up {
    height: 180px;
    max-width: none;
  }

  &:focus,
  &:hover {
    color: $color-outer-space;
    background-color: $color-white;
    outline: none;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  .edited & {
    color: $color-shuttle-grey;
    background: none;
  }
}
