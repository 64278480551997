// Color name library: http://chir.ag/projects/name-that-color/

// Blues
$color-jelly-bean: #2785ab; // Has rebrand alternative
$color-lily-white: #e8f8ff;
$color-anakiwa: #8cdbfe; // Has rebrand alternative
$color-cerulean: #30a0ce; // Has rebrand alternative
$color-summer-sky: #2dc8ff; // Has rebrand alternative
$color-neon-blue: #335dff;

// Grays
$color-shuttle-grey: #4f5e6a;
$color-athens-gray: #f6f8fb; // Changed to rebrand colour
$color-gallery: #eaeaea;
$color-outer-space: #33393f; // Has rebrand alternative
$color-porcelain: #eef0f1;
$color-zircon: #e5e8ea;
$color-iron: #dde1e3; // Has rebrand alternative
$color-hit-gray: #aab5bb;
$color-regent-gray: #919fa6;
$color-oslo-gray: #7f8b92;
$color-nevada: #606e78;
$color-shark: #242526; // Has rebrand alternative
$color-cape-cod: #505151;

// Others
$color-black: #000000;
$color-white: #ffffff;
$color-corn: #ecc30b;
$color-sunshade: #ff9949; // Has rebrand alternative

// Reds
$color-lavender-blush: #fff1f3;
$color-tickle-pink: #ff748c;
$color-crimson: #e31337; // Matches rebrand colour
$color-shiraz: #bc0f2d;

// Greens
$color-narvik: #edf9f3;

// Alpha Colours
$color-anakiwa-alpha: mix(
  $color-anakiwa,
  $color-white,
  20
); // Has rebrand alternative
$color-black-alpha: rgba($color-black, 0.2);
$color-white-alpha: rgba($color-white, 0.4);
$color-hit-gray-alpha: mix($color-hit-gray, $color-white, 25);

// Rebrand colours
$color-east-bay: #4e5868; // Replaces shark
$color-dodger-blue: #226dff; // Replaces cerulean
$color-dodger-blue-light: #3994ff; // Replaces summer-sky
$color-denim: #1b57cc; // Replaces jelly-bean
$color-alice-blue: #e2f2ff; // Replaces anakiwa
$color-black-pearl: #0e1825; // Replaces outer-space
$color-solitude: #f1f3f7; // Replaces iron
$color-solitude-dark: #e6e9ef;
$color-blistering-mars: #ff6c51; // Replaces sunshade, corn
$color-alabaster: #f7f7f8;
$color-chateau-gray: #969ba4;
$color-alice-blue-alpha: mix(
  $color-alice-blue,
  $color-white,
  20
); // Replaces anakiwa-alpha
