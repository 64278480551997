.tag {
  @include type-note-secondary;
  min-width: $margin-small;
  min-height: $margin-small;
  background: $color-crimson;
  padding: 8px 12px 5px;
  border-radius: 16px;
  color: $color-white;
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: middle;

  &--applied {
    background: $color-porcelain;
    border-color: $color-porcelain;
  }

  &--live {
    background: $color-outer-space;
    border-color: $color-outer-space;

    .rebrand & {
      background: $color-black-pearl;
      border-color: $color-black-pearl;
    }
  }

  &--disabled {
    opacity: 0.4;
  }

  &--number {
    @include type-body-secondary;

    @include ml-down {
      @include type-note-secondary;
    }
  }
}
