.validation {
  float: left;
  height: 48px;
  max-width: 75px;
  margin-left: 16px;

  &__message {
    @include type-note-primary;
    @include type-italic;
    @include vertical-align;
    display: block;

    &--error {
      color: $color-crimson;
    }
  }

  &__row {
    position: absolute;
    top: 12px;
    margin-left: 7px;
    display: inline-block;
    white-space: nowrap;

    &__message {
      @include type-note-primary;
      @include type-italic;
      position: relative;
      top: -7px;
    }
  }

  &-icon {
    width: 17px;
    height: 23px;

    &--error {
      fill: $color-crimson;
    }

    &--success {
      fill: $color-cerulean;

      .rebrand & {
        fill: $color-dodger-blue;
      }
    }
  }
}
