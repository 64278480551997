.history-breadcrumbs {
  float: left;
  max-height: 33px;
  padding-top: 7px;

  &__item-container {
    display: block;
    float: left;
    min-height: 24px;
  }

  &__icon {
    @include icon-size(16px);
    position: relative;
    top: -2px;
    fill: $color-regent-gray;
  }

  &__item,
  &__dropdown-item {
    overflow: hidden;
    color: $color-regent-gray;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: $color-cerulean;

      .rebrand & {
        color: $color-dodger-blue;
      }
    }
  }

  &__item {
    @include type-body-secondary;
    display: inline-block;
    max-width: 150px;
  }

  &__dropdown-container {
    position: relative;
    float: left;
    cursor: pointer;
  }

  &__dropdown {
    position: absolute;
    top: 24px;
    left: 0;
    z-index: 9;
    display: none;
    min-width: 200px;
    padding: 10px 0;
    background-color: $color-white;
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.2);

    &--open {
      display: block;
    }
  }

  &__dropdown-item {
    @include type-body-primary;
    position: relative;
    float: left;
    width: 100%;
    padding: 12px 16px;
    clear: both;
    color: $color-black;

    &:hover {
      cursor: pointer;
      background-color: rgba($color-anakiwa, 0.2);

      .rebrand & {
        background-color: rgba($color-alice-blue, 0.2);
      }
    }
  }
}
