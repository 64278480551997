//@import '../base/colors';

.login {
  background-image: url("../../../assets/images/mountains.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  // Override for base template padding
  .main-content {
    width: 100%;
    left: 0;
  }

  // Override for base template footer
  .footer {
    display: none;
  }

  &__logo-wrapper {
    margin-top: 10%;
    text-align: center;
  }

  &__logo {
    width: 70px;
    height: 124px;
    fill: $color-white;
  }

  &__wrapper {
    max-width: 340px;
    margin: 0 auto;
    margin-top: 90px;
  }

  &__form {
    overflow: auto;
    background-color: $color-white;
    padding: 25px;
  }

  &__form-input {
    width: 100%;
  }

  &__form-input-row {
    margin: 0 0 22px;
  }

  &__head-section {
    height: 75px;
    background-color: $color-shuttle-grey;
    width: 100%;
  }

  &__head-section-title {
    @include type-title;
    padding: 24px;
    color: $color-white;
  }

  &-error__message {
    color: $color-crimson;
    padding-right: 100px;
  }

  &__error-message {
    color: $color-crimson;
    padding-right: 100px;
  }

  &__submit {
    float: right;
  }
}
