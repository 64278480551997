.modal-performance-details {
  position: relative;
  background-color: white;

  overflow: scroll;
  max-height: 600px;

  &__header {
    display: flex;
    align-items: center;

    padding: 0 40px;

    h1 {
      @include type-subheading;
      text-transform: uppercase;
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    border-left: 1px solid $color-iron;

    margin-left: 10px;
    padding: 20px;

    .rebrand & {
      border-left-color: $color-solitude;
    }

    button {
      @include type-body-secondary;

      background-color: transparent;
      border: 0;
      cursor: pointer;

      outline: 0;

      &.selected {
        text-decoration: underline;
      }

      &:nth-child(2) {
        margin-left: 30px;
      }
    }
  }

  &__container {
    border-top: 1px solid $color-iron;
    padding: 30px;

    .rebrand & {
      border-top-color: $color-solitude;
    }

    header {
      @include type-body-secondary;
      border-bottom: 1px solid $color-iron;

      .rebrand & {
        border-bottom-color: $color-solitude;
      }
    }

    .columns-2 {
      width: 12%;
    }
  }

  &__metric {
    font-size: 14px;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;

    cursor: pointer;
  }
}
