.set-contents-banner {
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: map-get($z-index, editing-banner);
  width: 100%;
  padding: 10px 40px 10px 30px;
  color: $color-white;
  background-color: $color-cerulean;

  .rebrand & {
    background-color: $color-dodger-blue;
  }

  &__icon {
    @include icon-size(22px);
    margin-top: 5px;
    fill: $color-white;
  }

  &__text {
    @include type-subheading;
    margin: 4px auto 0 14px;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__button {
    float: right;
    margin-left: 8px;

    // Overwrite background-color
    // when used on blue background
    &.button-small--disabled {
      background-color: rgba($color-white, 0.66);
    }

    &--is-loading {
      .button-small__icon {
        top: 8px;
        transform: rotate(0deg);
        animation: spin 1s linear infinite;
      }
    }
  }
}
