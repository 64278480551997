@import "styles/base/colors";
@import "styles/base/z-index";
@import "styles/utilities/typography";

$parent: toaster-container;

.#{$parent} {
  @include type-body-secondary;
  position: fixed;
  z-index: 999999;
  right: 56px;
  bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
  }

  &__notification {
    position: relative;
    margin: 0;
    padding: 16px 20px 13px;
    border-radius: 2px;
    max-width: 100%;
    background: $color-outer-space;
    background-repeat: no-repeat;
    background-position: 15px center;
    opacity: 0.8;
    color: $color-white;

    .rebrand & {
      background: $color-black-pearl;
    }

    + .#{$parent}__notification {
      margin-top: 10px;
    }
  }

  &__emphasised-message {
    color: $color-cerulean;
    text-decoration: underline;

    .rebrand & {
      color: $color-dodger-blue;
    }

    &:hover {
      color: $color-jelly-bean;

      .rebrand & {
        background-color: $color-denim;
      }
    }
  }

  &__body-message {
    margin-right: 20px;
  }

  &__button {
    position: absolute;
    z-index: 100;
    top: 0;
  }

  &__close {
    @include type-title;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 10px;
    border: 0;
    background: transparent;
    text-shadow: none;
    opacity: 1;
    cursor: pointer;
    color: $color-white;
    line-height: 1.92;
    -webkit-appearance: none;
  }
}
