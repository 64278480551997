@import "styles/components/articles";
@import "styles/base/colors";
@import "styles/utilities/mixins";

$parent: image-block;

.#{$parent} {
  @include article-media-block;
  @include unselectable;

  &__container {
    @include unselectable;
    @include article-media-block;
    max-width: 488px;
  }

  &__image {
    @include article-image;
  }
}
