.delete-block {
  margin-top: 40px;
  border: dashed $color-iron;
  border-width: 3px 0;
  padding: 29px 30px;

  .rebrand & {
    border-color: #dbdee0;
  }

  @include ml-down {
    border: 0;
    padding: 0;
  }

  &__inner {
    @include clearfix;
  }

  &-paragraph {
    @include type-body-primary;
    margin-left: 23px;
    vertical-align: middle;

    .rebrand & {
      text-transform: uppercase;
      color: $color-east-bay;
      font-style: italic;
    }

    @include ml-down {
      display: none;
    }
  }
}
