.radio-input {
  display: none;

  + .radio-input__label {
    &::after {
      display: inline-block;
      width: 22px;
      height: 22px;
      background-color: $color-iron;
      border-radius: 25px;
      content: "";

      .rebrand & {
        background-color: $color-solitude;
      }
    }

    &:focus::after,
    &:hover::after {
      width: 18px;
      height: 18px;
      background-color: $color-white;
      border: 2px solid $color-iron;

      .rebrand & {
        border-color: $color-solitude;
      }
    }

    &:hover::after {
      background-color: $color-hit-gray;
    }

    &.lighter-color {
      &::after {
        background-color: $color-porcelain;
      }

      &:focus::after {
        background-color: $color-white;
      }

      &:hover::after {
        background-color: $color-hit-gray;
        border-color: $color-hit-gray;
      }
    }
  }

  &:checked + .radio-input__label {
    &::after {
      width: 10px;
      height: 10px;
      background-color: $color-white;
      border: 6px solid $color-cerulean;

      .rebrand & {
        border: 6px solid $color-dodger-blue;
      }
    }

    &:hover::after {
      background-color: $color-hit-gray;
      border: 6px solid $color-outer-space;

      .rebrand & {
        border-color: $color-black-pearl;
      }
    }

    &.lighter-color:hover::after {
      background-color: $color-hit-gray;
    }
  }
}
