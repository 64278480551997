.multiple-checkbox-alpha {
  &__search {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }

  &__select-all {
    border-bottom: 1px solid $color-porcelain;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }

  &__search-field {
    width: 100%;
    margin-bottom: 20px;
  }

  &__count {
    @include type-body-primary;
    padding: 11px 0;
  }
}
