.accordion {
  @include clearfix;
  position: relative;
  margin-top: 20px;
  background: $color-white;
  box-shadow: 0 1px 2px rgba($color-black, 0.15);

  &--shadowless {
    box-shadow: none;
  }

  &__content {
    background: $color-white;
  }

  &__content-inner {
    padding: 24px;
  }

  &__header {
    @include clearfix;
    position: relative;
    padding: 24px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-anakiwa, 0.2);

      .rebrand & {
        background-color: rgba($color-alice-blue, 0.2);
      }

      .accordion__header-state-indicator {
        fill: $color-cerulean;

        .rebrand & {
          fill: $color-dodger-blue;
        }
      }
    }

    &.open {
      background-color: $color-iron;

      .rebrand & {
        background-color: $color-solitude;
      }

      &:hover {
        &::before {
          background-color: rgba($color-hit-gray, 0.2);
        }
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      content: "";
    }

    &--disabled {
      cursor: default;
      background-color: $color-iron;

      .rebrand & {
        background-color: $color-solitude;
      }

      &:hover {
        background-color: $color-iron;

        .rebrand & {
          background-color: $color-solitude;
        }

        .accordion__header-state-indicator {
          fill: $color-hit-gray;
        }
      }
    }
  }

  &__header-meta {
    float: right;
  }

  &__header-meta-proress {
    @include type-subheading;
    position: relative;
    top: 3px;
    text-align: center;
    text-transform: uppercase;
  }

  &__header-button {
    position: absolute;
    top: 50%;
    right: 85px;
    float: right;
    transform: translateY(-50%);
  }

  &__header-state-indicator {
    @include icon-size(24px);
    float: right;
    margin-left: $margin-small;
    transition: all 0.2s;
    fill: $color-hit-gray;

    .open & {
      transform: rotate(180deg);
      fill: $color-regent-gray;
    }
  }

  &__item-container {
    background-color: $color-white;
  }

  &__title {
    @include span(8 of 12 inside no-gutters);
    @include ellipsis;
    @include type-subheading;
    position: relative;
    float: left;
    color: $color-outer-space;
    text-transform: uppercase;

    .rebrand & {
      color: $color-black-pearl;
      text-transform: capitalize;
    }

    &--disabled {
      color: $color-regent-gray;
    }

    &--has-tag {
      top: 0;
    }

    .tag {
      margin-left: 12px;
    }
  }

  &__locked-container {
    float: right;
    margin-right: 13px;
  }

  &__locked-label {
    @include type-note-primary;
    @include type-italic;
    display: inline-block;
    color: $color-hit-gray;
    text-transform: uppercase;
    vertical-align: super;
  }

  &__locked-icon {
    @include icon-size(24px);
    fill: $color-hit-gray;
  }

  &__conditional-button-group {
    display: none;
    float: left;
    text-align: right;
    animation: fade-in 0.3s;

    &--active {
      display: block;
    }
  }

  &__conditional-button {
    margin-bottom: 12px;
    clear: left;
  }
}

[accordion-group] {
  position: relative;
  overflow: visible;
}

.collapsing {
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.collapsed {
  display: none;
  overflow: hidden;
}
