@import "styles/base/colors";
@import "styles/utilities/typography";

.link-input {
  &__container {
    display: flex;
    background-color: transparent;
  }

  &__input {
    @include type-body-primary;
    margin-right: 12px;
    padding: 0 14px;
    width: 323px;
    height: 48px;
    border: 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 4px 12px 0 rgba(0, 0, 0, 0.2);

    &::placeholder {
      color: $color-hit-gray;
      text-transform: lowercase;
    }
  }

  &__button {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  }
}
