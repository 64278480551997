.header-section {
  position: relative;
  padding: 24px 32px 0;
  background-color: $color-white;
  border-bottom: 1px solid rgba($color-hit-gray, 0.35);

  &--no-filtering {
    padding-bottom: $margin-small;
  }

  &--no-divider {
    border-bottom: 0;
  }

  &--tabs {
    padding-top: 0;
  }
}
