@import "styles/base/colors";
@import "styles/utilities/typography";
@import "styles/utilities/mixins";

$parent: block-header;

.#{$parent} {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 541px;
  background-color: $color-white;
  border: 1px solid $color-porcelain;
  border-radius: 2px;
  margin: 0 auto;
  box-shadow: 0 7px 14px rgba($color-black, 0.15);
  z-index: 2;
  color: $color-outer-space;

  .rebrand & {
    color: $color-black-pearl;
  }

  &__title {
    position: relative;
    display: flex;
    padding: 0 15px;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 48px;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 100%;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 83%,
        rgba(255, 255, 255, 1) 100%
      );
      content: "";
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    fill: $color-regent-gray;
  }

  &__icon-wrap {
    display: inline-flex;
  }

  &__text {
    @include type-body-primary;
    position: absolute;
    width: 100%;
    padding: 0 80px 60px 8px;
    white-space: nowrap;
    overflow: scroll;
    top: 14px;
    left: 39px;
  }
}
