.modal-settings {
  max-width: calc(500px + #{$navigation-width} + (2 * #{$margin-small}));

  &__title {
    padding: 24px;
  }

  &__content {
    padding: 40px 44px 20px;
  }

  .form__field-label {
    display: none;
  }

  .form__field-wrap,
  .smartfield__item-wrapper {
    width: 100%;
  }

  .placeholder {
    &--active {
      color: $color-cerulean;

      .rebrand & {
        color: $color-dodger-blue;
      }
    }
  }
}
