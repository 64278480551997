// Build your button using these:
// button-standard button-standard--primary (blue)
// button-standard button-standard--secondary-on-dark (modals mainly)
// button-standard button-standard--secondary-on-light (secondary action: CANCEL, etc.)
// button-standard button-standard--tertiary (red)
//
// Change button state by replacing modifiers with these:
// button-standard--disabled
// button-standard--locked
//
// Spacing between buttons: 12px
.button-standard {
  @include button-defaults;
  @include button-states;
  min-height: 48px;
  padding: 14px 12px 11px;
  line-height: 22px;

  &--outline {
    padding: 13px 11px 10px;
  }

  &__text {
    @include type-button;
    padding: 0 2px;

    &--has-icon {
      padding-right: 36px;
    }
  }

  &__icon {
    @include button-icon-defaults;
    @include icon-size(24px);
    right: 12px;
  }
}
