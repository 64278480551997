.header-actions {
  display: flex;
  flex-direction: row;
  min-height: 80px;
  padding: 0 0 4px;
  background-color: $color-white;

  @include lg-up {
    padding: 0 24px 4px;
  }

  &__info {
    display: flex;
    flex-flow: column;

    @include lg-up {
      max-width: span(9 of 12);
    }
  }

  &__title {
    @include type-display;
    display: flex;
    flex-flow: row;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &__type {
    @include type-body-primary;
    position: relative;
    margin-top: -6px;
    clear: left;
    color: $color-outer-space;
    text-transform: uppercase;
    fill: $color-regent-gray;

    .rebrand & {
      text-transform: none;
      color: $color-black-pearl;
      margin-top: 8px;
    }
  }

  &__icon {
    @include icon-size(24px);
    position: relative;
    top: 6px;
    margin-right: 5px;
  }

  &__label {
    position: relative;
    float: left;
    width: 70%;
  }

  &__settings {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    justify-content: space-between;
  }

  &__status {
    @include type-note-primary;

    display: none;
    padding: 5px;
    color: $color-outer-space;
    text-transform: uppercase;
    background: $color-athens-gray;

    .rebrand & {
      color: $color-black-pearl;
    }

    &.published {
      background: $color-porcelain;
    }
  }

  &__new-input {
    @include type-display;
    max-width: 100%;
    padding: 0;
    color: $color-hit-gray;
    background: none;
    border-bottom: 1px dotted $color-athens-gray;
  }

  &__new-action {
    float: right;
  }
}
