@import "styles/base/colors";

$parent: radio;

.#{$parent} {
  &__input {
    display: none;

    &:checked {
      + .#{$parent}__label {
        &::after {
          background-color: $color-white;
          border: 6px solid $color-cerulean;
          width: 10px;
          height: 10px;

          .rebrand & {
            border-color: $color-dodger-blue;
          }
        }
      }
    }
  }

  &__label {
    &::after {
      content: "";
      background-color: $color-iron;
      border-radius: 25px;
      display: inline-block;
      width: 22px;
      height: 22px;

      .rebrand & {
        background-color: $color-solitude;
      }
    }

    &:focus,
    &:hover {
      &::after {
        background-color: $color-white;
        border: 6px solid $color-iron;
        width: 10px;
        height: 10px;

        .rebrand & {
          border-color: $color-solitude;
        }
      }
    }
  }
}
