.home-container {
  margin-top: 120px;
  margin-bottom: 32px;

  h1,
  h2 {
    @include type-title;
    font-weight: 500;

    text-align: center;
  }

  h1 {
    color: $color-hit-gray;
  }

  h2 {
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  .search-container {
    margin: 72px auto 0;
    width: 680px;
  }

  .search-bar-container {
    width: 680px;
  }
}
