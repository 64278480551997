.user-profile {
  text-align: right;

  &--header-widget {
    @include lg-up {
      width: auto;
    }
  }

  &__author {
    @include type-body-primary;
    color: $color-regent-gray;
  }

  &__date {
    @include type-note-primary;
    color: $color-regent-gray;
  }
}
