@mixin dropdown-wrapper {
  position: relative;
  display: block;
  border: 2px solid transparent;
  cursor: pointer;
  line-height: 0;

  &--disabled {
    cursor: not-allowed;

    &:focus {
      outline: 0;
    }
  }
}

@mixin dropdown-icon($fill-color) {
  position: absolute;
  top: calc(50% - 2px);
  transform: translateY(-50%);
  fill: $fill-color;
}

@mixin dropdown-text {
  @include type-subheading;
  display: inline-block;
  overflow: hidden;
  padding-right: 36px;
  width: 100%;
  white-space: nowrap;
  text-transform: uppercase;
  text-overflow: ellipsis;
}

@mixin dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  box-sizing: content-box;
  border: 2px solid $color-iron;
  border-top: 0;
  width: 100%;
  background: $color-white;
  text-decoration: none;
  text-transform: uppercase;

  .rebrand & {
    border-color: $color-solitude;
  }

  &--visible {
    display: block;
    overflow-x: auto;
    min-width: 100px;
    max-height: 316px;
  }
}
