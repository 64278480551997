@import "styles/base/colors";

$parent: checkbox;

.#{$parent} {
  &--is-selectable {
    cursor: pointer;
  }

  &__input {
    display: none;

    &:checked {
      + .#{$parent}__label .#{$parent}__icon {
        background-color: $color-cerulean;
        border: 0;
        display: inline-block;
        fill: $color-white;

        .rebrand & {
          background-color: $color-dodger-blue;
        }
      }
    }
  }

  &__icon {
    border: 2px solid $color-iron;
    border-radius: 2px;
    fill: none;
    height: 24px;
    width: 24px;

    .rebrand & {
      border-color: $color-solitude;
    }
  }
}
