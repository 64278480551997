@font-face {
  font-family: "Ciutadella";
  src: local("Ciutadella-Medium"),
    url("../../../assets/fonts/Ciutadella-Medium.woff2") format("woff2"),
    url("../../../assets/fonts/Ciutadella-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Ciutadella Regular Italic";
  src: local("Ciutadella-RegularItalic"),
    url("../../../assets/fonts/Ciutadella-RegularItalic.woff2") format("woff2"),
    url("../../../assets/fonts/Ciutadella-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "FSAlbert";
  src: local("FSAlbert Regular"), local("FSAlbert-Regular"),
    url("../../../assets/fonts/FSAlbert-Regular.woff2") format("woff2"),
    url("../../../assets/fonts/FSAlbert-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: VideoJS;
  src: url("../../../assets/fonts/VideoJS.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
