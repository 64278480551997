.options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 16;
  display: none;
  width: 100%; /* putting right: 0; will render this useless, leaving this here for now */
  margin-left: -2px; /* also this will be fixed by right: 0 */
  text-decoration: none;
  text-transform: uppercase;
  background: $color-white;
  border: 2px solid $color-iron;
  border-top: 0;
  box-sizing: content-box;

  .rebrand & {
    text-transform: capitalize;
    border-color: $color-solitude;
    border-top-color: transparent;
  }

  &--light {
    border: 1px solid $color-porcelain;
    left: 1px;
  }

  &--visible {
    display: block;
    // 217px: 5 list items at 40px (5 * 40)
    // + .options__list top padding (14px)
    // + .options top and bottom borders (3px)
    max-height: 217px;
    min-width: 100px;
    overflow-x: auto;
    border-top: 1px solid $color-iron;

    .rebrand & {
      border-top-color: transparent;
    }
  }

  &--has-direct-upload {
    border: 1px solid $color-iron;
    box-shadow: 0 7px 14px 0 $color-black-alpha;

    .rebrand & {
      border-color: $color-solitude;
    }

    margin-left: 0;
    right: 0;
    width: auto;
    z-index: map-get($z-index, options);
    .options__item {
      &:hover {
        background-color: $color-anakiwa-alpha;

        .rebrand & {
          background-color: $color-alice-blue-alpha;
        }
      }

      .options__link {
        color: $color-regent-gray;
        font-weight: 500;

        &:hover {
          color: $color-cerulean;
          text-decoration: none;

          .rebrand & {
            color: $color-dodger-blue;
          }
        }
      }
    }
  }

  &__list {
    padding: 14px 0;

    .rebrand & {
      padding: 4px 0;
    }

    &--separator {
      border-bottom: 1px solid $color-iron;

      .rebrand & {
        border-bottom-color: transparent;
      }
    }
  }

  &__header {
    @include type-note-secondary;
    position: relative;
    bottom: -12px;
    margin: 0 15px;
    text-align: left;

    &--translations {
      bottom: 0;
      padding: 8px 0;
      color: $color-hit-gray;
    }

    &--sub-heading {
      margin-top: 8px;
      padding-top: 16px;
      border-top: 1px solid $color-iron;

      .rebrand & {
        border-top-color: transparent;
      }
    }
  }

  &__link {
    @include type-body-primary;
    display: block;
    padding: 9px 15px;
    color: $color-outer-space;
    cursor: pointer;

    .rebrand & {
      color: $color-black-pearl;
    }

    .options--light & {
      @include type-body-primary;
      color: $color-regent-gray;

      &:hover {
        background-color: rgba($color-anakiwa, 0.2);
        text-decoration: none;
        color: $color-cerulean;

        .rebrand & {
          color: $color-dodger-blue;
          background-color: rgba($color-alice-blue, 0.2);
        }
      }

      &--active {
        color: $color-outer-space;

        .rebrand & {
          color: $color-black-pearl;
        }

        &:hover {
          background-color: transparent;
          color: $color-outer-space;
          cursor: default;

          .rebrand & {
            color: $color-black-pearl;
          }
        }
      }
    }

    @include ml-down {
      @include clearfix;
    }

    &:hover {
      text-decoration: underline;
    }

    &--editableform {
      @include type-body-primary;
      text-transform: none;
    }

    &--hover-disabled:hover {
      text-decoration: none;
    }

    .header-actions &,
    .header-actions__new-action &,
    .article-workspace__add & {
      @include type-subheading;
    }
  }

  &__link-combo {
    @include ml-down {
      float: left;
    }
  }

  &__link-combo-mobile {
    @include ml-down {
      float: right;
    }

    @include ml-up {
      display: none;
    }
  }
}

.option {
  position: relative;
  cursor: pointer;

  &--hoverable {
    &:hover {
      .options {
        display: block;
        // 217px: 5 list items at 40px (5 * 40)
        // + .options__list top padding (14px)
        // + .options top and bottom borders (3px)
        max-height: 217px;
        min-width: 100px;
        overflow-x: auto;
        border-top: 1px solid $color-iron;

        .rebrand & {
          border-top-color: transparent;
        }
      }
    }
  }
}
