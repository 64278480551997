@import "styles/base/colors";
@import "styles/utilities/typography";
@import "styles/utilities/mixins";
@import "styles/components/articles";

.editor-block {
  @include article-block-border;
  position: relative;
  padding: 24px 0;
  width: 100%;

  &:last-of-type {
    background-image: none;
  }

  &__template {
    background-color: $color-athens-gray;
  }

  &__text-container {
    padding: 0 44px;

    h2 {
      padding: 20px 0;
      color: $color-shuttle-grey;
    }

    h3,
    h4 {
      padding: 15px 0;
    }

    .public-DraftStyleDefault-block {
      color: $color-regent-gray;
    }
  }

  &--select-disabled > * {
    @include unselectable;
  }
}
