@import "styles/utilities/typography";
@import "styles/base/colors";

$parent: "schedules-date";

.#{$parent} {
  display: flex;
  flex-flow: row wrap;
  height: 20px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;

  &__text {
    @include type-note-primary;
    padding: 3px 0 0 12px;
    color: $color-regent-gray;
  }

  &__icon {
    width: 19px;
    height: 9px;
    color: $color-regent-gray;
  }
}
