.modal-notification {
  top: 50%;
  left: 50%;
  width: 604px;
  transform: translate(-50%, -50%);

  @include lg-up {
    width: 834px;
    padding-left: $navigation-width;
  }

  &__content {
    margin: $margin-small;
  }

  &__header {
    position: relative;
    padding: 24px;
    color: $color-white;
    background: $color-shuttle-grey;
  }

  &__title {
    @include type-title;
    color: $color-white;
  }

  &__body {
    padding: 24px;
    background: $color-white;
  }

  &__body-message {
    @include type-body-primary;
    padding: 20px $margin-small 20px 20px;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &__dont-show {
    padding: 0 20px;
  }

  &__dont-show-text {
    @include type-body-primary;
    margin-left: 12px;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &__actions {
    margin: 16px 0;
    text-align: right;
  }

  &__button {
    margin-left: 12px;
  }

  &--is-active {
    animation: fade-in 300ms ease-out;

    &.is-closing {
      animation: fade-out 300ms ease-in;
    }
  }
}
