.table {
  width: 100%;
  min-width: 725px;

  &--spaced {
    margin-top: 20px;

    @include lg-up {
      margin-top: 30px;
    }
  }

  &--drawer {
    min-width: auto;
  }

  &__list {
    &--drawer {
      height: calc(100vh - 292px);
      overflow-x: auto;
    }
  }

  &__head {
    &--darker {
      background-color: $color-iron;

      .rebrand & {
        background-color: $color-solitude;
      }
    }

    &--white {
      background-color: $color-white;
    }

    &--collapsed {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;
    }

    &--expanded {
      max-height: 70px;
      overflow: visible;
    }

    &-tip {
      margin: 5px 0 10px 26px;
      font-size: 12px;
      color: $color-regent-gray;
    }
  }

  &__head-inner {
    display: flex;
    align-items: center;
    padding: 0 14px;

    // 85px - controls width
    &--has-separated-controls {
      width: calc(100% - 85px);
      padding-right: span(2);
    }

    &--expanded-controls {
      width: 100%;
      padding-right: 80px;
    }

    &--inline-control {
      padding: 0 0 0 14px;
      width: calc(100% - 85px);
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    height: 57px;
    background-color: $color-white;
    box-shadow: 0 2px 3px 0 rgba($color-black, 0.15);

    // Remove outer row shadow
    &--inside {
      box-shadow: inset 0 1px 0 0 rgba($color-porcelain, 1);
    }

    &__collapsible-content {
      overflow: hidden;
      transition: 0.25s max-height linear;

      &--closed {
        max-height: 0;
      }

      &--finished-opening {
        overflow: initial;
      }
    }

    &--has-image {
      height: 88px;
    }

    &--drawer {
      box-shadow: none;
    }

    &--active {
      background-color: $color-iron;
      transition: all 0.5s ease;

      .rebrand & {
        background-color: $color-solitude;
      }

      .table__controls-item:hover {
        background-color: transparent;
      }
    }

    &--grey {
      position: relative;
      background-color: $color-athens-gray;
      border: 0;
      box-shadow: none;

      &:hover {
        .table__controls-item {
          background-color: transparent;
        }
      }
    }

    &--tall {
      height: 168px;
    }
  }

  &__row-delimiter {
    width: 100%;
    height: 24px;
    background-image: repeating-linear-gradient(
      to bottom,
      $color-athens-gray,
      $color-athens-gray 7px,
      rgba($color-hit-gray, 0.35) 7px,
      rgba($color-hit-gray, 0.35) 8px
    );
    border: 1px solid rgba($color-hit-gray, 0.35);
    transition: all ease-in 0.5s;
    transition-delay: 0.5s;

    &.ng-hide-remove {
      transition: all ease-in 0.5s;
    }

    &.ng-hide {
      height: 0;
      opacity: 0;
      transition: all ease-in 0.5s;
    }
  }

  &__row-inner,
  &__row-inner--collapsible {
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-grow: 1;
    min-width: 640px;
    padding: 0 14px;
    box-shadow: inset 0 1px 0 0 rgba($color-hit-gray, 0.35);

    &--hover-deactivated {
      cursor: default;

      &:hover,
      &:focus {
        background-color: transparent;
      }

      &.table__row-inner--inactive:not(.table__row-inner--is-link) {
        &:hover,
        &:focus {
          background-color: $color-athens-gray;
        }
      }

      .table__controls-item,
      .table__controls-item:hover {
        background-color: transparent;
      }
    }

    &__row-inner {
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: rgba($color-anakiwa, 0.2);

        .rebrand & {
          background-color: rgba($color-alice-blue, 0.2);
        }

        .table__controls-item {
          fill: $color-hit-gray;
        }
      }
    }

    &--invalid {
      cursor: default;
      background-color: rgba($color-tickle-pink, 0.1);

      &:hover,
      &:focus {
        background-color: rgba($color-tickle-pink, 0.1);

        .table__controls-item {
          fill: $color-hit-gray;
        }
      }
    }

    &--selected {
      background-color: rgba($color-anakiwa, 0.2);
      box-shadow: inset 0 1px 0 0 rgba($color-cerulean, 1),
        0 1px 0 0 rgba($color-cerulean, 1);

      .rebrand & {
        background-color: rgba($color-alice-blue, 0.2);
        box-shadow: inset 0 1px 0 0 rgba($color-dodger-blue, 1),
          0 1px 0 0 rgba($color-dodger-blue, 1);
      }

      &:hover,
      &:focus {
        background-color: rgba($color-anakiwa, 0.1);

        .rebrand & {
          background-color: rgba($color-alice-blue, 0.1);
        }
      }
    }

    &--added,
    &--placed,
    &--modified {
      background-color: rgba($color-anakiwa, 0.1);

      .rebrand & {
        background-color: rgba($color-alice-blue, 0.1);
      }

      &:hover,
      &:focus {
        background-color: rgba($color-anakiwa, 0.2);

        .rebrand & {
          background-color: rgba($color-alice-blue, 0.2);
        }
      }
    }

    &--deleted {
      background-color: rgba($color-tickle-pink, 0.1);

      &:hover,
      &:focus {
        background-color: rgba($color-tickle-pink, 0.2);
      }
    }

    &--has-separated-controls {
      padding-right: span(2);
    }

    &--is-collapsible {
      padding-right: span(1.33);
    }

    // 85px - controls width
    // Could be used when some rows in the same block
    // have additional controls and some don't. This
    // helps to align content.
    &--shortened {
      margin-right: 85px;
    }

    &--inline-control {
      padding-right: 85px;
    }

    &--expanded-controls {
      padding-right: 80px;
      cursor: default;
    }

    // 85px - controls width
    // Could be used along with --invalid modifier
    // when row needs to span edge to edge
    &--extended {
      padding-right: calc(85px + #{span(2)});
    }

    &--is-link {
      &:hover,
      &:focus {
        background-color: rgba($color-anakiwa, 0.2);

        .rebrand & {
          background-color: rgba($color-alice-blue, 0.2);
        }
      }
    }

    &--inactive {
      background-color: $color-athens-gray;
    }
  }

  &__row-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__column {
    display: flex;
    align-items: stretch;
    transition: background 0.2s ease-in-out;

    &__engagements {
      display: flex;
      align-items: center;
    }

    &__switch_to {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      text-align: center;
      margin-right: 18px;
    }

    &__apply_now {
      background-color: #7ee0b2;
      border: 0;
      border-radius: 3px;

      font-family: "FSAlbert", Arial, sans-serif;

      padding: 8px 28px;
      margin-right: 10px;
    }

    &__current_engagement {
      font-size: 18px;
      margin-right: 4px;
    }

    &__compared_engagement {
      font-size: 14px;
      color: #25e087;
    }

    &--controls {
      position: relative;
      display: none;
      flex-grow: 1;

      @include ml-up {
        display: block;
      }
    }

    &--expanded-controls {
      position: absolute;
      top: 1px;
      right: 0;
      bottom: 0;
      transition: none;

      &:before {
        content: "";
        position: absolute;
        width: 88px;
        top: 0;
        bottom: 0;
        left: -88px;
        pointer-events: none;

        .table__row-inner--expanded-controls & {
          background-image: linear-gradient(
            to right,
            rgba(228, 246, 254, 0) 0%,
            rgba(232, 248, 255, 0.83) 50%,
            #e8f8ff 100%
          );
        }

        .table__row-inner--invalid & {
          background-image: linear-gradient(
            to right,
            rgb(255, 241, 243) 0%,
            rgb(255, 241, 243) 83%,
            #fff1f3 100%
          );
        }

        .table__row-inner--hover-deactivated &,
        .table__row-inner--invalid-deactivated & {
          background-image: none;
        }
      }

      .table__controls-item {
        padding: 0 15px;
        right: 12px;
        z-index: 1;
      }

      .table__row-inner--editing & {
        opacity: 0;
      }

      .table__row-inner--editing:hover & {
        background-color: rgb(232, 248, 255);
        opacity: 1;
      }

      .table__row-inner--invalid:hover & {
        background-color: #fff1f3;
      }
    }

    &--type {
      .table__list-row__heading--type {
        padding-left: 10px;
      }
    }

    &__collapsible-toggle {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      cursor: pointer;
    }

    &--aligned {
      align-items: center;
    }

    &--uppercase {
      text-transform: uppercase;
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 60px;
    padding: 0 16px;
  }

  &__cell-head {
    @include type-note-primary;
    width: 100%;
    padding: 10px 0 6px;
    overflow: hidden;
    color: $color-regent-gray;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;

    &--aligned {
      text-align: center;
    }
  }

  &__content-score {
    display: flex;
    text-align: center;

    &--aligned {
      justify-content: center;
    }
  }

  &__cell-media-wrapper {
    position: relative;
    width: 100%;
    max-width: 100px;
    margin: 12px 0;

    &--inactive {
      opacity: 0.3;
    }
  }

  &__cell-media {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    background-color: $color-outer-space;

    .rebrand & {
      background-color: $color-black-pearl;
    }

    &--placeholder {
      @include image-placeholder($color-iron);

      .rebrand & {
        @include image-placeholder-rebrand($color-solitude);
      }
    }
  }

  &__cell-image {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 100%;
    transform: translate(-50%, -50%);
  }

  &__body-data-meta {
    margin-top: 3px;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }
  }

  &__cell-position {
    @include type-body-primary;
    color: $color-regent-gray;

    &--unplaced {
      color: $color-outer-space;
      background: none;

      .rebrand & {
        color: $color-black-pearl;
      }
    }
  }

  &__cell-heading {
    @include type-body-primary;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }

    &--no-preview {
      @include type-note-primary;
      @include type-italic;
      color: $color-regent-gray;
      text-transform: uppercase;
    }

    &--tooltip {
      display: flex;
    }

    &--limit-lines {
      // Best non CSS solution for modern (webkit) browsers
      display: -webkit-box;
      // Fall back for non webkit browsers
      max-height: 38px;
      overflow: hidden;
      box-orient: vertical;
      line-clamp: 2;

      @include lg-down {
        max-height: 50px;
        line-clamp: 3;
      }
    }

    &--scrolling-title {
      width: 100%;
      overflow: hidden;
    }

    .text-scroller--scrollable {
      .table__cell--scrolling-title:hover &::after,
      .table__row-inner:hover &::after,
      .table__row-inner:focus &::after {
        background: linear-gradient(to right, transparent, $color-lily-white);
      }

      .table__row-inner--hover-deactivated
        .table__cell--scrolling-title:hover
        &::after,
      .table__row-inner--hover-deactivated:hover
        .table__cell--scrolling-title
        &::after,
      .table__row-inner--hover-deactivated:focus
        .table__cell--scrolling-title
        &::after {
        background: linear-gradient(to right, transparent, $color-white);
      }

      .table__row-inner--invalid .table__cell--scrolling-title &::after,
      .table__row-inner--invalid:hover .table__cell--scrolling-title &::after,
      .table__row-inner--invalid:focus .table__cell--scrolling-title &::after,
      .table__row-inner--invalid .table__cell--scrolling-title:hover &::after {
        background: linear-gradient(
          to right,
          transparent,
          $color-lavender-blush
        );
      }

      .table__row-inner--is-link:hover .table__cell--scrolling-title &::after,
      .table__row-inner--is-link:hover .table__cell--scrolling-title &::after,
      .table__row-inner--is-link .table__cell--scrolling-title:hover &::after {
        background: linear-gradient(to right, transparent, $color-lily-white);
      }
    }

    &--inactive {
      opacity: 0.3;
    }
  }

  &__cell-type {
    @include type-body-primary;
    color: $color-regent-gray;
    text-transform: uppercase;

    &--standard {
      text-transform: none;
    }

    &--italic {
      @include type-italic;
    }

    &--error {
      color: $color-crimson;
    }

    &--inactive {
      color: rgba($color-regent-gray, 0.3);
    }
  }

  &__cell-type-icon {
    @include icon-size(24px);
    margin: 4px 5px 0 0;
    fill: $color-regent-gray;

    @include lg-down {
      display: none;
    }

    .table__row-inner--inactive & {
      fill: rgba($color-regent-gray, 0.3);
    }
  }

  &__cell-modified-date {
    @include type-note-primary;
    color: $color-regent-gray;
  }

  &__cell-title {
    @include type-body-primary;
    color: $color-outer-space;

    .rebrand & {
      color: $color-black-pearl;
    }

    &--limit-lines {
      // Best non CSS solution for modern (webkit) browsers
      display: -webkit-box;
      // Fall back for non webkit browsers
      max-height: 34px;
      overflow: hidden;
      box-orient: vertical;
      line-clamp: 2;

      @include lg-down {
        max-height: 50px;
        line-clamp: 3;
      }
    }

    &--inactive {
      opacity: 0.3;
    }

    &--uppercase {
      text-transform: uppercase;
    }
  }

  &__cell-appearance {
    width: 100%;
  }

  &__cell-tooltip {
    position: relative;
    align-self: center;
    padding-left: 12px;
  }

  &__cell-appearance-icon {
    position: absolute;
    top: 50%;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 20px;
    transform: translate(50%, -50%);
  }

  &__cell-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0;
  }

  // @TODO: this should be an SVG icon along
  // with other icons in a Sketch file
  &__appearance-icon {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 2px solid transparent;
      border-radius: 20px;
      content: "";
    }

    &--editable {
      &::before {
        background: transparent;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 1px;
        background: $color-regent-gray;
        content: "";
        transform: translate(-50%, -50%);

        .table__row-inner--inactive & {
          background: rgba($color-regent-gray, 0.3);
        }
      }
    }

    &--empty {
      &::before {
        background: transparent;
        border-color: $color-regent-gray;

        .table__row-inner--inactive & {
          border-color: rgba($color-regent-gray, 0.3);
        }
      }
    }

    &--full {
      &::before {
        background: $color-regent-gray;

        .table__row-inner--inactive & {
          background: rgba($color-regent-gray, 0.3);
        }
      }
    }
  }

  &__controls {
    display: flex;

    &--inline {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }

    &--offset {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }

    &--separated {
      align-items: stretch;
      flex-direction: row;
      margin-left: auto;
      box-shadow: inset 0 1px 0 0 rgba($color-hit-gray, 0.35);
    }
  }

  &__controls-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 30px;
    cursor: pointer;
    fill: $color-hit-gray;

    &:hover,
    &:focus,
    .table__column--expanded-controls &:hover,
    .table__column--expanded-controls &:focus {
      fill: $color-cerulean;

      .rebrand & {
        fill: $color-dodger-blue;
      }

      // Ensure that hover state is triggered
      // only by hovering control item, not
      // whole table row
      .table__row-inner--invalid & {
        fill: $color-crimson;
      }
    }

    &--divided {
      border-left: 1px solid $color-porcelain;
    }

    &--as-placeholder {
      visibility: hidden;
    }

    .table__controls--offset & {
      padding: 0 27px;
    }
  }

  &__controls-item-icon {
    @include icon-size(24px);
    display: flex;
  }

  &__controls-item-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
