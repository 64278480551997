.ingest-indicator {
  &__wrapper {
    @include span(12 of 12 inside no-gutters);
    position: relative;
    padding: 14px;

    @include ml-up {
      position: static;
      width: auto;
      padding: 0 0 0 10px;
    }
  }

  &__icon {
    @include icon-size(24px);
    position: absolute;
    float: left;
    padding: 4px;
    cursor: pointer;
    border-radius: 50%;
    fill: $color-white;

    @include ml-up {
      top: 50%;
      transform: translate(0, -50%);
    }

    &--locked {
      background-color: $color-corn;

      .rebrand & {
        background-color: $color-blistering-mars;
      }
    }

    &--unlocked {
      background-color: $color-hit-gray;
    }
  }

  &__text {
    @include type-note-primary;
    @include type-italic;
    max-width: 120px;
    margin-left: 34px;
    color: $color-hit-gray;

    @include ml-up {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .form__field__data-source--flex & {
    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__icon,
    &__text {
      position: static;
      top: 0;
      transform: translate(0, 0);
    }

    &__text {
      margin-left: 10px;
    }
  }
}
