.requirement-indicator {
  @include icon-size(20px);
  border-radius: 50%;
  fill: $color-crimson;
  position: absolute;
  top: -12px;
  right: -22px;

  &--fulfilled {
    display: none;
  }
}
