@use "sass:math";

.text-track {
  background-color: $color-white;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: $color-hit-gray;
  }

  @for $i from 1 through $grid-columns {
    &__col-#{$i} {
      width: percentage(math.div($i, $grid-columns));
      padding-right: 20px;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    fill: $color-cerulean;

    .rebrand & {
      fill: $color-dodger-blue;
    }
  }

  &__status {
    color: $color-oslo-gray;
  }

  &__content {
    padding: 0 20px 12px;

    .button-small:not(:last-of-type) {
      margin-right: 12px;
    }
  }

  &__field:first-child {
    padding-top: 12px;
    border-top: 1px solid $color-hit-gray-alpha;
  }

  .collapsible-panel {
    border-top: 1px solid $color-hit-gray-alpha;
  }

  .collapsible-panel__header {
    margin-top: 0;
    padding: 12px 20px;
  }

  .collapsible-panel__header--open {
    background-color: $color-white;

    &:hover {
      background-color: $color-lily-white;
    }
  }

  .collapsible-panel__header--closed {
    .text-track__status {
      text-align: right;
    }
  }

  // don't want to show data source icons
  .ingest-indicator__wrapper {
    display: none;
  }
}
