@import "styles/base/colors";

.link {
  cursor: text;
  color: $color-cerulean;
  text-decoration: underline;

  .rebrand & {
    color: $color-dodger-blue;
  }
}
