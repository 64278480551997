@import "styles/components/lists";

$parent: entity-list;

.#{$parent} {
  @include list-wrapper;

  &__headings {
    @include list-box-shadow;
  }

  &__items {
    min-height: 105px;
    overflow: auto;

    &--modal {
      max-height: 340px;
    }

    &--drawer {
      min-height: auto;
      height: calc(100vh - 286px);
    }
  }
}
