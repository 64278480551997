@import "styles/components/articles";
@import "styles/utilities/typography";
@import "styles/utilities/mixins";

$parent: text-preview;

.#{$parent} {
  &__title,
  &__subtitle,
  &__paragraph,
  &__author {
    word-break: break-word;
  }

  &__title {
    @include article-title;
  }

  &__subtitle {
    @include article-subtitle;
  }

  &__paragraph,
  &__author {
    @include article-generic;
  }

  &__video {
    @include article-media-block-container;
  }
}
