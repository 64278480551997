@import "styles/utilities/typography";
@import "styles/base/colors";
@import "styles/utilities/mixins";

$parent: message-overlay;

.#{$parent} {
  position: absolute;
  top: 31px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  color: $color-regent-gray;
  flex-wrap: wrap;

  &__title {
    @include type-italic;
    @include type-body-primary;
    width: 100%;
    display: block;
    text-align: center;
    text-transform: uppercase;
  }

  &__content {
    @include type-note-primary;
    @include type-italic;
    margin-top: 20px;
  }
}
