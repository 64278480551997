.textfield-error {
  background-color: rgba($color-tickle-pink, 0.2);
  border-color: $color-crimson;

  &::-webkit-input-placeholder {
    color: $color-crimson;
  }

  &::placeholder {
    color: $color-crimson;
  }

  &:-ms-input-placeholder {
    color: $color-crimson;
  }

  + .error-message {
    @include type-note-primary;
    position: absolute;
    right: 0;
    bottom: -21px;
    display: none;
    height: 22px;
    padding: 5px;
    color: $color-white;
    background-color: $color-crimson;

    @include ml-up {
      bottom: -23px;
      height: 24px;
    }
  }

  &:focus,
  &:hover {
    border-color: $color-crimson;

    + .error-message {
      display: inline-block;
    }
  }
}
