// to reduce side effects, position of :after :before needs to be set manual
@mixin spinner-pulse($size, $keyframe: pulse-saving) {
  &::before,
  &::after {
    content: "";
    width: $size;
    height: $size;
    border-radius: 50%;
    animation: fade-in 0.3s;
  }

  &::before {
    animation: $keyframe 0.7s ease-in-out infinite alternate;
  }

  &::after {
    animation: $keyframe 0.7s ease-in-out infinite alternate-reverse;
  }
}
