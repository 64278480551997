.text-tracks {
  &__actions {
    background-color: $color-white;
    padding: 16px 20px;
    box-shadow: 0 1px 2px $color-black-alpha;

    &__hint {
      margin-top: 2px;
      font-size: 12px;
      color: $color-regent-gray;

      &:first-of-type {
        margin-top: 12px;
      }
    }
  }
}
