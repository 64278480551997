@import "styles/utilities/typography";
@import "styles/base/colors";

$parent: "schedule-column";

.#{$parent} {
  display: flex;
  flex-flow: row wrap;
  height: 20px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}
