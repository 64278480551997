@import "styles/base/colors";

ul.inline {
  li {
    display: inline-block;
  }
}

ul.blank {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

@mixin list-box-shadow {
  box-shadow: inset 0 -1px 0 0 rgba($color-hit-gray, 0.5);
}

@mixin list-wrapper {
  width: 100%;
}

@mixin list-headings-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 14px;
}

@mixin list-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  transition: background 0.2s ease-in-out;
}

@mixin list-cell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-width: 60px;
  padding: 0 16px;
}

@mixin list-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 57px;
  background-color: $color-white;

  &:hover {
    background-color: rgba($color-anakiwa, 0.2);

    .rebrand & {
      background-color: rgba($color-alice-blue, 0.2);
    }
  }
}

@mixin list-item-selected {
  background-color: rgba($color-anakiwa, 0.2);
  box-shadow: inset 0 -1px 0 0 $color-cerulean, 0 -1px 0 0 $color-cerulean;

  .rebrand & {
    background-color: rgba($color-alice-blue, 0.2);
    box-shadow: inset 0 -1px 0 0 $color-dodger-blue,
      0 -1px 0 0 $color-dodger-blue;
  }

  &:hover {
    background-color: rgba($color-anakiwa, 0.1);

    .rebrand & {
      background-color: rgba($color-alice-blue, 0.1);
    }
  }
}

@mixin list-item-faded {
  background-color: $color-athens-gray;
}

@mixin list-item-columns {
  @include list-box-shadow;
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-grow: 1;
  min-width: 640px;
  padding: 0 14px;
  cursor: pointer;
}
