.ingest-id-banner {
  display: flex;
  position: relative;
  padding: 8px 32px;
  background-color: $color-zircon;

  @include lg-up {
    padding: 8px 56px;
  }

  &__container {
    position: relative;
  }

  &__icon-label {
    @include type-subheading;
    position: relative;
    padding-left: 48px;
    line-height: 32px;
    text-transform: uppercase;
    vertical-align: text-top;

    @include xl-up {
      width: auto;
    }

    .rebrand & {
      text-transform: none;
    }
  }

  &__icon-container {
    position: absolute;
    top: 50%;
    left: 0;
    width: 32px;
    height: 32px;
    text-align: center;
    background-color: $color-corn;
    border-radius: 50%;
    transform: translate(0, -50%);

    .rebrand & {
      background-color: $color-blistering-mars;
    }
  }

  &__icon {
    @include icon-size(24px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: $color-white;
  }

  &__ingest-id {
    width: 100%;
    padding-top: 12px;
    line-height: 32px;
    color: $color-regent-gray;

    @include xl-up {
      width: auto;
      padding: 0 16px;
    }

    &--editable {
      color: $color-cerulean;
      text-decoration: underline;
      cursor: pointer;

      .rebrand & {
        color: $color-dodger-blue;
      }
    }
  }

  &__date {
    @include span(12 of 12 inside no-gutters);
    @include type-note-primary;
    padding-top: 12px;
    color: $color-regent-gray;

    @include xl-up {
      position: absolute;
      top: 50%;
      right: 56px;
      width: auto;
      padding-top: 0;
      transform: translate(0, -50%);
    }
  }
}
