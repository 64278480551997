.droppable-container {
  &.empty {
    background: $color-iron;

    .rebrand & {
      background: $color-solitude;
    }
  }
}

.droppable {
  @include clearfix;
  width: calc(100% + 64px);
  padding: 28px;
  margin: -32px -32px 20px;
  text-align: center;
  background-color: $color-iron;

  .rebrand & {
    background-color: $color-solitude;
  }

  &__contextual-text {
    padding-top: 15px;
    color: $color-regent-gray;

    .rebrand & {
      font-size: 13px;
    }
  }

  &__image {
    position: relative;
    @include clearfix;
    float: left;

    img {
      width: auto;
      height: 138px;
    }
  }

  &__replace {
    @include clearfix;
    float: left;
    padding-top: calc(138px - #{$margin-small});
    margin-left: 12px;
  }

  &__addnew {
    padding: 36px 0;
  }
}
