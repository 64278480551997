.text-scroller {
  $block: "text-scroller";
  $gap: 20px;
  position: relative;
  width: 100%;
  height: 1em;
  overflow: hidden;

  &__wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    white-space: nowrap;
  }

  &__inner {
    line-height: 1em;
  }

  &__duplicate {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    padding-left: $gap;
  }

  &--scrollable:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 16px;
    background: linear-gradient(to right, transparent, $color-white);
  }

  &:hover,
  &:focus {
    .#{$block}__wrapper {
      animation-delay: 0.4s;
      animation-name: text-scroller;
      animation-timing-function: linear;
    }

    &:after {
      opacity: 0;
      transition: opacity 0s 0.4s;
    }
  }

  @keyframes text-scroller {
    0% {
      left: 0;
    }

    100% {
      transform: translateX(calc(-100% - #{$gap}));
    }
  }
}
