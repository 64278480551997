.banner {
  position: relative;
  width: 100%;
  padding: 20px 54px;
  color: $color-white;
  background-color: $color-porcelain;

  &--editing {
    background-color: $color-cerulean;

    .rebrand & {
      background-color: $color-dodger-blue;
    }
  }

  &__text {
    @include type-subheading;
    margin-left: 36px;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__icon {
    @include icon-size(24px);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    fill: $color-white;
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 54px;
    transform: translate(0, -50%);
  }
}

.mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: $color-white;
  opacity: 0;
  transition: opacity 0.2s ease;

  &.active {
    opacity: 0.85;
  }
}

.contextual-messaging-panel {
  @include type-body-primary;
  padding: 40px;
  color: $color-regent-gray;
  background: $color-iron;

  .rebrand & {
    background: #f3f4f5;
  }

  &--modal {
    display: inline-table;
    width: 100%;
    min-height: calc(340px - 34px);
    text-align: center;
    background-color: $color-white;
  }

  &__text-container {
    @include type-note-primary;
    @include type-italic;
    display: table-cell;
    width: 100%;
    color: $color-regent-gray;
    vertical-align: middle;
  }

  &__lead-message {
    @include type-italic;
    display: block;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
  }

  &__lead-message--body {
    @include type-body-primary;
  }

  &__extended-message {
    display: block;
    text-align: center;
  }

  &__cta {
    color: $color-cerulean;
    text-decoration: underline;
    cursor: pointer;

    .rebrand & {
      color: $color-dodger-blue;
    }
  }

  &__message-icon {
    @include icon-size(24px);
    position: relative;
    top: 8px;
    fill: $color-regent-gray;
  }
}

.progress-wheel {
  max-width: 60px;

  canvas {
    max-width: 60px;
    max-height: 60px;

    @include ml-down {
      max-width: 40px;
      max-height: 40px;
    }
  }
}

.progress-value {
  @include type-note-secondary;
  position: relative;
  bottom: 38px;
  left: 8px;
  display: block;
  max-width: 44px;
  text-align: center;

  @include ml-down {
    bottom: 26px;
    left: 5px;
    max-width: 30px;
  }
}

.error-page {
  &__wrapper {
    max-width: 340px;
    margin: 0 auto;
    margin-top: 90px;
  }

  &__head-section {
    width: 100%;
    background-color: $color-shuttle-grey;
  }

  &__head-section-title {
    padding: 24px;
    color: $color-white;
  }

  &__body {
    padding: 25px;
    background-color: $color-white;
  }

  &__message {
    @extend .popover__body-message;
    width: 80%;
  }

  &__actions {
    margin-top: 35px;
    text-align: right;
  }
}
