.scheduling-information {
  &__icon {
    height: 9px;
    width: 19px;
  }

  &__label-container {
    display: flex;
    flex-flow: row wrap;
  }

  &__state-label {
    @include type-label;
    padding: 4px 0 1px;
    width: 80px;
    text-align: center;

    .rebrand & {
      padding: 4px 0;
      border-radius: 2px;
    }

    &--rights {
      width: 125px;
    }

    &--no-action {
      color: $color-regent-gray;
      background-color: $color-porcelain;
    }

    &--expired {
      color: $color-white;
      background-color: $color-crimson;
    }

    &--transitioning-expired {
      color: $color-white;
      background-color: $color-crimson;
    }

    &--transitioning-active {
      color: $color-white;
      background-color: $color-sunshade;

      .rebrand & {
        background-color: $color-blistering-mars;
      }
    }

    &--multiple-transitions {
      position: relative;
      color: $color-white;
      background-color: $color-crimson;
      z-index: 0;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 50%;
        height: 18px;
        background-color: $color-sunshade;
        z-index: -1;

        .rebrand & {
          background-color: $color-blistering-mars;
        }
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        width: 8px;
        height: 18px;
        background: linear-gradient(
          to right bottom,
          $color-crimson 50%,
          $color-sunshade 50%
        );
        z-index: -1;

        .rebrand & {
          background: linear-gradient(
            to right bottom,
            $color-crimson 50%,
            $color-blistering-mars 50%
          );
        }
      }
    }
  }

  &__text {
    &--raised {
      position: relative;
      z-index: 1;
    }
  }

  &__time-information {
    @include type-label;
    font-family: "FSAlbert", Arial, sans-serif;
    padding: 3px 0 1px 12px;
    color: $color-regent-gray;
  }
}
