@import "styles/base/colors";
@import "styles/base/grid";
@import "styles/components/modal";
@import "styles/components/lists";
@import "styles/utilities/typography";

$parent: list-headings;

.#{$parent} {
  &__inner {
    @include list-headings-inner;

    .#{$parent}--modal & {
      @include list-padding-modal;
    }

    .#{$parent}--drawer & {
      padding: 0 24px;
    }
  }

  &__column {
    @include list-column;
  }

  &__name {
    @include list-cell;
  }

  &__name-inner {
    @include type-note-primary;
    width: 100%;
    padding: 10px 0 6px;
    overflow: hidden;
    color: $color-regent-gray;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
